import { validateBBox } from "@turf/turf";
import { InputProfile, Label, WrapperInput, LegendError } from "./FormElements";

const InputComponent = ({ field, onChange }) => {
  const {
    value,
    validate,
    type,
    label,
    placeholder,
    name,
    legendError,
    regularExpression,
  } = field;

  // Change the value of the field
  const handleOnchange = (e) => {
    onChange(e);
  };

  // Validate the field
  const validation = () => {
    if (regularExpression) {
      if (regularExpression.test(value)) {
        onChange({ target: { name, validate: "true" } });
      } else {
        onChange({ target: { name, validate: "false" } });
      }
    }
  };

  return (
    <>
      <WrapperInput>
        <Label valid={validate}>
          {label}
          <InputProfile
            type={type}
            placeholder={placeholder}
            id={name}
            value={value}
            onChange={handleOnchange}
            onKeyUp={validation}
            onBlur={validation}
            valid={validate}
            name={name}
          />
        </Label>

        <LegendError valid={validateBBox}>{legendError}</LegendError>
      </WrapperInput>
    </>
  );
};

export default InputComponent;
