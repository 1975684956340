import styled from "styled-components";

export const CustomSelect = styled("div")`
  display: flex;
  flex-direction: column;

  select {
    margin-top: 9px;
    padding: 12px 10px;
    border-radius: 5px;
    background-color: #f1f1f1;
    color: rgb(61, 61, 61);
    border: none;
    border-radius: 10px;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
    cursor: pointer;
    color: #5c5757;
  }
`;
