import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

export const Operations = ({ parameters }) => {
  const { urlIcon, label, amount } = parameters;

  return (
    <Card sx={{ height: "100%", width: "100%" }}>
      <CardContent>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={8}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="overline"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "clip",
              }}
            >
              {label.substring(0, 20)}
            </Typography>
            <br />
            <Typography color="textPrimary" variant="p">
              {/* 2 collections */}
              <b>{amount} objects</b>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img
              src={urlIcon}
              alt=""
              style={{
                height: "60px",
                width: "60px",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
