import { useNavigate } from "react-router-dom";
import Icon1 from "images/undraw_OCR.svg";
import Icon2 from "images/dashboardV2.svg";
import Icon3 from "images/DigitalTwin.svg";
import ImageVideoManager from "images/undraw_video_files_fu10.svg";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import useGet from "hooks/useFetch";

import {
  UserContainer,
  UserH1,
  UserH2,
  HubContainer,
  HubWrapper,
  HubCard,
  HubIcon,
  HubH1,
  HubH2,
  HubP,
  UserH2Flex,
} from "./HubElements";

const HubComponent = () => {
  const navigate = useNavigate();

  const onClickOcr = () => navigate("/collections");
  const onClickDashboards = () => navigate("/dashboardUser");
  const onClickDigitalTwin = () => navigate("/digitalTwin");
  const onClickVideoManager = () => navigate("/field-video-manager");
  const operatorId = localStorage.getItem("operatorId");

  const [customer, errorCustomer] = useGet(`api/v1/operators/${operatorId}`);

  const formatDate = (string) => {
    if (string === null) {
      return "";
    } else {
      const newString = string.replace("T", " ");
      return newString.replace(".000Z", " ");
    }
  };

  return (
    <Container
      style={{
        paddingTop: 50,
      }}
      maxWidth="xl"
    >
      <UserContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            {customer && !errorCustomer ? (
              <UserH1>Hello, Welcome to a Green World!</UserH1>
            ) : (
              <UserH1>Loading ...</UserH1>
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <UserH2Flex>
                {" "}
                <b>Last Login:</b>{" "}
                {customer !== undefined &&
                  customer !== null &&
                  `${formatDate(
                    customer.operator.user.userVerification.lastLogin
                  )}`}
              </UserH2Flex>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={10} xl={10}>
            <UserH2>Please select the module that you want to use.</UserH2>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <UserH2Flex>
                {" "}
                <b>Role: </b>
                {customer !== undefined &&
                  customer !== null &&
                  `${formatDate(
                    customer.operator.user.userType.role.toLowerCase()
                  )}`}
              </UserH2Flex>
            </Box>
          </Grid>
        </Grid>
      </UserContainer>
      <HubContainer>
        <HubH1>
          Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon <br />{" "}
          Carbon footprint management system
        </HubH1>
        <HubWrapper>
          <HubCard onClick={onClickDashboards}>
            <HubH2>DASHBOARD</HubH2>
            <HubIcon src={Icon2} />
            <HubP>Report of all the objects collected.</HubP>
          </HubCard>
          <HubCard onClick={onClickDigitalTwin}>
            <HubH2>DIGITAL TWIN</HubH2>
            <HubIcon src={Icon3} />
            <HubP>View all objets and events in the map.</HubP>
          </HubCard>
          <HubCard onClick={onClickVideoManager}>
            <HubH2>VIDEO MANAGER</HubH2>
            <HubIcon src={ImageVideoManager} />
            <HubP>Manage your field collected video.</HubP>
          </HubCard>
          <HubCard onClick={onClickOcr} className="last-item">
            <HubH2>COLLECTIONS</HubH2>
            <HubIcon src={Icon1} />
            <HubP>View your collected objects for day.</HubP>
          </HubCard>
        </HubWrapper>
      </HubContainer>
    </Container>
  );
};

export default HubComponent;
