import { createStore, compose, applyMiddleware } from "redux";
import rootReducers from "./reducers/rootReducers";
import { logActions } from "./middlewares";

const commposeAlt = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = commposeAlt(applyMiddleware(logActions));

const store = createStore(rootReducers, composeEnhancers);

export default store;
