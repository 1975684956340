import Lottie from "react-lottie";

const AnimationLottie = ({ animation }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice", 
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} width="35vw" height="35vh" />
    </div>
  );
};

export default AnimationLottie;
