import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
    font-family: 'Montserrat', sans-serif;
    }

html {
  font-size: 62.5%;
}

p {
    font-size:1.4rem;
}

main {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    padding: 40px;
    font-family: 'Montserrat', sans-serif;
    }

body {
    background: #f9f9f9;
    font-family: 'Montserrat', sans-serif;
    }
`;
