import styled from "styled-components";

export const NamePlateMarker = styled.button`
  background: none;
  border: none;

  img {
    width: 40px;
  }
`;

export const CustomDiv = styled("div")`
  align-items: "center";
  margin-left: 30%;
  margin-top: 10%;
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0E4D45;
    color: white;
}
`;

export const SensorMarker = styled("button")`
  img {
    width: 9vw;
    height: 7vh;
    background-color: yellow;
}
`;

export const SelectSelected = styled("button")`
  background-color: DodgerBlue;
`;