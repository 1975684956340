import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LayersIcon from "@mui/icons-material/Layers";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import {
  setLocationsCSV,
  reloadCsv,
  setFilterObjectsByData,
} from "../../../redux/actions";
import OpenDialog from "../../Dialogs/OpenDialog";
import { setFeaturesDetailsDT } from "../../../redux/actions/admin";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import { PRIMARY_COLOR } from "../../../utils/const";

import { useSWRConfig } from "swr";
import { Checkbox } from "@mui/material";

import useSwr from "swr";

// check layer
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadCSV from "../../DigitalTwin/LoadCSV";

// redux actions Admin
import {
  showRegionDetailsDrawer,
  SetUsers,
  SetShowUsers,
  SetShowAllUsers,
} from "./../../../redux/actions/admin";

import AccordionObjects from "./AccordionDigitalTwin";
import AccordionLoadCsv from "./AccordionLoadCsv";
import AccordionObjectsPolygon from "./AccordionObjectsPolygon";

import {
  useStyles,
  CustomizedDiv,
  CustomizedTypography,
  CustomizeToggleButtonGroup,
  ButtonCard,
  FeatureDetails,
  CustomizedAccordion,
  CustomizedTypographyLayer,
  FilterTabStyles,
  HeadModal,
  ContentFilters,
  ContentFilterItem,
  ContentFilterItemLabel,
} from "./MapDrawerStyle";

import { SummaryFeature } from "../UserRoutes/DrawerElements";
import { format, addMonths } from "date-fns";
import useGet from "hooks/useFetch";

const DrawerHeader = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function convertLocalDateToUTC(date) {
  const utcDate =
    date.getUTCFullYear().toString() +
    "-" +
    (parseInt(date.getUTCMonth()) + 1).toString() +
    "-" +
    date.getUTCDate().toString();

  console.log(
    "🚀 ~ file: MapDrawer.jsx ~ line 81 ~ convertLocalDateToUTC ~ utcDate",
    utcDate
  );
  return new Date(utcDate);
}

export default function MapDrawer({ drawState }) {
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );

  const dataRegion = useSelector(
    (state) => state.adminReducer.dataObjectsRegion
  );

  const openRegionDetails = useSelector(
    (state) => state.adminReducer.showRegionDetailsDrawer
  );

  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [open, setOpen] = useState(false);
  const [dialogs, setDialog] = useState(false);
  const [loadsavepoint, setLoadsavepoint] = useState(false);
  const [object, setObject] = useState("1");
  const [ghg, setGhg] = useState("1");
  const [agile, setAgile] = useState("1");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [section, setSection] = useState("layers");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [today, setToday] = useState("");
  const [featureDescriptionData, setFeatureDescriptionData] =
    useState("No comments");
  const [contentDialog, setContentDialog] = useState({
    title: "Save object",
    description: "Are you sure you want to save the the object?",
    disagree: "no",
    agree: "yes",
  });
  const [localStartDate, setLocalStartDate] = useState("");
  const [localEndDate, setLocalEndDate] = useState("");
  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = localStorage.getItem("token");

  const handleAlignment = (event) => {
    setSection(event.currentTarget.value);
  };
  const locationsJSON = useSelector(
    (state) => state.locationCSVReducer.locationsJSON
  );

  const locationsCSV = useSelector(
    (state) => state.locationCSVReducer.locationsCSV
  );

  const reloadCsvMap = useSelector(
    (state) => state.locationCSVReducer.reloadCSV
  );

  const featureDetails = useSelector(
    (state) => state.adminReducer.setFeaturesDetailsDT
  );

  //filter
  const users = useSelector((state) => state.adminReducer.SetUsers);
  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  const [typeRegions] = useGet(`api/v1/type-region`);
  const [typeRegion, setTypeRegion] = useState({});
  // Know type of region
  useEffect(() => {
    const some = typeRegions?.find((item) => {
      return item?.id === dataRegion?.regionTypeId;
    });
    setTypeRegion(() => ({ ...some }));
  }, [dataRegion, typeRegions]);

  useEffect(() => {
    const date = new Date();

    const utcDate = convertLocalDateToUTC(date);

    const endDate = format(utcDate, "yyyy-MM-dd");
    const startDate = format(addMonths(utcDate, -2), "yyyy-MM-dd");

    const localEndDate = format(date, "yyyy-MM-dd");
    const localStartDate = format(addMonths(date, -2), "yyyy-MM-dd");

    setLocalStartDate(localStartDate);
    setLocalEndDate(localEndDate);
    setStartDate(startDate);
    setEndDate(endDate);
    setToday(localEndDate);
    dispatch(
      setFilterObjectsByData({
        endDate,
        startDate,
      })
    );
  }, [dispatch]);

  const handleChangeStartDate = (e) => {
    const newDate = e.target.value;
    setLocalStartDate(newDate);
    dispatch(setFilterObjectsByData({ endDate, startDate: newDate }));
  };

  const handleChangeEndDate = (e) => {
    const newDate = e.target.value;
    setLocalEndDate(newDate);
    dispatch(setFilterObjectsByData({ endDate: newDate, startDate }));
  };

  useEffect(() => {
    if (role === "OPERATOR") {
      Object.keys(users).forEach((idUser) => {
        if (idUser !== userId) {
          users[idUser].isShow = false;
          dispatch(SetUsers(users));
          dispatch(SetShowUsers(!showUsers));
        }
      });
    }
  }, [users]);

  const fetcher = (...args) =>
    fetch(...args, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

  const url = `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/objects/${adminCompanyId}`;
  const { data, error } = useSwr(url, fetcher);
  const dataObjects = data && !error ? data : [];

  const handleChangeUserFilter = (event, id) => {
    const { isShow } = users[id];
    users[id].isShow = !isShow;
    dispatch(SetUsers(users));
    dispatch(SetShowUsers(!showUsers));
  };

  const dataObjectsPolygon = useSelector(
    (state) => state.adminReducer.dataObjectsPolygon
  );

  useEffect(() => {
    if (drawState === true) {
      handleDrawerOpen();
    } else if (drawState === false) {
      handleDrawerClose();
    } else {
    }
  }, [role, drawState, locationsJSON]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const cleanLocationsCSV = () => {
    dispatch(setLocationsCSV({}));
  };

  const openSaveCsvDialog = () => {
    setDialog(true);
  };

  const openSavePointDialog = () => {
    setLoadsavepoint(true);
  };

  const saveObject = async (body, type) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/${type}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: JSON.stringify(body),
        }
      );
      await res.json();
      mutate(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/objects/${adminCompanyId}`
      );
      setContentDialog({ ...contentDialog, agree: "yes" });
    } catch (error) {
      console.error(error);
    }
  };

  const savePoint = async () => {
    setContentDialog({ ...contentDialog, agree: <CircularProgress /> });
    const bodyObject = () => {
      let date = new Date();
      return {
        userId: Number(userId),
        dataSourceId: 2,
        date: date,
        location: {
          latitude: Number(featureDetails.geometry.coordinates[1]),
          longitude: Number(featureDetails.geometry.coordinates[0]),
        },
      };
    };

    if (object === "1") {
      let body = {
        typeElementGhgId: Number(ghg),
        comments: featureDescriptionData,
        object: bodyObject(),
      };
      await saveObject(body, "object-ghg");
      setLoadsavepoint(false);
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
    }
    if (object === "2") {
      let body = {
        typeElementAgileId: Number(agile),
        comments: featureDescriptionData,
        object: bodyObject(),
      };
      await saveObject(body, "object-agile");
      setLoadsavepoint(false);
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
    }
    if (object === "3") {
      let body = {
        typeElementWellId: 1,
        comments: featureDescriptionData,
        object: bodyObject(),
      };
      await saveObject(body, "object-well");
      setLoadsavepoint(false);
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
    }
    if (object === "4") {
      let body = {
        typeElementFacilityId: 1,
        comments: featureDescriptionData,
        object: bodyObject(),
      };
      await saveObject(body, "object-facility");
      setLoadsavepoint(false);
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const saveCSV = async () => {
    setLoading(true);
    const bodyObject = (location) => {
      let date = new Date();
      return {
        userId: Number(userId),
        dataSourceId: 3,
        date: date,
        location: {
          latitude: Number(location.latitude),
          longitude: Number(location.longitude),
        },
      };
    };
    return Promise.all(
      locationsCSV.list.map(async (location, index) => {
        if (location.typeObjectId === "1") {
          let body = {
            typeElementGhgId: Number(location.typeElementId),
            comments: "no comments",
            object: bodyObject(location),
          };
          const newObjet = await saveObject(body, "object-ghg");
          return newObjet;
        }
        if (location.typeObjectId === "2") {
          let body = {
            typeElementAgileId: Number(location.typeElementId),
            comments: "no comments",
            object: bodyObject(location),
          };
          const newObjet = await saveObject(body, "object-agile");
          return newObjet;
        }
        if (location.typeObjectId === "3") {
          let body = {
            typeElementWellId: Number(location.typeElementId),
            comments: "no comments",
            object: bodyObject(location),
          };
          const newObjet = await saveObject(body, "object-well");
          return newObjet;
        }
        if (location.typeObjectId === "4") {
          let body = {
            typeElementFacilityId: Number(location.typeElementId),
            comments: "no comments",
            object: bodyObject(location),
          };
          const newObjet = await saveObject(body, "object-facility");
          return newObjet;
        }
      })
    ).then(() => {
      setLoading(false);
      setDialog(false);
      setSuccess(true);
    });
  };

  const cleanLocations = () => {
    setSuccess(false);
    cleanLocationsCSV();
    if (reloadCsvMap === false) {
      dispatch(reloadCsv(true));
    }
    if (reloadCsvMap === true) {
      dispatch(reloadCsv(false));
    }
  };

  const setObjectHandler = (e) => {
    setObject(e.target.value);
  };

  const setObjectGhgHandler = (e) => {
    setGhg(e.target.value);
  };

  const setObjectAgileHandler = (e) => {
    setAgile(e.target.value);
  };

  const closeRegionDetails = () => {
    dispatch(showRegionDetailsDrawer(false));
  };

  return (
    <div>
      <Drawer
        sx={{
          width: 0,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: {
              xs: 240,
              sm: 320,
            },
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader></DrawerHeader>
        <div>
          <div>
            <Divider />
            <List>
              <ListItem button></ListItem>
            </List>

            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              <CustomizeToggleButtonGroup
                value={section}
                onChange={handleAlignment}
                size="large"
                exclusive
                fullWidth={true}
                aria-label="text view"
              >
                <ToggleButton
                  sx={{ fontSize: "1.4rem", textTransform: "none" }}
                  value="layers"
                >
                  <LayersIcon></LayersIcon>
                  <p> Workspace</p>
                </ToggleButton>
                <ToggleButton
                  sx={{ fontSize: "1.4rem", textTransform: "none" }}
                  value="filters"
                >
                  <FilterAltIcon></FilterAltIcon>
                  <p>Filters</p>
                </ToggleButton>
              </CustomizeToggleButtonGroup>
            </List>
            <List
              sx={{
                height: "100%",
                position: "bottom",
              }}
              subheader={<li />}
            >
              {section === "layers" && (
                <div>
                  <div>
                    {role === "COMPANY ADMIN" && (
                      <List
                        sx={{
                          height: "100%",
                          position: "bottom",
                        }}
                        subheader={<li />}
                      >
                        <ToggleButtonGroup
                          size="medium"
                          exclusive
                          fullWidth={true}
                          aria-label="text view"
                        >
                          <CustomizedTypography>
                            CSV Files ({Object.keys(locationsCSV).length / 3})
                          </CustomizedTypography>
                          <ToggleButton
                            sx={{
                              fontSize: "1.4rem",
                              width: {
                                xs: 120,
                                sm: 150,
                              },
                            }}
                            value="Add data"
                          >
                            <AddIcon></AddIcon>
                            <LoadCSV />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </List>
                    )}
                    <br />
                    <div
                      style={{
                        padding: "0px 12px",
                      }}
                    >
                      {dataObjects.length > 0 && (
                        <AccordionObjects dataObjects={dataObjects} />
                      )}
                      {/* card with the summary of the objects when creating the polygon */}
                      {geometryPolygon?.type === "Polygon" &&
                        dataObjectsPolygon.length !== 0 && (
                          <CustomizedAccordion
                            classes={{
                              content: classes.content,
                              expanded: classes.expanded,
                            }}
                            defaultExpanded={true}
                          >
                            <AccordionSummary
                              classes={{
                                content: classes.content,
                                expanded: classes.expanded,
                              }}
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <CustomizedTypographyLayer>
                                Regions
                              </CustomizedTypographyLayer>
                            </AccordionSummary>
                            <AccordionObjectsPolygon
                              dataObjects={dataObjectsPolygon}
                            />
                          </CustomizedAccordion>
                        )}

                      {/* card with details of the region created  */}
                      {openRegionDetails === true &&
                      featureDetails.geometry.type == null ? (
                        <CustomizedAccordion
                          classes={{
                            content: classes.content,
                            expanded: classes.expanded,
                          }}
                          defaultExpanded={true}
                        >
                          <SummaryFeature>
                            <CloseIcon
                              onClick={closeRegionDetails}
                              sx={{
                                cursor: "pointer",
                                color: "rgba(0, 0, 0, 0.54)",
                                position: "absolute",
                                alignSelf: "right",
                                right: "10px",
                              }}
                            />

                            <p>Region Details</p>

                            <p class="geometry-type">
                              <b></b>
                            </p>
                            <div>
                              <p class="geometry-type">
                                <b>Region name:</b>
                              </p>
                              <input type="text" value={dataRegion.name} />
                            </div>
                            <div>
                              <p class="geometry-type">
                                <b>Region type:</b>
                              </p>
                              <input type="text" value={typeRegion.type} />
                            </div>
                            <div>
                              <p>
                                <b>Comments:</b>{" "}
                                <textarea
                                  type="text"
                                  value={dataRegion.comments}
                                />
                              </p>
                            </div>
                          </SummaryFeature>
                        </CustomizedAccordion>
                      ) : null}

                      {Object.keys(locationsCSV).length !== 0 ? (
                        <Accordion
                          sx={{
                            border: "none",
                            boxShadow: "none",
                          }}
                          defaultExpanded={true}
                        >
                          <CustomizedDiv>
                            <p>{locationsCSV.name}</p>
                            <div>
                              <SaveTwoToneIcon
                                onClick={openSaveCsvDialog}
                                sx={{
                                  cursor: "pointer",
                                  color: "#006ad4",
                                }}
                              />
                              <DeleteTwoToneIcon
                                onClick={cleanLocationsCSV}
                                sx={{
                                  cursor: "pointer",
                                  color: "#c73e3e",
                                }}
                              />
                              <Modal
                                open={dialogs}
                                onClose={setDialog}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box
                                  sx={style}
                                  style={{
                                    position: "relative",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <HeadModal>
                                    <img
                                      src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                                      alt=""
                                      className="neurona"
                                    />
                                    <span className="titleHeadModal">
                                      Save Locations
                                    </span>
                                  </HeadModal>
                                  <div style={{}}>head modal</div>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                    style={{
                                      marginTop: "50px",
                                    }}
                                  >
                                    Are you sure you want to save the locations?
                                  </Typography>
                                  <LoadingButton
                                    sx={{
                                      margin: "10px",
                                    }}
                                    loadingPosition="start"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      setDialog(false);
                                    }}
                                    style={{
                                      background: PRIMARY_COLOR,
                                      borderColor: PRIMARY_COLOR,
                                      color: "#fff",
                                    }}
                                  >
                                    Cancel
                                  </LoadingButton>
                                  <LoadingButton
                                    sx={{
                                      margin: "10px",
                                    }}
                                    loading={loading}
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      saveCSV();
                                    }}
                                    style={{
                                      background: PRIMARY_COLOR,
                                      borderColor: PRIMARY_COLOR,
                                      color: "#fff",
                                    }}
                                  >
                                    Save
                                  </LoadingButton>
                                </Box>
                              </Modal>
                              <OpenDialog
                                openDialog={success}
                                setOpenDialog={setSuccess}
                                execute={cleanLocations}
                                content={{
                                  title: "Success",
                                  description:
                                    "The CSV locations was succesfuly saved",
                                  agree: "Continue",
                                }}
                              />
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                              ></AccordionSummary>
                            </div>
                          </CustomizedDiv>

                          <AccordionLoadCsv dataObjects={locationsJSON} />
                        </Accordion>
                      ) : null}
                      {featureDetails.geometry.type !== null && (
                        <FeatureDetails>
                          <h1 className="title">Add New Object</h1>
                          <div class="main-currency-table">
                            <div class="currency-table--container">
                              <table>
                                <tr>
                                  <td class="table__top-left">Longitude</td>
                                  <td class="table__top-right table__right">
                                    {parseFloat(
                                      featureDetails.geometry.coordinates[0]
                                    ).toFixed(7)}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="table__bottom-left">Latitude</td>
                                  <td class="table__bottom-right table__right">
                                    {parseFloat(
                                      featureDetails.geometry.coordinates[1]
                                    ).toFixed(7)}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>

                          <div>
                            <p className="location-details">
                              <b>Object type:</b>
                            </p>
                            <select
                              defaultValue={object}
                              onChange={setObjectHandler}
                              className="browser-default custom-select"
                            >
                              <option selected value="1">
                                Object GHG
                              </option>
                              <option value="2">Object agile</option>
                              <option value="3">Well</option>
                              <option value="4">Facility</option>
                            </select>
                            <p className="location-details">
                              <b>Type element:</b>
                            </p>
                            {object === "1" ? (
                              <div>
                                <select
                                  defaultValue={ghg}
                                  onChange={setObjectGhgHandler}
                                  className="browser-default custom-select"
                                >
                                  <option selected value="1">
                                    Flanged and Threaded Connection
                                  </option>
                                  <option value="2">Tank</option>
                                  <option value="3">Flare stack</option>
                                  <option value="4">Combustion unit</option>
                                  <option value="5">Compressor station</option>
                                  <option value="6">Separator</option>
                                  <option value="7">Valve</option>
                                  <option value="8">Pipeline</option>
                                </select>
                              </div>
                            ) : null}
                            {object === "2" ? (
                              <div>
                                <select
                                  defaultValue={agile}
                                  onChange={setObjectAgileHandler}
                                  className="browser-default custom-select"
                                >
                                  <option selected value="1">
                                    Sign
                                  </option>
                                  <option value="2">Needs other symbol</option>
                                  <option value="3">Abandoned</option>
                                  <option value="4">Camera overview</option>
                                  <option value="5">360 pictures</option>
                                  <option value="6">
                                    Come back to collect
                                  </option>
                                  <option value="7">Data error</option>
                                  <option value="8">Data verified</option>
                                  <option value="9">Needs verification</option>
                                  <option value="10">Team note view</option>
                                  <option value="11">Team note resolved</option>
                                  <option value="12">Urgency alert</option>
                                </select>
                              </div>
                            ) : null}
                            {object === "3" && null}
                            {object === "4" && null}
                            <p className="location-details">
                              <b>Comments:</b>{" "}
                              <textarea
                                onChange={(event) =>
                                  setFeatureDescriptionData(event.target.value)
                                }
                                type="text"
                              />
                            </p>
                          </div>
                          <ButtonCard onClick={openSavePointDialog}>
                            Save
                          </ButtonCard>
                          <OpenDialog
                            openDialog={loadsavepoint}
                            setOpenDialog={setLoadsavepoint}
                            execute={savePoint}
                            content={contentDialog}
                          />
                        </FeatureDetails>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* ---section filters--- */}
              {section === "filters" && (
                <ContentFilters>
                  <CustomizedAccordion
                    classes={{
                      content: classes.content,
                      expanded: classes.expanded,
                    }}
                    defaultExpanded={true}
                  >
                    <ContentFilterItem>
                      <Typography>Filter Objects For User</Typography>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      ></AccordionSummary>
                    </ContentFilterItem>
                    <AccordionSummary>
                      <FilterTabStyles>
                        {Object.keys(users).map((key) => (
                          <div>
                            <Checkbox
                              defaultChecked={users[key].isShow}
                              onChange={(event) =>
                                handleChangeUserFilter(event, key)
                              }
                            />
                            <p>{users[key].operator}</p>
                          </div>
                        ))}
                        {/* {users.length !== 0 &&} */}
                      </FilterTabStyles>
                    </AccordionSummary>
                  </CustomizedAccordion>
                  <CustomizedAccordion
                    classes={{
                      content: classes.content,
                      expanded: classes.expanded,
                    }}
                    defaultExpanded={true}
                  >
                    <ContentFilterItem>
                      <Typography>Filter Objects For Date</Typography>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      ></AccordionSummary>
                    </ContentFilterItem>
                    <AccordionSummary>
                      <FilterTabStyles>
                        <ContentFilterItem className="date">
                          <ContentFilterItemLabel>
                            Start Date:
                            <input
                              className="input-start"
                              type="date"
                              value={localStartDate}
                              onChange={(e) => handleChangeStartDate(e)}
                              max={localEndDate}
                            />
                          </ContentFilterItemLabel>

                          <ContentFilterItemLabel>
                            End Date:
                            <input
                              className=""
                              type="date"
                              value={localEndDate}
                              onChange={(e) => handleChangeEndDate(e)}
                              min={localStartDate}
                              max={today}
                            />
                          </ContentFilterItemLabel>
                        </ContentFilterItem>
                      </FilterTabStyles>
                    </AccordionSummary>
                  </CustomizedAccordion>
                </ContentFilters>
              )}
            </List>
          </div>
        </div>
      </Drawer>
    </div>
  );
}
