import MapRoutes from "./Map";

const UserRoutes = () => {
  return (
    <>
      <MapRoutes />
    </>
  );
};

export default UserRoutes;
