import { useNavigate } from "react-router-dom";
import Icon1 from "../../images/undraw_augmented_reality.svg";
import Icon2 from "../../images/undraw_mobile.svg";
import Icon3 from "../../images/undraw_baseline.svg";
import Icon4 from "../../images/undraw_cloud.svg";
import Icon5 from "../../images/undraw_software.svg";
import Icon6 from "../../images/undraw_hardware.svg";
import Icon7 from "../../images/undraw_footprint.svg";
import Icon8 from "../../images/undraw_factory.svg";
import Icon9 from "../../images/undraw_netzero.svg";

import {
  ServicesContainer,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH1,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  const navigate = useNavigate();

  const onClickLogin = () => {
    navigate("/signIn");
  };
  return (
    <div>
      <ServicesContainer id="productAndServices">
        <ServicesH1>Product & Services</ServicesH1>
        <ServicesWrapper>
          {/* <HorizontalScroll style={{ height: "100%", width: "100%" }}> */}
          <ServicesCard onClick={onClickLogin} id="1">
            <ServicesIcon src={Icon1} />
            <ServicesH2>
              Decimetrix<sup>®</sup> Augmented Reality
            </ServicesH2>
            <ServicesP>Training per user using our XR technology.</ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="2">
            <ServicesIcon src={Icon2} />
            <ServicesH2>
              Decimetrix<sup>®</sup> Digital Inventory App
            </ServicesH2>
            <ServicesP>
              Digital inventory of Facilities, Wells, and Systems.
            </ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="3">
            <ServicesIcon src={Icon3} />
            <ServicesH2>
              {" "}
              Decimetrix<sup>®</sup> Emissions Baseline{" "}
            </ServicesH2>
            <ServicesP>Emissions baseline creation for Wells & Facilities.</ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="4">
            <ServicesIcon src={Icon4} />
            <ServicesH2>Information & Data Management</ServicesH2>
            <ServicesP>
              On cloud or On-Premise service using elastic storage.
            </ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="5">
            <ServicesIcon src={Icon5} />
            <ServicesH2>Software as a Service (SaaS).</ServicesH2>
            <ServicesP>
              Customized software developed for our clients.
            </ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="6">
            <ServicesIcon src={Icon6} />
            <ServicesH2> Hardware as a Sale or Service. </ServicesH2>
            <ServicesP>Detection and quantification of emissions.</ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="7">
            <ServicesIcon src={Icon7} />
            <ServicesH2>Decimetrix<sup>®</sup> Consulting </ServicesH2>
            <ServicesP>
              Consulting for the road to NET ZERO and carbon neutrality.
            </ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="8">
            <ServicesIcon src={Icon8} />
            <ServicesH2>Field Repair & Validation</ServicesH2>
            <ServicesP>
              Repair & validation of emissions found for assets in the field.
            </ServicesP>
          </ServicesCard>

          <ServicesCard onClick={onClickLogin} id="9">
            <ServicesIcon src={Icon9} />
            <ServicesH2> Carbon Footprint Management</ServicesH2>
            <ServicesP>
              Management & estimation of carbon footprint levels.
            </ServicesP>
          </ServicesCard>

          {/* </HorizontalScroll> */}
        </ServicesWrapper>
      </ServicesContainer>
    </div>
  );
};

export default Services;
