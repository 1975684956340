import styled from "styled-components";

export const DashboardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  background-color: #f9f9f9;
`;

export const DashboardsForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 4px 4px; */
  background-color: #fff;
  border-radius: 22px;
  height: 100%;
  box-shadow: 5px 3px 6px #00000066;
  max-width: 1200px;
  
`;

export const DashboardsWrapper = styled.div`
  position: flex;
  z-index: 1;
  height: 60hv;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 0 auto;
  padding: 0 10px;
  justify-content: center;
  background-color: #f9f9f9;
`;

export const DashboardsRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 1000px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  margin: 100px auto 0 auto;
  @media (max-width:1000px){
    width: 50%;
  }
  @media (max-width:768px){
    width: 100%;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 0px;
  margin-top: 30px;
  grid-area: col2;
`;






export const Img = styled.img`
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 0.8;
    z-index: 10;
    cursor: pointer;
  }
`;

export const WrapperForms = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;


  @media (max-width: 768px) {
    display: inline-block;
    margin: 0 20%;
  }
  @media (max-width: 600px) {
    display: inline-block;
    margin: 0 15%;
  }
  @media (max-width: 450px) {
    display: inline-block;
    margin: 0;
  }
`;
export const FormCard = styled.form`
  padding: 0 20px;
  box-sizing: border-box;
  margin-right: 20px;
  border-radius: 20px;

  @media (max-width: 768px) {
    width: 300px;
  }
`;

export const FormTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(26, 26, 26);
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const WrapperInput = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;

export const LabelInput = styled.label`
  font-size: 1.4rem;
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const FormInput = styled.input`
  margin-left: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: rgb(61, 61, 61);
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
`;
export const FormInputComment = styled.textarea`
  margin-left: 10px;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
  padding: 10px;
  
  font-size: 1.4rem;
  background-color: #f9f9f9;
  
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #f9f9f9;
  margin-top: 30px;
  padding-top: 50px;
`;

export const ItemInfo = styled.h1`
  font-size: 2rem;
  color: rgb(41, 41, 41);
  margin-top: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

