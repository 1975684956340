import Typography from "@mui/material/Typography";
// check layer
import axios from "axios";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";

import REGION from "images/REGION.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CardMedia from "@mui/material/CardMedia";

import useGet from "hooks/useFetch";

// custom style
import {
  WrapperTypeObjectTitle,
  WrapperTypeElementTitle,
  FormInput,
  WrapperButtonsIcons,
  HeadModal,
} from "./AccordionsElements";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  showMarkerseByTypeObjectPolygon,
  showMarkerseByTypeElementPolygon,
  setDataObjectsPolygon,
} from "redux/actions/admin";

import {
  SummaryFeature,
  ButtonCard,
  SelectRegion,
} from "../UserRoutes/DrawerElements";

import { useEffect } from "react";

const deleteDuplicateObjects = (objects) => {
  return objects.filter(
    (object, index, self) =>
      index === self.findIndex((t) => t.name === object.name)
  );
};

const getTypeElementsNames = (dataObjects) => {
  const typeElementsNames = dataObjects.map((object) => {
    return object.typeObjectId === 1
      ? object.typeObjectGhg?.typeElementGhg.name
      : object.typeObjectId === 2
      ? object.typeObjectAgile?.typeElementAgile.name
      : object.typeObjectId === 3
      ? object.typeObjectWell?.typeElementWell.name
      : object.typeObjectId === 4
      ? object.typeObjectFacility?.typeElementFacility.name
      : null;
  });
  return [...new Set(typeElementsNames)];
};

const AccordionObjectsPolygon = ({ dataObjects }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 1,
    // textAlign: "left",
  };

  // All regions
  const [typeRegions, errorTypeRegion] = useGet(`api/v1/type-region`);

  const token = localStorage.getItem("token");

  const [openModal, setOpenModal] = useState(false);
  const [regionName, setRegionName] = useState("Region Name");
  const [comments, setComments] = useState("Comments");
  const [typeRegion, setTypeRegion] = useState("2");
  // const [regionGeojson, setRegionGeojson] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const typeObjectsNames = dataObjects.map((object) => object.typeObject.name);
  const objectsGhg = dataObjects.filter(
    (object) => object.typeObjectGhg !== undefined
  );
  const ghgObjectIds = objectsGhg.map((object) => object.typeObjectGhg.id);

  //Data Admin
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [adminCompany, errorAdminCompany] = useGet(
    `api/v1/admin-company/${adminCompanyId}`
  );
  // filter duplicate typeObjectsNames
  const uniqueTypeObjectsNames = [...new Set(typeObjectsNames)];

  //Geometry Polygon
  const geometryPolygon = useSelector(
    (state) => state.adminReducer.setGeometryPolygon
  );
  //Features Polygon
  const featuresPolygon = useSelector(
    (state) => state.adminReducer.setFeaturesPolygon
  );

  useEffect(() => {
    const showTypeObject = {};
    uniqueTypeObjectsNames.map(
      (typeObjectName) => (showTypeObject[typeObjectName] = true)
    );
    dispatch(showMarkerseByTypeObjectPolygon({ ...showTypeObject }));

    const showTypeElement = {};
    const typeElementsNames = getTypeElementsNames(dataObjects);
    typeElementsNames.map(
      (typeElementName) => (showTypeElement[typeElementName] = true)
    );
    dispatch(showMarkerseByTypeElementPolygon({ ...showTypeElement }));
  }, [dataObjects]);

  //open modal confirmation
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  // define functions to handle save button
  const saveRegion = async () => {
    handleClose();
    await sendRegionGeoJson();
  };

  const cleanLocationsRegion = () => {
    dispatch(setDataObjectsPolygon([]));
  };

  //geoJson format for region
  const regionGeoJson = {
    userId: adminCompany?.userId,
    type: "geojson",
    name: regionName,
    comments: comments,
    regionTypeId: parseInt(typeRegion),
    data: {
      type: "FeatureCollection",
      features: [
        ...featuresPolygon,
        {
          type: "Feature",
          properties: {
            name: regionName,
            userId: adminCompany?.userId,
            regionTypeId: parseInt(typeRegion),
            comments: comments,
          },
          geometry: geometryPolygon,
        },
      ],
    },
  };
  // console.log("🏝 regionGeoJson:", regionGeoJson);

  // function to save Region Geojson to api-green-dragon-mg & api-green-dragon-pg
  const sendRegionGeoJson = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_URL_MG_API}region`,
      regionGeoJson
    );
    const response = await res.data;
    if (res.status === 200) {
      const region = {
        userId: adminCompany?.userId,
        regionTypeId: parseInt(typeRegion),
        name: regionName,
        geojsonRegionId: response._id,
        comments: comments,
      };
      try {
        const body = {
          region,
          ghgObjectIds,
        };
        const res = await fetch(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/region`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: token ? `Bearer ${token}` : "",
            },
            body: JSON.stringify(body),
          }
        );
        await res.json();
        dispatch(setDataObjectsPolygon([]));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // const sendRegionGeoJson = () => {
  //   console.log(regionGeoJson);
  // };

  const showTypeObject = useSelector(
    (state) => state.adminReducer.markerByTypeObjectPolygon
  );

  const handlerClickFilterTypeObject = (typeObjectName) => {
    showTypeObject[typeObjectName] = !showTypeObject[typeObjectName];
    dispatch(showMarkerseByTypeObjectPolygon({ ...showTypeObject }));
  };

  const showTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElementPolygon
  );

  const handlerClickFilterTypeElement = (typeElementName) => {
    showTypeElement[typeElementName] = !showTypeElement[typeElementName];
    dispatch(showMarkerseByTypeElementPolygon({ ...showTypeElement }));
  };

  const accordionDetails = uniqueTypeObjectsNames.map(
    (typeObjectName, index) => {
      let typeElements = null;
      // filter typeElement by typeObjectName
      const objects = dataObjects.filter(
        (object) => object.typeObject.name === typeObjectName
      );

      if (typeObjectName === "GHG") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectGhg.typeElementGhg.name,
            icon: object.typeObjectGhg.typeElementGhg.url_icon_color,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }

      if (typeObjectName === "AGILE") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectAgile.typeElementAgile.name,
            icon: object.typeObjectAgile.typeElementAgile.urlIconColor,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }
      if (typeObjectName === "WELL") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectWell?.typeElementWell.name,
            icon: object.typeObjectWell?.typeElementWell.url_icon_color,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }
      if (typeObjectName === "FACILITIES") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectFacility?.typeElementFacility.name,
            icon: object.typeObjectFacility?.typeElementFacility.urlIconColor,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }

      return (
        <div key={index}>
          <Accordion
            sx={{
              paddingLeft: "5px",
              border: "none",
              boxShadow: "none",
            }}
            defaultExpanded={false}
          >
            <WrapperTypeObjectTitle>
              <div className="icon-title">
                {showTypeObject[typeObjectName] ? (
                  <VisibilityIcon
                    sx={{
                      color: "#272727",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlerClickFilterTypeObject(typeObjectName)}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{
                      color: "#575757",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlerClickFilterTypeObject(typeObjectName)}
                  />
                )}

                <p>Objects {typeObjectName.toLowerCase()}</p>
              </div>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              ></AccordionSummary>
            </WrapperTypeObjectTitle>
            <AccordionDetails>
              {typeElements.map((element, index) => {
                return (
                  <FormGroup key={index}>
                    <WrapperTypeElementTitle>
                      <div className="icon-title">
                        {showTypeElement[element.name] ? (
                          <VisibilityIcon
                            sx={{
                              color: "#575757",
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerClickFilterTypeElement(element.name)
                            }
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{
                              color: "#575757",
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerClickFilterTypeElement(element.name)
                            }
                          />
                        )}
                        <p>{element.name.toLowerCase()}</p>
                      </div>
                      <img
                        src={element.icon}
                        width="30px"
                        height="30px"
                        alt="ico"
                      />
                    </WrapperTypeElementTitle>
                  </FormGroup>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }
  );

  return (
    <>
      <CustomizedAccordion
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormInput
            type="text"
            value={regionName}
            onChange={(e) => setRegionName(e.target.value)}
          ></FormInput>

          <WrapperButtonsIcons>
            <SaveTwoToneIcon
              onClick={handleOpen}
              sx={{
                cursor: "pointer",
                color: "#006ad4",
              }}
            />
            <DeleteTwoToneIcon
              onClick={cleanLocationsRegion}
              sx={{
                cursor: "pointer",
                color: "#c73e3e",
              }}
            />
          </WrapperButtonsIcons>

          {/* modal */}
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              style={{
                position: "relative",
                borderRadius: "10px",
              }}
            >
              <CardMedia
                component="img"
                height="240"
                width="40"
                image={REGION}
                alt="green iguana"
                sx={{
                  cursor: "pointer",
                }}
              />
              <HeadModal>
                <img
                  src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                  alt=""
                  className="neurona"
                />
                <span className="titleHeadModal">Region Details: </span>
              </HeadModal>
              <SummaryFeature>
                <h2>Region Details:</h2>
                <p class="geometry-type">
                  <b></b>
                </p>
                <div>
                  <p class="geometry-type">
                    <b>Region name:</b>
                  </p>
                  <input
                    type="text"
                    value={regionName}
                    onChange={(e) => setRegionName(e.target.value)}
                  />
                </div>
                <div>
                  <p class="geometry-type">
                    <b>Region type:</b>
                  </p>
                  <SelectRegion
                    name="regions"
                    id="region-select"
                    onChange={(e) => setTypeRegion(e.target.value)}
                  >
                    {!errorTypeRegion &&
                      typeRegions?.map((regionType, index) => {
                        return (
                          <option key={index} value={regionType.id}>
                            {regionType.type}
                          </option>
                        );
                      })}
                  </SelectRegion>
                </div>
                <div>
                  <p>
                    <b>Created by:</b>
                  </p>
                  {!errorAdminCompany && adminCompany && (
                    <input
                      readonly
                      type="text"
                      value={`${adminCompany.firstName} ${adminCompany.firstLastName}`}
                    />
                  )}
                  <p>
                    <b>Comments:</b>{" "}
                    <textarea
                      type="text"
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </p>
                </div>
              </SummaryFeature>

              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
              ></Typography>

              {adminCompany && (
                <ButtonCard
                  onClick={() => {
                    saveRegion();
                  }}
                >
                  <p>Confirm</p>
                </ButtonCard>
              )}
            </Box>
          </Modal>

          <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        </div>
        {accordionDetails}
      </CustomizedAccordion>
    </>
  );
};

export default AccordionObjectsPolygon;
