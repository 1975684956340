import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import RouteIcon from "@mui/icons-material/Route";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTypeJob } from "./../../../redux/actions/admin";

const ToggleButtons = () => {
  const dispatch = useDispatch();

  const typeJob = useSelector((state) => state.adminReducer.typeJob);

  const handleTypeJob = (event, nextView) => {
    dispatch(setTypeJob(nextView));
  };

  return (
    <ToggleButtonGroup
      value={typeJob[0].toUpperCase() + typeJob.slice(1).toLowerCase()}
      exclusive
      onChange={handleTypeJob}
      aria-label="text view"
    >
      <ToggleButton
        value="Point"
        aria-label="left aligned"
        sx={{
          width: "8.5vw",
        }}
      >
        <Tooltip title="Add point">
          <AddLocationAltIcon
            style={{
              color: "#08AFFF",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          />
        </Tooltip>
      </ToggleButton>
      <ToggleButton
        value="Route"
        aria-label="centered"
        sx={{
          width: "8.5vw",
        }}
      >
        <Tooltip title="Add route">
          <RouteIcon
            style={{
              color: "#08D19C",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
