import { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputComponent from "../Forms/Input";
import { Box, CircularProgress, Container, Grid } from "@mui/material";
import {
  LoginContainer,
  LoginForm,
  ArrowForward,
  ArrowRight,
  LoginButton,
  PStyled,
  Checkboxarea,
} from "./LoginElements";
import BannerDecimetrix from "./../forgotPassword/BannerDecimetrix";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/actions/index";
import { setCurrentUserParams } from "../../redux/actions";
import OpenDialog from "./../Dialogs/OpenDialog.jsx";
import { MessageError, MessageSuccess } from "../Forms/FormElements";

const Login = () => {
  const rememberedUser = JSON.parse(localStorage.getItem("rememberUser"));
  const [password, setPassword] = useState({
    field: rememberedUser ? rememberedUser.password : "",
    valid: null,
  });
  const [email, setEmail] = useState({
    field: rememberedUser ? rememberedUser.email : "",
    valid: null,
  });
  const [remember, setRemember] = useState(false);
  const [FormValidated, setFormValidated] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const dispatch = useDispatch();

  const expressions = {
    password: /^.{8,20}$/, // 4 a 12 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  const loginUser = async (body) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const response = await res.json();
      console.log(
        "🚀 ~ file: index.js ~ line 64 ~ loginUser ~ response",
        response
      );

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const reestablishUser = async (body) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/auth/forgotPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const response = await res.json();
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  const OnSubmit = async (event) => {
    event.preventDefault();
    if (email.valid === "true" && password.valid === "true") {
      const body = {
        email: email.field,
        password: password.field,
      };
      setMessage(null);
      setLoading(true);
      const payload = await loginUser(body);
      setLoading(false);

      if (payload.statusCode === 403) {
        setFormValidated(false);
        setMessage("Please confirm your account");
      } else {
        if (payload.statusCode === 401 || payload.statusCode === 404) {
          setFormValidated(false);
          setMessage("Incorrect user or password");
        } else {
          setFormValidated(true);
          dispatch(setCurrentUser(payload));
          dispatch(setCurrentUserParams(payload.user));
          // set login status in local storage
          localStorage.setItem("loginStatus", true);
          const { role } = payload.user.userType;
          // set user type in local storage
          localStorage.setItem("role", role);
          localStorage.setItem("token", payload.token);
          if (role === "OPERATOR") {
            //set user id in local storage
            localStorage.setItem("operatorId", payload.user.operator.id);
            localStorage.setItem(
              "adminCompanyId",
              payload.user.operator.adminCompanyId
            );
            localStorage.setItem("userId", payload.user.operator.userId);
            navigate("/hub");
          }
          if (role === "COMPANY ADMIN") {
            //set user id in local storage
            localStorage.setItem(
              "adminCompanyId",
              payload.user.adminCompany.id
            );
            localStorage.setItem("userId", payload.user.adminCompany.userId);
            navigate("/admin");
          }
          if (role === "DECIMETRIX ADMIN") {
            //set user id in local storage
            localStorage.setItem(
              "adminDecimetrixId",
              payload.user.adminDecimetrix.id
            );
            localStorage.setItem("userId", payload.user.adminDecimetrix.userId);
            navigate("/admin-decimetrix");
          }
          setMessage("");

          if (remember) {
            localStorage.setItem(
              "rememberUser",
              JSON.stringify({
                email: email.field,
                password: password.field,
              })
            );
          }
        }
      }
    } else {
      setFormValidated(false);
      setMessage("please fill form correctly.");
    }
  };
  const onHover = () => {
    setHover(!hover);
  };

  const forgetPassword = () => {
    navigate("/forgot-password");
    setMessage(null);
  };

  const forgotEmail = () => {
    setOpenDialog(true);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const contentDialog = {
    title: "Forgot your e-mail?",
    description: (
      <p
        style={{
          textAlign: "justify",
        }}
      >
        Your log in username is the one you use to log in the platform.If you
        don 't remember it, please contact{" "}
        <a href="mailto: support.greendragon@decimetrix.com">
          Decimetrix <sup> ® </sup>{" "}
        </a>{" "}
        via e - mail and provide your name, company and telephone number.We will
        help you as soon as possible.{" "}
      </p>
    ),
    agree: "Accept",
  };

  return (
    <LoginContainer>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={closeDialog}
        content={contentDialog}
      />{" "}
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <BannerDecimetrix />
          </Grid>{" "}
          <Grid item xs={12} sm={12} md={6}>
            <LoginContainer className="small">
              <LoginForm onSubmit={OnSubmit}>
                <InputComponent
                  state={email}
                  changeState={setEmail}
                  type="email"
                  name="email"
                  label="E-mail address"
                  placeholder="your.email@gmail.com"
                  legendError="The email can only contain letters, numbers, periods, hyphens and underscore."
                  regularExpression={expressions.email}
                ></InputComponent>{" "}
                <InputComponent
                  state={password}
                  changeState={setPassword}
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="********"
                  legendError="the password must be at least 8 characters."
                  regularExpression={expressions.password}
                ></InputComponent>{" "}
                <LoginButton
                  type="submit"
                  onMouseEnter={onHover}
                  onMouseLeave={onHover}
                >
                  Log In {hover ? <ArrowForward /> : <ArrowRight />}{" "}
                </LoginButton>{" "}
                {loading ? (
                  <Box
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : null}
                <div>
                  {" "}
                  {FormValidated === false && (
                    <MessageError> {message} </MessageError>
                  )}{" "}
                  {FormValidated === true && (
                    <MessageSuccess> Form sent successfully! </MessageSuccess>
                  )}{" "}
                </div>{" "}
              </LoginForm>{" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Checkboxarea>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "5px",
                    }}
                    onChange={(e) => setRemember(e.target.checked)}
                  />
                  Remember me{" "}
                </Checkboxarea>{" "}
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <PStyled onClick={forgotEmail}> Forgot E - mail ? </PStyled>{" "}
                  <PStyled> or </PStyled>{" "}
                  <PStyled onClick={forgetPassword}>
                    {" "}
                    Forgot Password ?{" "}
                  </PStyled>{" "}
                </div>{" "}
              </div>{" "}
            </LoginContainer>{" "}
          </Grid>{" "}
        </Grid>{" "}
      </Container>{" "}
    </LoginContainer>
  );
};

export default Login;
