import { useState } from "react";

export default function useForm(fields) {
  // Stablesh initial state
  const initialState = {};
  Object.keys(fields).map((key) => {
    initialState[key] ??= { ...fields[key] };
    return false;
  });
  const [form, setForm] = useState({ ...initialState });

  // Clear field
  const clearField = () => {
    setForm({ ...initialState });
  };

  // On Change any value
  const handleChangeForm = ({ target }) => {
    const { value, name, validate } = target;
    setForm((currentFilter) => ({
      ...currentFilter,
      [name]: {
        ...currentFilter[name],
        value: value || value === "" ? value : currentFilter[name]["value"],
        validate: validate ? validate : currentFilter[name]["validate"],
      },
    }));
  };

  // Change form for specific values
  const changeFormForSpecificValues = (newValues) => {
    Object.keys(newValues).map((key) => {
      setForm((currentFilter) => ({
        ...currentFilter,
        [key]: {
          ...currentFilter[key],
          value: newValues[key],
        },
      }));
      return false;
    });
  };

  // Return state and methods
  return [form, { handleChangeForm, changeFormForSpecificValues, clearField }];
}
