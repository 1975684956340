import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArticleIcon from '@mui/icons-material/Article';

export const Document = (props) => (
  <Card
    sx={{ height: '100%', width: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
          >
            DOCUMENTS 

          </Typography>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {/* 2 collections */}
            <b> 
            {props.data} objects
            </b>  
            
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
          alt="Remy Sharp"
            sx={{
              backgroundColor: '#DF358B',
              height: 46,
              width: 46
            }}
          >
            <ArticleIcon />
          </Avatar>
        </Grid>
      </Grid>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <ArrowUpwardIcon color="success" />
        <Typography
          color="success"
          sx={{
            mr: 1
          }}
          variant="body2"
        >
          {/* 50% */}
          {props.datamonth}%
        </Typography>
        <Typography
          color="textSecondary"
          variant="caption"
        >
          Since last month
        </Typography>
      </Box>
    </CardContent>
  </Card>
);
