import { useNavigate } from "react-router-dom";
import Icon2 from "images/dashboardV2.svg";
import Icon3 from "images/user.svg";
import Icon4 from "images/undraw_footprint.svg";
import Icon5 from "images/setup.svg";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";

import useGet from "hooks/useFetch";

import {
  UserContainer,
  UserH1,
  UserH2,
  HubContainer,
  HubWrapper,
  HubCard,
  HubIcon,
  HubH1,
  HubH2,
  HubP,
  UserH2Flex,
} from "./HubElements";

const AdminHub = () => {
  const navigate = useNavigate();

  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const [adminDecimetrix, errorAdminDecimetrix] = useGet(
    `api/v1/admin-decimetrix/${adminDecimetrixId}`
  );

  const addNewOperator = () => {
    navigate("/admin-decimetrix-register");
  };
  const onClickDashboard = () => {
    navigate("/dashboard-admin-decimetrix");
  };

  const capitalizeFirstLetter = (string) => {
    if (string === null) {
      return "";
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };
  const formatDate = (string) => {
    if (string === null) {
      return "";
    } else {
      const newString = string.replace("T", " ");
      return newString.replace(".000Z", " ");
    }
  };

  const navigation = (url) => {
    navigate(url);
  };

  return (
    <Container
      style={{
        paddingTop: 50,
      }}
      maxWidth="xl"
    >
      <UserContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            {adminDecimetrix && !errorAdminDecimetrix ? (
              <UserH1>Hello, Welcome to a Green World!</UserH1>
            ) : (
              <UserH1>Loading ...</UserH1>
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <UserH2Flex>
                {" "}
                <b>Last Login:</b>{" "}
                {adminDecimetrix !== undefined &&
                  adminDecimetrix !== null &&
                  `${formatDate(
                    adminDecimetrix.user.userVerification.lastLogin
                  )}`}
              </UserH2Flex>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            <UserH2>Please select the module that you want to use.</UserH2>
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <UserH2Flex>
                {" "}
                <b>Role: </b>
                {adminDecimetrix !== undefined && adminDecimetrix !== null
                  ? `${capitalizeFirstLetter(
                      adminDecimetrix.user.userType.role.toLowerCase()
                    )}`
                  : null}{" "}
              </UserH2Flex>
            </Box>
          </Grid>
        </Grid>
      </UserContainer>
      <HubContainer>
        <HubH1>
          Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon <br />{" "}
          Carbon Footprint Management System
        </HubH1>
        <HubWrapper>
          <HubCard onClick={onClickDashboard}>
            <HubH2>DASHBOARD</HubH2>
            <HubIcon src={Icon2} id="1" />
            <HubP>Report of all the objects collected.</HubP>
          </HubCard>

          <HubCard onClick={addNewOperator} id="1">
            <HubH2>COMPANY MANAGERS</HubH2>
            <HubIcon src={Icon3} />
            <HubP>Add a company manager.</HubP>
          </HubCard>

          <HubCard onClick={() => navigation("/config-admin")}>
            <HubH2>SETUP</HubH2>
            <HubIcon src={Icon5} />
            <HubP>Configure app settings, customization, more.</HubP>
          </HubCard>

          <HubCard id="1">
            <HubH2>REPORTS</HubH2>
            <HubIcon src={Icon4} />
            <HubP>View reports.</HubP>
          </HubCard>
        </HubWrapper>
      </HubContainer>
    </Container>
  );
};

export default AdminHub;
