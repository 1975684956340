import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import airplane from "../lotties/airplane.json";
import email from "../lotties/email-sent.json";
import NavBar from "../components/NavBar";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

import {
  Confirmation,
  TitleConfirmation,
  Pconfirmation,
  GoButton,
} from "./RecoveryElements";

const EmailConfirmation = () => {
  const [value, setValue] = useState(false);
  const [dialogs, setDialog] = useState(false);

  const { token, id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/user-verification/${id}`,
          { confirmationToken: token }
        );
        if (response.status === 201) {
          setValue(true);
          setDialog(true);
        }
        if (response.status === 401) {
          setValue(true);
          setDialog(true);
        }
      } catch (error) {
        setValue(true);
        setDialog(true);
      }
    };
    fetchData();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: airplane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const emailLottie = {
    loop: true,
    autoplay: true,
    animationData: email,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const continueHome = () => {
    navigate("/")
  };

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };


  return (
    <>
      <NavBar configNavbar={configNavbar} />
      <Container style={{
      paddingTop: 150,
    }} >
      <div>
        {value === false ? (
          <div>
            <Lottie options={defaultOptions} height={500} width={500} />
          </div>
        ) : (
          <>
          <Confirmation>
            <Lottie options={emailLottie} height={350} width={350} />
            <TitleConfirmation>E-mail confirmed!</TitleConfirmation>
            <Pconfirmation>Your account has been successfully registered. To complete the process please log in and explore the Green Dragon.</Pconfirmation>
            <GoButton to="/signIn">Go to log in</GoButton>
          </Confirmation>
          </>
        )}

      </div>
      </Container>
    </>
  );
};

export default EmailConfirmation;
