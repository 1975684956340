import { useEffect, useState } from "react";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import Container from "@mui/material/Container";
import { ImgIconOperation, MapCard } from "./MapViewElements";
import Map, {
  Marker,
  NavigationControl,
  AttributionControl,
  Source,
  Layer,
} from "react-map-gl";
import LayersIcon from "@mui/icons-material/Layers";

import {
  DrawerMapStyle,
  ButtonMapStyle,
  ImgButton,
} from "../../components/Admin/DigitalTwin/MapElements";
import OpenDialog from "./../../components/Dialogs/OpenDialog";

import { NamePlateMarker, ButtonBaseMap } from "./MapViewElements";
import OperationDetails from "../ImageGallery/OperationDetails";
import { useDispatch } from "react-redux";
import {
  setOperationDetails,
  setShowOperationDetails,
} from "../../redux/actions";

const MapView = ({
  eventId,
  typeOperation,
  objectId,
  location,
  urlIcon,
  typeObjectId,
  otherMarkers,
}) => {
  const [marks, setMarks] = useState([]);
  const [operation, setOperation] = useState([]);
  const [anemometer, setAnemometer] = useState([]);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const [baseMap, setBaseMap] = useState("streets-v11");

  const [haveObjects, setHaveObjects] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const [events, setEvents] = useState();

  const changeBaseMap = (event) => {
    setBaseMap(event.target.value);
    setHaveObjects(false);
  };

  useEffect(() => {
    typeObjectId === 1 &&
      axios
        .get(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/object-ghg/operations/${objectId}`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        )
        .then((response) => {
          const res = response.data;
          if (res !== null) {
            setMarks(res.objectsOperations);
          }
        })
        .catch((err) => {
          console.error(err);
        });

    typeObjectId === 1 &&
      axios
        .get(`${process.env.REACT_APP_URL_BACKEND}api/v1/operation/`, {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        })
        .then((response) => {
          const res = response.data;
          setOperation(res);
        });

    if (typeOperation !== undefined) {
      typeOperation.name === "INSPECTION FLIR" &&
        axios
          .get(
            `${process.env.REACT_APP_URL_BACKEND}api/v1/inspection-flir/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;
            setAnemometer(res.operationAnemometer);
          })
          .catch((err) => {
            console.log(err);
            setAnemometer([]);
          });

      typeOperation.name === "LEAK" &&
        axios
          .get(
            `${process.env.REACT_APP_URL_BACKEND}api/v1/leak/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
      typeOperation.name === "INSPECTION SNIFFER" &&
        axios
          .get(
            `${process.env.REACT_APP_URL_BACKEND}api/v1/inspection-sniffer/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
      typeOperation.name === "REPERATION" &&
        axios
          .get(
            `${process.env.REACT_APP_URL_BACKEND}api/v1/reparation/anemometer/${eventId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          )
          .then((response) => {
            const res = response.data;

            setAnemometer(res.operationAnemometer);
          });
    }
  }, [objectId]);

  // When click in some operation chnage view in the modal
  const handleClickMarker = (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} fromObject={true} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
    dispatch(setShowOperationDetails(true));
  };

  useEffect(() => {
    const typeOperations = [
      ["inspection-sniffer", "inspectionSniffer"],
      ["inspection-flir", "inspectionFlir"],
      ["leak", "leak"],
      ["reparation", "Reparation"],
    ];

    const getSniffers = async (objectOperation) => {
      const { typeOperationId } = objectOperation;
      const [path, type] = typeOperations[typeOperationId - 1];
      const { id } = objectOperation[type];
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/${path}/anemometer/${id}`
      );

      const { operationAnemometer } = data;
      setAnemometer((currentAnemometers) => [
        ...currentAnemometers,
        ...operationAnemometer,
      ]);
    };
    marks?.map((object) =>
      object?.operations?.map((objectOperation) => getSniffers(objectOperation))
    );
  }, [marks]);

  // show Events markers
  const showEventMarkers = () => {
    return marks.map((object, index) => {
      let icon = null;
      return object.operations.map((objectOperation, index) => {
        // if type operation is ispection sniffer
        if (objectOperation.typeOperationId === 1) {
          operation.map((operation) => {
            if (operation.id === 1) {
              const urlIcon = operation.urlIcon;
              icon = urlIcon;
            }
          });
        }
        // if type operation is ispection flir
        if (objectOperation.typeOperationId === 2) {
          operation.map((operation) => {
            if (operation.id === 2) {
              const urlIcon = operation.urlIcon;
              icon = urlIcon;
            }
          });
        }
        // if type operation is ispection leak
        if (objectOperation.typeOperationId === 3) {
          operation.map((operation) => {
            if (operation.id === 3) {
              const urlIcon = operation.urlIcon;
              icon = urlIcon;
            }
          });
        }
        // if type operation is ispection repair
        if (objectOperation.typeOperationId === 4) {
          operation.map((operation) => {
            if (operation.id === 4) {
              const urlIcon = operation.urlIcon;
              icon = urlIcon;
            }
          });
        }
        return (
          <div key={index}>
            {marks && (
              <Marker
                latitude={Number(
                  object.operations[0].locationOperation.latitude
                )}
                longitude={Number(
                  object.operations[0].locationOperation.longitude
                )}
                style={{
                  cursor: "pointer",
                }}
              >
                <NamePlateMarker
                  id={object.id}
                  onClick={() => handleClickMarker(object.id)}
                >
                  <ImgIconOperation
                    id={object.id}
                    src={icon}
                    alt="nameplate marker"
                    style={{
                      cursor: "pointer",
                    }}
                  ></ImgIconOperation>
                </NamePlateMarker>
              </Marker>
            )}
          </div>
        );
      });
    });
  };

  // get all events of the Object GHG

  useEffect(() => {
    const fetchData = async () => {
      try {
        const AllEvents = [];
        setEvents(AllEvents);
        return marks.map(async (event, id) => {
          if (event.operations.length > 0) {
            const allEvents = {
              events: [],
              objectGhgId: objectId,
              objectGhgLocation: [],
            };
            AllEvents.push(allEvents);
            const objectGhgLocation = [location.longitude, location.latitude];
            allEvents.objectGhgLocation.push(objectGhgLocation);

            return event.operations.map((objectOperation, index) => {
              const locationOperation = [
                objectOperation.locationOperation.longitude,
                objectOperation.locationOperation.latitude,
              ];
              const geojsonLineString = {
                type: "Feature",
                properties: {},
                geometry: {
                  type: "LineString",
                  coordinates: [objectGhgLocation, locationOperation],
                },
              };
              allEvents.events.push(geojsonLineString);
            });
          }
        });
      } catch (error) {
        console.error("error:", error);
      }
    };
    fetchData();
  }, [marks]);

  // show joins object GHG on map
  const EventsJoins = () => {
    if (events && events.length > 0) {
      const eventsFlat = events.flatMap((event) => event.events);
      return eventsFlat.map((event, index) => {
        return (
          <div key={index}>
            <Source type="geojson" data={event} key={index}>
              <Layer
                key={index}
                id={event.id}
                type="line"
                paint={{
                  "line-color": "#00957f",
                  "line-width": 2,
                  "line-dasharray": [1, 2],
                }}
              />
            </Source>
          </div>
        );
      });
    }
  };

  // show event anemometer markers
  const showOperationEventMarkers = () => {
    return anemometer.map((object, index) => {
      return (
        <div key={index}>
          {object.anemometer !== null && (
            <Marker
              latitude={Number(object.anemometer.locationAnemometer.latitude)}
              longitude={Number(object.anemometer.locationAnemometer.longitude)}
            >
              <NamePlateMarker
                // style={{
                //   cursor: "pointer",
                // }}
                id={anemometer.id}
                // onClick={() => HandleClickMarker(object.id)}
              >
                <img
                  id={anemometer.id}
                  src={
                    "https://bluedragon-uploaded-files.s3.us-east-2.amazonaws.com/anemometerIconMap.png"
                  }
                  alt="nameplate marker"
                ></img>
              </NamePlateMarker>
            </Marker>
          )}
        </div>
      );
    });
  };

  const showOthersMarkers = () => {
    return otherMarkers.map((marker) => (
      <Marker
        latitude={Number(marker.latitude)}
        longitude={Number(marker.longitude)}
      >
        <NamePlateMarker>
          <img
            // src={objectLocation.object.typeElement.icon}
            src={marker.urlIcon}
            alt="nameplate marker"
          ></img>
        </NamePlateMarker>
      </Marker>
    ));
  };

  // Modal change base map
  const handleBaseMap = () => {
    setHaveObjects(true);
    setContentDialog({
      title: "Base Map",
      description: (
        <DrawerMapStyle>
          <ButtonMapStyle value="streets-v11" onClick={changeBaseMap}>
            <ImgButton
              src={
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3tZaAJFEXlkxY3W3cMkbEfU95DFoJTipPww&usqp=CAU"
              }
            ></ImgButton>
            Streets
          </ButtonMapStyle>

          <ButtonMapStyle value="satellite-v9" onClick={changeBaseMap}>
            <ImgButton
              src={
                "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_SATELLITE.png"
              }
            ></ImgButton>
            Satellite
          </ButtonMapStyle>

          <ButtonMapStyle value="light-v10" onClick={changeBaseMap}>
            <ImgButton
              src={
                "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_LIGHT_V2.png"
              }
            ></ImgButton>
            Light
          </ButtonMapStyle>

          <ButtonMapStyle value="dark-v10" onClick={changeBaseMap}>
            <ImgButton
              src={
                "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_DARK_V2.png"
              }
            ></ImgButton>
            Dark
          </ButtonMapStyle>
        </DrawerMapStyle>
      ),
    });
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        width: "100%",
        height: "90%",
        padding: "0px 0px 24px 0px",
      }}
    >
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        content={contentDialog}
      />
      <MapCard>
        <Map
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          initialViewState={{
            latitude: location.latitude,
            longitude: location.longitude,
            zoom: 18,
          }}
          style={{ width: "100%", height: 434, margin: "auto" }}
          mapStyle={`mapbox://styles/mapbox/${baseMap}`}
          attributionControl={false}
        >
          {/* Button layer map */}
          <ButtonBaseMap onClick={handleBaseMap}>
            <LayersIcon
              style={{
                color: "#282828",
                fontSize: "1.6rem",
              }}
            />
          </ButtonBaseMap>
          <Marker
            latitude={Number(location.latitude)}
            longitude={Number(location.longitude)}
          >
            <NamePlateMarker>
              <img
                // src={objectLocation.object.typeElement.icon}
                src={urlIcon}
                alt="nameplate marker"
              ></img>
            </NamePlateMarker>
          </Marker>

          {otherMarkers && showOthersMarkers()}

          <AttributionControl customAttribution="© Decimetrix 2022" />
          <NavigationControl style={navControlStyle} />
          {typeObjectId === 1 &&
            marks.length > 0 &&
            operation.length > 0 &&
            showEventMarkers()}
          {typeObjectId === 1 &&
            marks.length > 0 &&
            operation.length > 0 &&
            EventsJoins()}
          {anemometer.length > 0 ? showOperationEventMarkers() : null}
        </Map>
      </MapCard>
    </Container>
  );
};

export default MapView;
