import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
// check layer
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import logoWhite from "../../images/Digital-Transformation.png";

import LoadCSV from "./LoadCSV";
import { useDispatch, useSelector } from "react-redux";
import {
  showDrawerDigitalTwin,
  showMarkersDigitalTwin,
  showMarkerByTypeElementId,
  showMarkersByActivities,
} from "../../redux/actions";
import AppBar from "./AppBar";

import { Img, NavbarLogoR } from "./PowerSystemsElements";
import axios from "axios";
import { useEffect, useState } from "react";

const drawerWidth = 400;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const PersistentDrawerLeft = () => {
  // useSatet to TyeElements
  const [typeElements, setTypeElements] = useState([]);
  // useState to uniqueActivityNames
  const [uniqueActivityNames, setUniqueActivityNames] = useState([]);

  const theme = useTheme();
  const open = useSelector(
    (state) => state.digitalTwinReducer.showDrawerDigitalTwin
  );
  const showMarkersDT = useSelector(
    (state) => state.digitalTwinReducer.showMarkersDigitalTwin
  );

  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    dispatch(showDrawerDigitalTwin());
  };

  const handleDrawerClose = () => {
    dispatch(showDrawerDigitalTwin());
  };

  const handleShowMarkers = (event) => {
    dispatch(showMarkersDigitalTwin());
  };

  //get customerId from localStorage
  const customerId = localStorage.getItem("customerId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/typeElements/${customerId}`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );

        const res = await response.data;
        const urls = generatorUrl(res);
        const typeElements = await getElementsWithDetails(urls);
        const activityNames = [];
        const activities = typeElements.map((typeElement) => {
          activityNames.push(typeElement.activity.name);
        });
        //filter duplicate names in  activityNames
        const uniqueActivityNames = activityNames.filter((name, index) => {
          return activityNames.indexOf(name) === index;
        });
        setUniqueActivityNames(uniqueActivityNames);

        setTypeElements(typeElements);
      } catch (error) {
        // setError(error);
      }
    };

    fetchData();
  }, [customerId]);

  const generatorUrl = (typeElements) => {
    const urls = [];
    typeElements.map((typeElement) => {
      urls.push({
        url: `${process.env.REACT_APP_URL_BACKEND}api/v1/typeElements/${typeElement.typeElementId}`,
      });
    });
    return urls;
  };

  const getElementsWithDetails = (Elements) => {
    return Promise.all(Elements.map((Element) => axios.get(Element.url))).then(
      (ElementResponses) => {
        return ElementResponses.map((response) => response.data);
      }
    );
  };

  //create json with typeElementsId
  const jsonTypeElementId = {};
  typeElements.map((typeElement) => {
    jsonTypeElementId[typeElement.id] = true;
  });

  // function to handle click on checkbox
  const handleClickLayers = async (typeElementId) => {
    //conditional to change from true to false
    if (jsonTypeElementId[typeElementId] === true) {
      jsonTypeElementId[typeElementId] = false;
    } else {
      jsonTypeElementId[typeElementId] = true;
    }
    dispatch(showMarkerByTypeElementId({ ...jsonTypeElementId }));
  };

  // create json with activityType
  const jsonActivityType = {};
  uniqueActivityNames.map((activityName) => {
    jsonActivityType[activityName] = true;
  });
  //funtion to handle click on checkbox in activityNames
  const handleClickActivityNames = async (activityName) => {
    //conditional to change from true to false
    if (jsonActivityType[activityName] === true) {
      jsonActivityType[activityName] = false;
    } else {
      jsonActivityType[activityName] = true;
    }
    dispatch(showMarkersByActivities({ ...jsonActivityType }));
  };

  const iteratorMarkers = () => {
    dispatch(showMarkerByTypeElementId({ ...jsonTypeElementId }));
    dispatch(showMarkersByActivities({ ...jsonActivityType }));

    return uniqueActivityNames.map((activity, index) => {
      return (
        <div key={index}>
          {showMarkersDT && (
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <Accordion
                defaultExpanded={false}
                style={{
                  boxShadow: "none",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>
                    {
                      <Checkbox
                        defaultChecked
                        onClick={() => handleClickActivityNames(activity)}
                      />
                    }
                    {activity}
                  </Typography>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  ></AccordionSummary>
                </div>
                <AccordionDetails
                  style={{
                    padding: "0px",
                    marginLeft: "45px",
                  }}
                >
                  {filterTypeElementsByActivityName(activity).map(
                    (typeElement) => {
                      return (
                        showMarkersDT && (
                          <FormGroup id={typeElement.id}>
                            <FormControlLabel
                              label={typeElement.name}
                              control={
                                <Checkbox
                                  defaultChecked
                                  onClick={() =>
                                    handleClickLayers(typeElement.id)
                                  }
                                />
                              }
                            />
                          </FormGroup>
                        )
                      );
                    }
                  )}
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      );
    });
  };

  const filterTypeElementsByActivityName = (activityName) => {
    return typeElements.filter((typeElement) => {
      return typeElement.activity.name === activityName;
    });
  };
  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon sx={{ fontSize: "2.5rem" }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <NavbarLogoR to="/hub">
              <Img src={logoWhite} />
            </NavbarLogoR>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <div>
          <div>
            <Divider />
            <List>
              <ListItem button>
                <ListItemIcon>
                  <UploadFileIcon />
                </ListItemIcon>
                <LoadCSV />
              </ListItem>
            </List>
            <Divider />
            <Divider />
            <List
              sx={{
                height: "100%",
                // width: 700,
                // maxWidth: 100,
                // bgcolor: "background.paper",
                position: "bottom",
                // // overflow: "auto",
                // maxHeight: 1000,
                // Height: "100%",
                // "& ul": { padding: 0 },
              }}
              subheader={<li />}
            >
              <Accordion
                defaultExpanded={true}
                style={{
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  // aria-controls="panel1a-content"
                  // id="panel1a-header"
                >
                  <Typography>Layers</Typography>
                </AccordionSummary>

                <Accordion
                  defaultExpanded={true}
                  style={{
                    boxShadow: "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                  >
                    <Typography>
                      {<Checkbox defaultChecked onChange={handleShowMarkers} />}
                      Markers
                    </Typography>
                  </AccordionSummary>
                  {typeElements.length > 0 && <>{iteratorMarkers()}</>}
                </Accordion>
              </Accordion>
            </List>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default PersistentDrawerLeft;