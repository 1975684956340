import Typography from "@mui/material/Typography";
// check layer
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// custom style
import {
  WrapperTypeObjectTitle,
  WrapperTypeElementTitle,
} from "./AccordionsElements";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  showMarkerseByTypeObjectLoad,
  showMarkerseByTypeElementLoad,
} from "redux/actions/admin";
import { useEffect } from "react";

const deleteDuplicateObjects = (objects) => {
  return objects.filter(
    (object, index, self) =>
      index === self.findIndex((t) => t.name === object.name)
  );
};

const getTypeElementsNames = (dataObjects) => {
  const typeElementsNames = dataObjects.map((object) => {
    return object.typeObjectId === 1
      ? object.name
      : object.typeObjectId === 2
      ? object.name
      : object.typeObjectId === 3
      ? object.name
      : object.typeObjectId === 4
      ? object.name
      : null;
  });
  return [...new Set(typeElementsNames)];
};

const AccordionLoadCsv = ({ dataObjects }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const typeObjectsNames = dataObjects.map((object) => object.type);

  // filter duplicate typeObjectsNames
  const uniqueTypeObjectsNames = [...new Set(typeObjectsNames)];

  useEffect(() => {
    const showTypeObject = {};
    uniqueTypeObjectsNames.map(
      (typeObjectName) => (showTypeObject[typeObjectName] = true)
    );
    dispatch(showMarkerseByTypeObjectLoad({ ...showTypeObject }));

    const showTypeElement = {};
    const typeElementsNames = getTypeElementsNames(dataObjects);
    typeElementsNames.map(
      (typeElementName) => (showTypeElement[typeElementName] = true)
    );
    dispatch(showMarkerseByTypeElementLoad({ ...showTypeElement }));
  }, [dataObjects, dispatch]);

  const showTypeObject = useSelector(
    (state) => state.adminReducer.markerByTypeObjectLoad
  );

  const handlerClickFilterTypeObject = (typeObjectName) => {
    showTypeObject[typeObjectName] = !showTypeObject[typeObjectName];
    dispatch(showMarkerseByTypeObjectLoad({ ...showTypeObject }));
  };

  const showTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElementLoad
  );

  const handlerClickFilterTypeElement = (typeElementName) => {
    showTypeElement[typeElementName] = !showTypeElement[typeElementName];
    dispatch(showMarkerseByTypeElementLoad({ ...showTypeElement }));
  };

  const getObjectAndIcon = (objects) => {
    const typesElementNames = objects.map((object) => {
      return {
        name: object.name,
        icon: object.icon,
      };
    });
    const uniqueTypesElementNames = deleteDuplicateObjects(typesElementNames);
    return uniqueTypesElementNames;
  };

  const accordionDetails = uniqueTypeObjectsNames.map(
    (typeObjectName, index) => {
      let typeElements = null;
      // filter typeElement by typeObjectName
      const objects = dataObjects.filter(
        (object) => object.type === typeObjectName
      );

      typeElements = getObjectAndIcon(objects);

      return (
        <div key={index}>
          <Accordion
            sx={{
              paddingLeft: "5px",
              border: "none",
              boxShadow: "none",
            }}
          >
            <WrapperTypeObjectTitle>
              <div className="icon-title">
                {showTypeObject[typeObjectName] ? (
                  <VisibilityIcon
                    sx={{
                      color: "#575757",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlerClickFilterTypeObject(typeObjectName)}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{
                      color: "#272727",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlerClickFilterTypeObject(typeObjectName)}
                  />
                )}

                <p>Objects {typeObjectName.toLowerCase()}</p>
              </div>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              ></AccordionSummary>
            </WrapperTypeObjectTitle>
            <AccordionDetails>
              {typeElements.map((element, index) => {
                return (
                  <FormGroup key={index}>
                    <WrapperTypeElementTitle>
                      <div className="icon-title">
                        {showTypeElement[element.name] ? (
                          <VisibilityIcon
                            sx={{
                              color: "#575757",
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerClickFilterTypeElement(element.name)
                            }
                          />
                        ) : (
                          <VisibilityOffIcon
                            sx={{
                              color: "#575757",
                              margin: "0px 10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handlerClickFilterTypeElement(element.name)
                            }
                          />
                        )}
                        <p>{element.name.toLowerCase()}</p>
                      </div>
                      <img
                        src={element.icon}
                        width="30px"
                        height="30px"
                        alt="ico"
                      />
                    </WrapperTypeElementTitle>
                  </FormGroup>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      );
    }
  );

  return (
    <>
      <CustomizedAccordion
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
      >
        {accordionDetails}
      </CustomizedAccordion>
    </>
  );
};

export default AccordionLoadCsv;
