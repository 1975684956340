import styled from "styled-components";
import { PRIMARY_COLOR } from "./../../utils/const";

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }
`;

export const ContentButtonGallery = styled.div`
  width: 100%;
  padding: 10px 0px 10px 0px;
`;

export const ContentButton = styled.div`
  width: 93%;
  padding: 10px 10px 20px 10px;
`;

export const ButtonDeleteElement = styled.div`
  background: #e13030;
  color: white;
  padding: 8px 8px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;

  :hover {
    background-color: #ff2121;
    box-shadow: rgba(255, 61, 61, 0.7) 0px 0px 5px;
  }
`;
