import React from "react";
import { useState, useEffect } from "react";
// @material-ui/core components
import Card from "@mui/material/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import WorkIcon from "@mui/icons-material/Work";
import BadgeIcon from "@mui/icons-material/Badge";
import DescriptionIcon from "@mui/icons-material/Description";
import OpenDialog from "../Dialogs/OpenDialog";
import { setProfileImage } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUserParams } from "redux/actions";
import avatar from "assets/user.jpg";
import CircularProgress from "@mui/material/CircularProgress";

import {
  WrapperForm,
  Form,
  Wrapperbtn,
  AddButton,
  ProfileButton,
  ProfileFormContainer,
} from "../Forms/FormElements";

import InputComponent from "../Forms/InputProfile";
import { ProfileStyle } from "./profileStyle";

const Item = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: "left",
}));

const Item2 = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function UserProfile({ userId }) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });

  const [role, setRole] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [photosFile, setPhotosFile] = useState([]);
  const [dialogs, setDialog] = React.useState(false);
  const [pass, setPass] = useState(false);
  const [passdialog, setPassdialog] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [user, setUser] = useState({});
  const [showinfo, setShowinfo] = useState(false);
  const [openDialogSavePhoto, setOpenDialogSavePhoto] = useState({
    title: "Save picture",
    description: "Are you sure you want to save the profile picture?",
    disagree: "no",
    agree: "yes",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/users/${userId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
        const res = await response.json();
        setUser(res);
        dispatch(setCurrentUserParams(res));
        if (res.userTypeId === 2) {
          setFirstName(res.operator.firstName);
          setSecondName(res.operator.secondName);
          setFirstLastName(res.operator.firstLastName);
          setEmail(res.email);
          setRole(capitalizeFirstLetter(res.userType.role.toLowerCase()));
          setShowinfo(true);
        }
        if (res.userTypeId === 1) {
          setFirstName(res.adminCompany.firstName);
          setSecondName(res.adminCompany.secondName);
          setFirstLastName(res.adminCompany.firstLastName);
          setEmail(res.email);
          setRole(capitalizeFirstLetter(res.userType.role.toLowerCase()));
          setShowinfo(true);
        }
        if (res.userTypeId === 3) {
          setFirstName(res.adminDecimetrix.firstName);
          setSecondName(res.adminDecimetrix.secondName);
          setFirstLastName(res.adminDecimetrix.firstLastName);
          setEmail(res.email);
          setRole(capitalizeFirstLetter(res.userType.role.toLowerCase()));
          setShowinfo(true);
        }
        return res;
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [dispatch, token, userId]);

  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );

  // define functions to handle photo upload
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setProfileImage([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setDialog(true);
  };

  const savePhotos = async () => {
    photosFile.forEach(async (file) => {
      setOpenDialogSavePhoto({
        title: "Save picture",
        description: "Are you sure you want to save the profile picture?",
        disagree: "no",
        agree: <CircularProgress />,
      });
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", file);
      const urlPhoto = await fetch(
        `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
        {
          method: "POST",
          body: formData,
        }
      );

      const photo = await urlPhoto.json();
      const body = {
        urlPhoto: photo.urlFile,
      };

      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/users/${userId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: JSON.stringify(body),
        }
      );
      await res.json();
      setPhotosFile([]);
      setDialog(false);
      setOpenDialogSavePhoto({
        title: "Save picture",
        description: "Are you sure you want to save the profile picture?",
        disagree: "no",
        agree: "yes",
      });
      return res;
    });
  };

  const capitalizeFirstLetter = (string) => {
    if (string === null) {
      return "";
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  const changePassword = () => {
    setPass(true);
  };

  const executeChangePassword = async () => {
    const body = {
      email: email,
    };
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/auth/changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const response = await res.json();
      setPass(false);
      setPassdialog(true);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <br />
      {showinfo && (
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <br />
          <ProfileStyle>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={5}>
                <Card
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    "box-shadow": "0px 0px 10px 0 rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    spacing={2}
                  >
                    <Avatar
                      alt="Avatar"
                      src={user.urlPhoto === null ? avatar : user.urlPhoto}
                      sx={{ width: 206, height: 210 }}
                    ></Avatar>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    fullwidth="true"
                    spacing={2}
                  >
                    <Item>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <WorkIcon sx={{ mr: 1 }} />{" "}
                        </Grid>
                        <Grid item className="profile-details-item">
                          <b>Role: </b>
                          {`${capitalizeFirstLetter(
                            user.userType.role.toLowerCase()
                          )}`}
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <BadgeIcon sx={{ mr: 1 }} />{" "}
                        </Grid>
                        <Grid item className="profile-details-item">
                          <b>Name: </b>{" "}
                          {user.userTypeId === 1
                            ? `${capitalizeFirstLetter(
                                user.adminCompany.firstName
                              )} ${capitalizeFirstLetter(
                                user.adminCompany.secondName
                              )} ${capitalizeFirstLetter(
                                user.adminCompany.firstLastName
                              )}`
                            : user.userTypeId === 2
                            ? `${capitalizeFirstLetter(
                                user.operator.firstName
                              )} ${capitalizeFirstLetter(
                                user.operator.secondName
                              )} ${capitalizeFirstLetter(
                                user.operator.firstLastName
                              )}`
                            : user.userTypeId === 3
                            ? `${capitalizeFirstLetter(
                                user.adminDecimetrix.firstName
                              )} ${capitalizeFirstLetter(
                                user.adminDecimetrix.secondName
                              )} ${capitalizeFirstLetter(
                                user.adminDecimetrix.firstLastName
                              )}`
                            : null}
                        </Grid>
                      </Grid>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <DescriptionIcon sx={{ mr: 1 }} />{" "}
                        </Grid>
                        <Grid item className="profile-details-item">
                          {" "}
                          <b>Description: </b> {user.userType.description}
                        </Grid>
                      </Grid>
                    </Item>
                  </Stack>
                  <Stack>
                    <Item2>
                      <Wrapperbtn>
                        <ProfileButton>
                          <AddButton htmlFor="files">
                            <p>Edit Profile Picture</p>
                          </AddButton>
                          <input
                            id="files"
                            style={{ visibility: "hidden" }}
                            accept="image/*"
                            type="file"
                            name="file"
                            onChange={uploadFile}
                          />
                        </ProfileButton>
                        <ProfileButton onClick={changePassword}>
                          <AddButton>
                            <p>Change Password</p>
                          </AddButton>
                        </ProfileButton>
                      </Wrapperbtn>
                      <OpenDialog
                        openDialog={pass}
                        setOpenDialog={setPass}
                        execute={executeChangePassword}
                        content={{
                          title: "Change password",
                          description:
                            "Are you sure you want to change your password?",
                          disagree: "no",
                          agree: "yes",
                        }}
                      />
                      <OpenDialog
                        openDialog={passdialog}
                        setOpenDialog={setPassdialog}
                        content={{
                          title: "Check your email",
                          description:
                            "A e-mail has been send. Please follow the password change instructions. Please remember to check your spam or junk mail folder",
                          disagree: "Continue",
                        }}
                      />
                      <OpenDialog
                        openDialog={dialogs}
                        setOpenDialog={setDialog}
                        execute={savePhotos}
                        content={openDialogSavePhoto}
                      />
                    </Item2>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <ProfileFormContainer>
                  <WrapperForm>
                    <Form action="">
                      <InputComponent
                        state={firstName}
                        changeState={setFirstName}
                        value={capitalizeFirstLetter(firstName)}
                        class="field left"
                        type="text"
                        label="First Name"
                        name="firstName"
                        // value={firstName}
                        legendError="The name can only contain letters."
                        disabled={"disabled"}
                        readonly
                        // regularExpression={expressions.firstName}
                      />
                      {secondName === null ? (
                        <InputComponent
                          state=""
                          changeState=""
                          value=""
                          type="text"
                          label="Second Name"
                          name="secondName"
                          legendError="The name can only contain letters."
                          disabled={"disabled"}
                          // regularExpression={expressions.secondName}
                        />
                      ) : (
                        <InputComponent
                          state={secondName}
                          changeState={setSecondName}
                          value={capitalizeFirstLetter(secondName)}
                          type="text"
                          label="Second Name"
                          name="secondName"
                          legendError="The name can only contain letters."
                          disabled={"disabled"}
                          // regularExpression={expressions.secondName}
                        />
                      )}
                      <InputComponent
                        state={firstLastName}
                        changeState={setFirstLastName}
                        value={capitalizeFirstLetter(firstLastName)}
                        type="text"
                        label="Last Name"
                        name="firstLastName"
                        legendError="The last name can only contain letters."
                        disabled={"disabled"}
                        // regularExpression={expressions.firstLastName}
                      />
                      <InputComponent
                        state={role}
                        changeState={setRole}
                        value={capitalizeFirstLetter(role)}
                        type="text"
                        label="Role"
                        name="role"
                        legendError="The role can only contain letters and spaces."
                        disabled={"disabled"}
                        // regularExpression={expressions.role}
                      />
                      <InputComponent
                        state={email}
                        changeState={setEmail}
                        value={capitalizeFirstLetter(email)}
                        type="email"
                        readOnly
                        label="E-mail"
                        placeholder="email@email.com"
                        name="email"
                        legendError="The e-mail can only contain letters, numbers, periods, hyphens and underscore."
                        disabled={"disabled"}
                        // regularExpression={expressions.email}
                      />
                    </Form>
                  </WrapperForm>
                </ProfileFormContainer>
              </Grid>
            </Grid>
          </ProfileStyle>
        </Container>
      )}
    </div>
  );
}
