import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "../../utils/const";

export const Label = styled("label")`
  background: ${PRIMARY_COLOR};
  width: 60%;
  margin: 0 -15px;
  border-radius: 10px;
  color: #f9f9f9;
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: ${SECOND_COLOR};
  }
`;

export const NavInfo = styled.div`
  margin-left: ${(props) => (props.tipo ? "200px" : "0px")};

  display: flex;
`;

export const CustomInput = styled.input`
  width: 50%;
`;

export const LoadCsv = styled("div")`
  width: 100vw;
  display: flex;
  align-items: center;

  input {
    position: absolute;
  }
`;

export const MarkerCard = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const Wrapperbtn = styled("div")`
  position: relative;
  margin-top: 1.5rem;
`;

export const NamePlateMarker = styled.button`
  background: none;
  border: none;

  img {
    width: 40px;
  }
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0e4d45;
    color: white;
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  span {
    color: ${PRIMARY_COLOR};
  }
  h1 {
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 40px;
    height: 40px;
    margin: 5px 0;
  }
`;
