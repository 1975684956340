import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import CardMarker from "./CardMarker";
import { useDispatch, useSelector } from "react-redux";
import {
  showCardMakerDigitalTwin,
  showDrawerObjectDt,
} from "../../redux/actions";
import { MarkerCard } from "./stylesPowerSystems";

const drawerWidth = 400;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerObjectDT = () => {
  const theme = useTheme();
  const open = useSelector(
    (state) => state.digitalTwinReducer.showDrawerObjectDt
  );
  const showCard = useSelector(
    (state) => state.digitalTwinReducer.cardMarkerDigitalTwin
  );
  const dispatch = useDispatch();

  const data = useSelector((state) => state.digitalTwinReducer.objectDataDT);

  const handleDrawerClose = () => {
    dispatch(showDrawerObjectDt(false));
    dispatch(showCardMakerDigitalTwin(false));
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>

        <>
          <ListItem>
            {showCard && Object.keys(data).length !== 0 ? (
              <MarkerCard>
                <CardMarker />
              </MarkerCard>
            ) : null}
          </ListItem>
        </>
      </Drawer>
    </>
  );
};

export default DrawerObjectDT;
