import NavBar from "../../components/NavBar/index";
import ImageGalleryComponent from "../../components/ImageGallery/index";
import { useParams } from "react-router-dom";

const ImageGallery = () => {
  const {date, customerId} = useParams();
  
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: { collections: "collections" },
    button: {
      link: `collections/${date}/${customerId}`,
      text: "Back",
    },
  };

  return (
    <div>
      
      <NavBar configNavbar={configNavbar} />
      <ImageGalleryComponent date={date} customerId ={customerId}/>
    </div>
  );
};

export default ImageGallery;
