import UserRegister from "../../components/Admin/UserRegister";
// import NavBar from "./../../components/Admin/UserRegister/NavBar";
import NavBar from "./../../components/Admin/NavBar";
const userRegister = () => {
  return (
    <>
      <NavBar linkLogo={"admin"} />
      <UserRegister />
    </>
  );
};

export default userRegister;
