import useGet from "../../hooks/useFetch";

import {
  DashboardsWrapper,
  DashboardsRow,
  Column1,
  Column2,
  DashboardsContainer,
  DashboardsForm,
  Img,
  FormCard,
  FormInput,
  WrapperInput,
  FormTitle,
  WrapperForms,
  LabelInput,
  FormInputComment,
  InfoSection,
  ItemInfo,
} from "./DashboardsElements";

const ImageGalleryComponent = ({ customerId }) => {
  const [paramPlates, error] = useGet(
    `api/v1/paramPlates/customer/${customerId}`
  );

  const iteratorOcrResult = () => {
    if (error) {
      return <DashboardsContainer>{error.message}</DashboardsContainer>;
    }

    if (!paramPlates || paramPlates.length === 0) {
      return <DashboardsContainer>no data</DashboardsContainer>;
    }

    return paramPlates?.map((param) => {
      const { electricParamMotor, generalParamMotor } = param;
      return (
        <DashboardsWrapper key={param.id}>
          <DashboardsRow>
            <Column1>
              <DashboardsContainer>
                <DashboardsForm>
                  <Img src={param.urlImgProcessed} />
                </DashboardsForm>
              </DashboardsContainer>
            </Column1>
            <Column2>
              <WrapperForms>
                <FormCard>
                  <FormTitle>Electric Param </FormTitle>
                  <WrapperInput>
                    <LabelInput htmlFor="hp">HP:</LabelInput>
                    <FormInput
                      type="text"
                      name="hp"
                      readOnly
                      value={electricParamMotor.hp}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>HZ:</LabelInput>
                    <FormInput
                      type="text"
                      name="HZ"
                      readOnly
                      value={electricParamMotor.hz}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Volt:</LabelInput>
                    <FormInput
                      type="text"
                      name="voltage"
                      readOnly
                      value={electricParamMotor.voltage}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Amperage:</LabelInput>
                    <FormInput
                      type="text"
                      name="amperage"
                      readOnly
                      value={electricParamMotor.amperage}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Efficiency:</LabelInput>
                    <FormInput
                      type="text"
                      name="efficiency"
                      readOnly
                      value={electricParamMotor.efficiency}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Phases:</LabelInput>
                    <FormInput
                      type="text"
                      name="phases"
                      readOnly
                      value={electricParamMotor.phases}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Power factor:</LabelInput>
                    <FormInput
                      type="text"
                      name="powerFactor"
                      readOnly
                      value={electricParamMotor.powerFactor}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>rpm:</LabelInput>
                    <FormInput
                      type="text"
                      name="rpm"
                      readOnly
                      value={electricParamMotor.rpm}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Service factor:</LabelInput>
                    <FormInput
                      type="text"
                      name="serviceFactor"
                      readOnly
                      value={electricParamMotor.serviceFactor}
                    />
                  </WrapperInput>
                </FormCard>
                <FormCard>
                  <FormTitle>General Param</FormTitle>
                  <WrapperInput>
                    <LabelInput>Cat:</LabelInput>
                    <FormInput
                      type="text"
                      name="CAT"
                      readOnly
                      value={generalParamMotor.cat}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Date:</LabelInput>
                    <FormInput
                      type="text"
                      name="date"
                      readOnly
                      value={generalParamMotor.date}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Weight:</LabelInput>
                    <FormInput
                      type="text"
                      name="weight"
                      readOnly
                      value={generalParamMotor.weight}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Duty:</LabelInput>
                    <FormInput
                      type="text"
                      name="duty"
                      readOnly
                      value={generalParamMotor.duty}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Enclousure:</LabelInput>
                    <FormInput
                      type="text"
                      name="enclosure"
                      readOnly
                      value={generalParamMotor.enclosure}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Insulation class:</LabelInput>
                    <FormInput
                      type="text"
                      name="insulationClass"
                      readOnly
                      value={generalParamMotor.insulationClass}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>manufacturer:</LabelInput>
                    <FormInput
                      type="text"
                      name="manufacturer"
                      readOnly
                      value={generalParamMotor.manufacturer}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Model number:</LabelInput>
                    <FormInput
                      type="text"
                      name="modelNumber"
                      readOnly
                      value={generalParamMotor.modelNumber}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>Serial number:</LabelInput>
                    <FormInput
                      type="text"
                      name="serialNumber"
                      readOnly
                      value={generalParamMotor.serialNumber}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>temperature:</LabelInput>
                    <FormInput
                      type="text"
                      name="temperature"
                      readOnly
                      value={generalParamMotor.temperature}
                    />
                  </WrapperInput>
                  <WrapperInput>
                    <LabelInput>frame:</LabelInput>
                    <FormInput
                      type="text"
                      name="frame"
                      readOnly
                      value={generalParamMotor.frame}
                    />
                  </WrapperInput>

                  <WrapperInput>
                    <LabelInput>Comments:</LabelInput>
                  </WrapperInput>
                  <FormInputComment
                    type="text"
                    name="comments"
                    readOnly
                    value={param.comments}
                  />
                </FormCard>
              </WrapperForms>
            </Column2>
          </DashboardsRow>
        </DashboardsWrapper>
      );
    });
  };

  return (
    <>
      {paramPlates && (
        <InfoSection>
          <ItemInfo>
            <b>{paramPlates.length}</b> images processed
          </ItemInfo>
        </InfoSection>
      )}
      {iteratorOcrResult()}
    </>
  );
};

export default ImageGalleryComponent;
