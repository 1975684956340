import Container from "@mui/material/Container";
import { useSelector } from "react-redux";

import ViewSection from "./ViewSection";
import ImageGallery from "./../ImageGallery";
import MapCollection from "./../MapCollection";

import {
  ViewModeContainer,
  FieldDataContainer,
} from "./FieldDataCollectionElements";

const FieldDataComponent = ({ date }) => {
  const section = useSelector(
    (state) => state.collectionReducer.sectionCollection
  );

  return (
    <Container style={{
      paddingTop: 80,
    }} maxWidth="xl">
      <FieldDataContainer>
        <ViewModeContainer>
          <ViewSection />
        </ViewModeContainer>
        {section === "gallery" && <ImageGallery date={date} />}
        {section === "map" && <MapCollection date={date} />}
      </FieldDataContainer>
    </Container>
  );
};

export default FieldDataComponent;
