import axios from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  setLocationsIdPolygon,
  setGeometryPolygon,
  setFeaturesDetailsDT,
  setDataObjectsPolygon,
} from "redux/actions/admin";

const HandlerDrawerControl = () => {
  const companyId = localStorage.getItem("adminCompanyId");
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();

  // Iterator objects that are inside the polygon
  const iteratorRegionLocationId = useCallback(
    (locationsIdPolygon) => {
      return Promise.all(
        locationsIdPolygon.map(async (object, index) => {
          let locationId = object;
          const res = await axios.get(
            `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/location/${locationId}`,
            {
              headers: {
                Authorization: token ? `Bearer ${token}` : "",
              },
            }
          );
          const response = await res.data;
          return response;
        })
      ).then((response) => {
        dispatch(setDataObjectsPolygon(response));
      });
    },
    [dispatch, token]
  );

  // function to send polygon geometry to backend
  const sendPolygonGeometry = useCallback(
    async (body) => {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_OCR_API}filterpolygon?adminCompanyId=${companyId}`,
        body
      );
      const response = await res.data;
      dispatch(setLocationsIdPolygon(response));
      iteratorRegionLocationId(response);
    },
    [dispatch, iteratorRegionLocationId, companyId]
  );

  const onCreate = useCallback(
    (e) => {
      if (e.features[0].geometry.type === "Polygon") {
        sendPolygonGeometry(e.features[0].geometry);
        dispatch(setGeometryPolygon(e.features[0].geometry));
      }
      if (e.features[0].geometry.type === "Point") {
        ((currFeatures) => {
          const newFeatures = { ...currFeatures };

          for (const f of e.features) {
            newFeatures[f.id] = f;
            dispatch(setFeaturesDetailsDT(f));
          }
          return newFeatures;
        })();
      }
    },
    [dispatch, sendPolygonGeometry]
  );

  const onSelect = useCallback(
    (e) => {
      for (const f of e.features) {
        dispatch(setFeaturesDetailsDT(f));
      }
    },
    [dispatch]
  );

  const onUpdate = useCallback(
    (e) => {
      if (e.features[0].geometry.type === "Polygon") {
        sendPolygonGeometry(e.features[0].geometry);
        dispatch(setGeometryPolygon(e.features[0].geometry));
      }
      ((currFeatures) => {
        const newFeatures = { ...currFeatures };
        for (const f of e.features) {
          newFeatures[f.id] = f;
        }
        return newFeatures;
      })();
    },
    [dispatch, sendPolygonGeometry]
  );

  const onDelete = useCallback(
    (e) => {
      dispatch(setDataObjectsPolygon([]));
      dispatch(
        setFeaturesDetailsDT({
          geometry: {
            type: null,
          },
        })
      );
    },
    [dispatch]
  );

  return {
    onCreate,
    onSelect,
    onUpdate,
    onDelete,
  };
};

export default HandlerDrawerControl;
