import AdminRegister from "components/AdminDecimetrix/adminRegister";
// import NavBar from "./components/Admin/UserRegister/NavBar";
import NavBar from "components/AdminDecimetrix/NavBar";
const userRegister = () => {
  return (
    <>
      <NavBar linkLogo={"admin"} />
      <AdminRegister />
    </>
  );
};

export default userRegister;
