import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../utils/const";

export const Title = styled("div")`
  font-size: 1.4rem;
  background-color: #f9f9f9;
  margin: 20px 0;
`;

export const ButtonStyle = styled("div")`
  background-color: #f9f9f9;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  button {
    background-color: #0E4D45;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: #31a636;
    }
  }
`;
