import {
  SHOW_DRAWER_USER_ROUTES,
  TYPE_JOB,
  SHOW_DRAWER_DIGITAL_TWIN_ADMIN,
  SET_COORDINATES_USER_JOB,
  SET_FEATURES_DETAILS,
  SET_FEATURES_DETAILS_DT,
  SET_LOCATIONSID_IN_POLYGON,
  SET_GEOMETRY_POLYGON,
  SET_FEATURES_POLYGON,
  SET_DATA_OBJECTS,
  SET_DATA_REGION,
  SET_DATA_OBJECTS_POLYGON,
  SHOW_MARKER_BY_TYPE_OBJECT_ID,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID,
  SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD,
  SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON,
  SHOW_REGION_DETAILS_DRAWER,
  SET_FILTER_USERS,
  SET_SHOW_FILTER_USERS,
  SET_SHOW_FILTER_ALL_USERS,
  FILTER_BY_EVENTS,
} from "./../actions/types";

const initialValues = {
  SetShowAllUsers: false,
  SetShowUsers: false,
  SetUsers: {},
  setGeometryPolygon: {},
  setFeaturesPolygon: [],
  setLocationsIdPolygon: [],
  showDrawerUserRoutes: true,
  typeJob: null,
  showDrawerDigitalTwinAdmin: false,
  setCoordinatesUserRoute: {
    lat: null,
    lng: null,
  },
  setFeaturesDetails: {
    geometry: {
      type: null,
    },
  },
  setFeaturesDetailsDT: {
    geometry: {
      type: null,
    },
  },
  dataObjects: [],
  dataObjectsRegion: {},
  dataObjectsPolygon: [],
  markerByTypeObject: {},
  markerByTypeElement: {},
  markerByTypeObjectLoad: {},
  markerByTypeElementLoad: {},
  markerByTypeObjectPolygon: {},
  markerByTypeElementPolygon: {},
  showRegionDetailsDrawer: false,
  showEvents: false,
};

const adminReducer = (state = initialValues, action) => {
  switch (action.type) {
    case SET_GEOMETRY_POLYGON:
      return {
        ...state,
        setGeometryPolygon: action.payload,
      };
    case SET_FEATURES_POLYGON:
      return {
        ...state,
        setFeaturesPolygon: action.payload,
      };
    case SET_LOCATIONSID_IN_POLYGON:
      return {
        ...state,
        setLocationsIdPolygon: action.payload,
      };
    case SHOW_DRAWER_USER_ROUTES:
      return {
        ...state,
        showDrawerUserRoutes: action.payload,
      };
    case TYPE_JOB:
      return {
        ...state,
        typeJob: action.payload,
      };
    case SHOW_DRAWER_DIGITAL_TWIN_ADMIN:
      return {
        ...state,
        showDrawerDigitalTwinAdmin: action.payload,
      };
    case SET_COORDINATES_USER_JOB:
      return {
        ...state,
        setCoordinatesUserRoute: action.payload,
      };
    case SET_FEATURES_DETAILS:
      return {
        ...state,
        setFeaturesDetails: action.payload,
      };
    case SET_FEATURES_DETAILS_DT:
      return {
        ...state,
        setFeaturesDetailsDT: action.payload,
      };
    case SET_DATA_OBJECTS:
      return {
        ...state,
        dataObjects: action.payload,
      };
    case SET_DATA_OBJECTS_POLYGON:
      return {
        ...state,
        dataObjectsPolygon: action.payload,
      };
    case SET_DATA_REGION:
      return {
        ...state,
        dataObjectsRegion: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_OBJECT_ID:
      return {
        ...state,
        markerByTypeObject: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_ELEMENT_ID:
      return {
        ...state,
        markerByTypeElement: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD:
      return {
        ...state,
        markerByTypeObjectLoad: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD:
      return {
        ...state,
        markerByTypeElementLoad: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON:
      return {
        ...state,
        markerByTypeObjectPolygon: action.payload,
      };
    case SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON:
      return {
        ...state,
        markerByTypeElementPolygon: action.payload,
      };
    case SHOW_REGION_DETAILS_DRAWER:
      return {
        ...state,
        showRegionDetailsDrawer: action.payload,
      };
    case SET_FILTER_USERS:
      return {
        ...state,
        SetUsers: action.payload,
      };
    case SET_SHOW_FILTER_USERS:
      return {
        ...state,
        SetShowUsers: action.payload,
      };
    case SET_SHOW_FILTER_ALL_USERS:
      return {
        ...state,
        SetShowAllUsers: action.payload,
      };
    case FILTER_BY_EVENTS:
      return {
        ...state,
        showEvents: action.payload,
      };

    default:
      return state;
  }
};

export default adminReducer;
