import useSupercluster from "use-supercluster";
import { Marker } from "react-map-gl";
import { MarkerContainer } from "./MapStyle";
import { useSelector, useDispatch } from "react-redux";
import { NamePlateMarker } from "./userRoutes";

import ObjectDetails from "components/ImageGallery/ObjectDetails";
import {
  setOperationDetails,
  setShowOperationDetails,
} from "redux/actions";
import { useCallback, useMemo } from "react";

const ObjectsClusters = ({
  objects,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  const dispatch = useDispatch();

  const showMarkersByTypeObject = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );
  const showMarkerByElementWithMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );

  // data to geoJSON
  const objectsFilter = objects.filter((object) => {
    let nameTypeElement = null;
    if (object.typeObjectId === 1 && object.typeObjectGhg) {
      nameTypeElement = object.typeObjectGhg?.typeElementGhg.name;
    }
    if (object.typeObjectId === 2 && object.typeObjectAgile) {
      nameTypeElement = object.typeObjectAgile?.typeElementAgile.name;
    }
    if (object.typeObjectId === 3 && object.typeObjectWell) {
      nameTypeElement = object.typeObjectWell?.typeElementWell.name;
    }
    if (object.typeObjectId === 4 && object.typeObjectFacility) {
      nameTypeElement = object.typeObjectFacility?.typeElementFacility.name;
    }

    const show =
      (showMarkerByElementWithMedia && object.haveMedia) ||
      !showMarkerByElementWithMedia;

    if (
      showMarkersByTypeObject[object.typeObject.name] &&
      showMarkersByTypeElement[nameTypeElement] &&
      show
    ) {
      return true;
    } else {
      return false;
    }
  });

  const points = objectsFilter.map((object) => ({
    type: "Feature",
    properties: {
      cluster: false,
      object: object,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(object.location.longitude),
        parseFloat(object.location.latitude),
      ],
    },
  }));

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const HandleClickMarker = useCallback(
    async (objectId) => {
      const object = {
        content: <ObjectDetails objectId={objectId} />,
        title: "Objects Details",
      };
      dispatch(setOperationDetails(object));
      dispatch(setShowOperationDetails(true));
    },
    [dispatch]
  );
  // Show objects in the map
  const iteratorLocation = useCallback(
    (object, index) => {
      let icon = null;
      if (object.typeObjectId === 1 && object.typeObjectGhg) {
        if (object.haveMedia === true) {
          const { url_icon_color } = object.typeObjectGhg?.typeElementGhg;
          icon = url_icon_color;
        } else {
          const { url_icon_gray } = object.typeObjectGhg?.typeElementGhg;
          icon = url_icon_gray;
        }
      }
      if (object.typeObjectId === 2 && object.typeObjectAgile) {
        if (object.haveMedia === true) {
          const { urlIconColor } = object.typeObjectAgile?.typeElementAgile;
          icon = urlIconColor;
        } else {
          const { urlIconGray } = object.typeObjectAgile?.typeElementAgile;
          icon = urlIconGray;
        }
      }
      if (object.typeObjectId === 3 && object.typeObjectWell) {
        if (object.haveMedia === true) {
          const { url_icon_color } = object.typeObjectWell?.typeElementWell;
          icon = url_icon_color;
        } else {
          const { url_icon_gray } = object.typeObjectWell?.typeElementWell;
          icon = url_icon_gray;
        }
      }
      if (object.typeObjectId === 4 && object.typeObjectFacility) {
        if (object.haveMedia === true) {
          const { urlIconColor } =
            object.typeObjectFacility?.typeElementFacility;
          icon = urlIconColor;
        } else {
          const { urlIconGray } =
            object.typeObjectFacility?.typeElementFacility;
          icon = urlIconGray;
        }
      }
      return (
        <div key={index}>
          <Marker
            latitude={Number(object.location.latitude)}
            longitude={Number(object.location.longitude)}
          >
            <NamePlateMarker
              style={{
                cursor: "pointer",
              }}
              id={object.id}
              onClick={() => HandleClickMarker(object.id)}
            >
              <img id={object.id} src={icon} alt="object marker"></img>
            </NamePlateMarker>
          </Marker>
        </div>
      );
    },
    [HandleClickMarker]
  );

  const MarkersCluster = useMemo(
    () =>
      clusters.map((cluster) => {
        const [longitude, latitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              latitude={latitude}
              longitude={longitude}
            >
              <MarkerContainer>
                <div
                  className="cluster-marker"
                  style={{
                    width: `${10 + (pointCount / points.length) * 35}px`,
                    height: `${10 + (pointCount / points.length) * 35}px`,
                  }}
                  onClick={() => {
                    onSelectPoint(longitude, latitude);
                    setViewState({
                      ...viewState,
                      latitude,
                      longitude,
                    });
                  }}
                >
                  {pointCount}
                </div>
              </MarkerContainer>
            </Marker>
          );
        }
        return iteratorLocation(cluster.properties.object);
      }),
    [clusters, iteratorLocation, onSelectPoint, points, setViewState, viewState]
  );
  return <>{MarkersCluster}</>;
};

export default ObjectsClusters;
