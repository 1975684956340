import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { PRIMARY_COLOR, SECOND_COLOR } from "../../utils/const";

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }
`;

export const ImageDetailsContainer = styled.div`
  height: 80hv;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 50px;
  margin-top: 50px;
  align-items: center;
  background-color: #f9f9f9;
`;

export const GalleryContainer = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 525px;
    background-color: #000000;
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 8px 15px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const GalleryContainerNoThumbnails = styled.div`
  .image-gallery-slide {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 400px;
    background-color: #000000;
    &.center {
      position: relative;
    }

    .image-gallery-image {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .image-gallery-content {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }

    .image-gallery-description {
      background: $ig-background-black;
      bottom: 70px;
      color: $ig-white;
      left: 0;
      line-height: 1;
      padding: 10px 20px;
      position: absolute;
      white-space: normal;

      @media (max-width: $ig-small-screen) {
        bottom: 45px;
        font-size: 0.8em;
        padding: 0px 0px;
      }
    }

    @media all and (display-mode: fullscreen) {
      height: 100%;
    }
  }
`;

export const Wrapperbtn = styled("div")`
  position: relative;
  margin-left: 5rem;
  width: 75%;
`;

export const Label = styled("label")`
  background: ${PRIMARY_COLOR};
  width: 100%;
  border-radius: 10px;
  color: #f9f9f9;
  padding: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: ${SECOND_COLOR};
  }
`;

export const LabelRotatePhoto = styled("label")`
  background: ${PRIMARY_COLOR};
  width: 100%;
  margin-bottom: 0px;
  border-radius: 5px;
  color: #f9f9f9;
  padding: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: ${SECOND_COLOR};
  }
`;

export const Btn = styled.button`
  position: absolute;
  top: 0;
  right: 5px;
  width: 40%;
  height: 70%;
  background: ${PRIMARY_COLOR};
  color: #f9f9f9;
  border: 2px solid ${PRIMARY_COLOR};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 25px;

  &:hover {
    background: #f9f9f9;
    color: ${PRIMARY_COLOR};
  }
`;

export const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: bold;
  color: ${PRIMARY_COLOR};
  text-align: center;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const ImgCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  height: 100%;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 50px;
`;

export const Imgcard = styled.section`
  display: flex;
  margin-top: 50px;
  justify-content: left;
  align-items: left;
  max-width: 60%;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  width: 100%;

  @media (max-width: 1900px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const WrapperImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: inline-block;
    margin: 0 25%;
  }
  @media (max-width: 600px) {
    display: inline-block;
    margin: 0 17%;
  }
  @media (max-width: 450px) {
    display: inline-block;
    margin: 0 10%;
  }
  @media (max-width: 390px) {
    display: inline-block;
    margin: 0 6%;
  }
`;

export const CardImg = styled.img`
  width: 550px;
  height: auto;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);

  @media (max-width: 1250px) {
    width: 400px;
  }
  @media (max-width: 980px) {
    width: 300px;
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }
`;

export const CardTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const InfoObject = styled.div`
  width: 100%;
  padding: 0px;
  justify-content: left;
  align-items: left;
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  /* height: auto; */
  text-align: left;

  .main-currency-table {
    width: 100%;
    min-width: 235px;
    /* max-width: 500px; */
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .main-currency-table .currency-table--title {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.3rem;
    text-align: center;
  }
  .currency-table--container {
    width: 100%;
    min-width: 230px;
    max-width: 380px;
    /* height: 200px; */
    margin: 0 auto;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
  }

  .currency-table--container table td {
    width: 50%;
    height: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
  }
  .currency-table--container .table__top-left {
    border-radius: 5px 0 0 0;
  }
  .currency-table--container .table__top-right {
    border-radius: 0 5px 0 0;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 0 0 5px 0;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 0 0 0 5px;
  }
  .currency-table--container .table__right {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
  }
  .currency-table--container table tr:nth-child(2n + 1) {
    background-color: #f7f4f3;
  }
  .currency-table--container table tr:nth-child(2n) {
    background-color: #fff;
  }
`;

export const CardSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding: 0 20px;
  align-self: left;
  justify-self: left;
`;

export const CardSubtitleObject = styled.p`
  font-size: 1.5rem;
  margin-bottom: 10px;
  padding: 0 20px;
  align-self: center;
  justify-self: center;
`;

export const WrapperButtons = styled.div`
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 100%;
  margin: 50px 0;
  /* @media (max-width: 480px) {
    padding: 100px 0;
  } */
`;

export const ButtonCard = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: #31a636;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    opacity: 0.8;
    z-index: 10;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    font-size: 1.4rem;
    width: 140px;
  }
`;

export const SelectOperation = styled.select`
  width: 160px;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  border: none;
  background-color: #31a636;
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 20px;
`;
export const ButtonDelete = styled.button`
  width: 150px;
  height: 50px;
  border-radius: 5px;
  border: none;
  background-color: rgba(212, 56, 58, 1);
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    z-index: 10;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    font-size: 1.4rem;
    width: 140px;
  }
`;

export const WrapperForms = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    display: inline-block;
    margin: 0 25%;
  }
  @media (max-width: 600px) {
    display: inline-block;
    margin: 0 17%;
  }
  @media (max-width: 450px) {
    display: inline-block;
    margin: 0 10%;
  }
  @media (max-width: 390px) {
    display: inline-block;
    margin: 0 6%;
  }
`;

export const FormCard = styled.form`
  padding: 0 20px;
  box-sizing: border-box;
  margin-right: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 300px;
  }
`;

export const FormTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(26, 26, 26);
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const WrapperInput = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LabelInput = styled.label`
  font-size: 1.4rem;
  color: #000;
  margin: 10px 0;
`;

export const FormInput = styled.input`
  margin-left: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: rgb(61, 61, 61);
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
`;

export const FormInputComment = styled.textarea`
  margin-left: 10px;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
  padding: 10px;

  font-size: 1.4rem;
`;

export const imageLink = styled.a`
  color: #222729;
  font-size: 2.4rem;
`;

export const Message = styled.p`
  padding: 20px;
  box-sizing: border-box;
  margin-right: 20px;
  border-radius: 40px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);

  @media (max-width: 768px) {
    width: 300px;
  }
`;

export const NavbarLogoR = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const Img = styled.img`
  width: 160px;
  height: auto;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: #222729;
  padding: 10px 22px;
  color: #fff;
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #222729;
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  span {
    color: ${PRIMARY_COLOR};
  }
  h1 {
    margin-top: 15px;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 45px;
    height: 38px;
    margin: 5px 0;
  }
`;
