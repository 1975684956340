import styled from "styled-components";


export const CustomSelect = styled("div")`
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0E4D45;
    color: white;
}
`;