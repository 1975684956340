import NavBar from "../components/NavBar";
// import SignUpComponent from "../components/SignUp";
import SignUpComponent from '../components/SignUp/FormSignUp'

const SignUp = () => {

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  return (
    <div>
      <NavBar configNavbar={configNavbar}/>
      <SignUpComponent/>
    </div>
  )
}

export default SignUp
