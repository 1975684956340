import {
  ContainerOperations,
  HeadModal,
  CustomizedChevronLeftIcon,
  ContentButton,
  ContentButtonGallery,
} from "./OperationDetailsElements";
import {
  WraperTitleCardMarker,
  InfoTitle,
  LabelRotatePhoto,
} from "./ImageDetailsElements";
import OpenDialog from "../Dialogs/OpenDialog";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import MapView from "components/MapView";
import PhotoGallery from "./PhotoGallery";
import { Label, Wrapperbtn } from "./../DigitalTwin/stylesPowerSystems";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ListOperationTable from "./ListOperationTable";
import Grid from "@material-ui/core/Grid";
import { PRIMARY_COLOR } from "./../../utils/const";
import { AiFillCloseCircle } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import DialogContent from "@mui/material/DialogContent";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { setDataObjectDTImages } from "redux/actions";
import { useSWRConfig } from "swr";
import useSwr from "swr";

import ObjectDetails from "./ObjectDetails";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import { SummaryFeature } from "../Admin/UserRoutes/DrawerElements";

import GenericDialog from "components/Dialogs/GenericDialog";
import ContentUploadFieldVIdeo from "./ContentUploadFieldVideo";

const OperationDetails = ({ operationId, fromObject }) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );
  const [equipmentPhoto, setEquipmentPhoto] = useState(null);
  const [namePlatePhoto, setNamePlatePhoto] = useState(null);
  const [equipmentPhotos, setEquipmentPhotos] = useState();
  const [namePlatePhotos, setNamePlatePhotos] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typePicture, setTypePicture] = useState("1");

  const [video, setVideo] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videosUpload, setVideosUpload] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModalUploadVideo, setOpenModalUploadVideo] = useState(false);
  const [openModalUploadVideoProcessed, setOpenModalUploadVideoProcessed] =
    useState(false);
  const [nameFlirVideo, setNameFlirVideo] = useState();

  const [nameFlirVideoUpdateProcessed, setNameFlirVideoUpdateProcessed] =
    useState();
  const [namePdf, setNamePdf] = useState();

  const [PPM, setPPM] = useState(0);
  const [grH, setGrH] = useState(0);
  const [ltMin, setLtMin] = useState(0);

  const [flirVideoId, setFlirVideoId] = useState();
  const [objectGHG, setObjectGHG] = useState(false);
  const [seeAnemometerTable, setSeeAnemometerTable] = useState(false);
  const [otherMarkers, setOtherMarkers] = useState([]);
  const [ppmValue, setPpmValue] = useState(null);
  const [grhValue, setGrhValue] = useState(null);
  const [ltMinValue, setLtMinValue] = useState(null);

  const { mutate } = useSWRConfig();

  const fetcher = (...args) =>
    fetch(...args, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

  const urlData = `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/${operationId}`;
  const { data: operation, error: errorOperation } = useSwr(urlData, fetcher);

  // Get object the current event
  useEffect(() => {
    const getObject = async () => {
      const objectGhgId = operation?.objectOperations?.objectGhgId;
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/object-ghg/${objectGhgId}`
      );
      setObjectGHG(data);

      const newMarker = {
        latitude: data?.object?.location?.latitude,
        longitude: data?.object?.location?.longitude,
        urlIcon: data?.typeElementGhg?.url_icon_color,
      };
      setOtherMarkers((current) => {
        return current.concat(newMarker);
      });
    };
    operation && getObject();
  }, [operation]);

  useEffect(() => {
    const equipementPhotosArray = [];
    const nameplatePhotosArray = [];
    operation &&
      operation.photos.map((photo) => {
        if (photo.operationPhotoTypeId === 2) {
          setEquipmentPhoto(photo);
          equipementPhotosArray.push(photo);
        }
        if (photo.operationPhotoTypeId === 1) {
          setNamePlatePhoto(photo);
          nameplatePhotosArray.push(photo);
        }
        return false;
      });
    setEquipmentPhotos(equipementPhotosArray);

    setNamePlatePhotos(nameplatePhotosArray);
  }, [operation]);

  useEffect(() => {
    const getVideos = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video/inspection-flir/${operation?.inspectionFlir?.id}`
      );
      setPpmValue(
        data[0]?.flirVideoProcessed?.ppm ? data[0]?.flirVideoProcessed.ppm : "-"
      );
      setGrhValue(
        data[0]?.flirVideoProcessed?.grH ? data[0]?.flirVideoProcessed.grH : "-"
      );
      setLtMinValue(
        data[0]?.flirVideoProcessed?.ltMin
          ? data[0]?.flirVideoProcessed.ltMin
          : "-"
      );
      setVideos(data);
      setNameFlirVideo(data[0]?.name);
      setFlirVideoId(data[0]?.id);
    };
    operation?.inspectionFlir && getVideos();
  }, [operation?.inspectionFlir]);

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 15);
  };

  //open modal upload video inspection
  const handleOpenUploadVideo = () => {
    setOpenModalUploadVideo(!openModalUploadVideo);
  };

  //open modal upload video processed
  const handleOpenUploadVideoProcessed = () =>
    setOpenModalUploadVideoProcessed(!openModalUploadVideoProcessed);

  const uploadVideoProcessed = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setVideosUpload([reader.result]);
    };
    setVideo(file);
    setNameFlirVideoUpdateProcessed(e.target.files[0].name);
  };

  const uploadPdfProcessed = async (e) => {
    const file = e.target.files[0];
    setPdf(file);
    setNamePdf(file.name);
  };

  const [openModalRotatePhoto, setOpenModalRotatePhoto] = useState(false);
  const [openModalRotatePhotoSensor, setOpenModalRotatePhotoSensor] =
    useState(false);
  const [rotation, setRotation] = useState(0);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    textAlign: "center",
    borderRadius: "5px",
  };

  const styleRotateModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const handleCloseModalRotatePhoto = () => {
    setOpenModalRotatePhoto(false);
    setRotation(0);
  };

  const handleCloseModalRotatePhotoSensor = () => {
    setOpenModalRotatePhotoSensor(false);
    setRotation(0);
  };

  const saveVideoProcessed = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", video);
      const urlVideo = await fetch(
        `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
        {
          method: "POST",
          body: formData,
        }
      );

      const videoSave = await urlVideo.json();

      const urlReport = await savePdf();

      await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video-processed`,
        {
          urlVideo: videoSave.urlFile,
          ppm: PPM,
          grH: grH,
          ltMin: ltMin,
          flirVideoId: flirVideoId,
          urlReport: urlReport,
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      setLoading(false);
      setOpenModalUploadVideoProcessed(false);
    } catch (error) {
      setLoading(false);
      setOpenModalUploadVideoProcessed(false);
      setErrorMessage(true);
    }
  };

  const savePdf = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", pdf);
    const urlPdf = await fetch(
      `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
      {
        method: "POST",
        body: formData,
      }
    );
    const videoSave = await urlPdf.json();
    return videoSave.urlFile;
  };

  const returnToObject = () => {
    const object = {
      content: <ObjectDetails objectId={objectGHG.object.id} />,
      title: "Object Details",
    };
    dispatch(setOperationDetails(object));
    dispatch(setShowOperationDetails(true));
  };

  const handleClose = () => {
    setOpenModalUploadVideo(false);
  };

  const handleClosePhotoModal = () => setOpenModal(false);

  const handleCloseUploadModalProcessed = () => {
    setOpenModalUploadVideoProcessed(false);
  };

  const rotateImageLeft = () => {
    setRotation(rotation - 90);
  };

  const rotateImageRight = () => {
    setRotation(rotation + 90);
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setDataObjectDTImages([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setOpenModal(true);
  };

  // define functions to handle save button
  const savePhotos = async () => {
    setLoading(true);
    Promise.all(
      photosFile.map(async (file) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("file", file);
        const urlPhoto = await fetch(
          `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        const photo = await urlPhoto.json();
        await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/operation-photo`,
          {
            operationId: operationId,
            operationPhotoTypeId: typePicture,
            urlPhoto: photo.urlFile,
          },
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      })
    ).then(() => {
      setLoading(false);
      handleClosePhotoModal();
      setPhotosFile([]);
      mutate(urlData);
    });
  };

  const openFullscreen = (idVideoElement) => {
    var elem = document.getElementById(idVideoElement);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };

  const setObjectHandler = (e) => {
    setTypePicture(e.target.value);
  };

  return (
    <>
      {operation && !errorOperation && (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            position: "relative",
          }}
        >
          {fromObject && (
            <IconButton
              onClick={returnToObject}
              style={{
                fontSize: 30,
                cursor: "pointer",
                position: "sticky",
                left: "0px",
                top: "0px",
              }}
            >
              <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
            </IconButton>
          )}
          <OpenDialog
            openDialog={errorMessage}
            setOpenDialog={setErrorMessage}
            content={{
              title: "Conexion lost",
              description: "Please check your internet conexion and try again.",
              disagree: "Continue",
            }}
          />
          <ContainerOperations>
            {/* --EVENT TYPE-- */}
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="info-operation">
                <WraperTitleCardMarker>
                  <h1>
                    EVENT TYPE
                    <br />
                    <span>
                      {" "}
                      {operation.typeOperation?.name === "INSPECTION FLIR" ? (
                        <span>FLIR INSPECTION</span>
                      ) : (
                        operation.typeOperation?.name
                      )}{" "}
                    </span>
                  </h1>
                  <img alt="ico" src={operation.typeOperation?.urlIcon}></img>
                </WraperTitleCardMarker>
                <InfoTitle>
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <section class="main-tables-container">
                      <div class="main-currency-table">
                        <div class="currency-table--container">
                          <table>
                            <tr>
                              <td class="table__top-left">
                                Leak per regulation
                              </td>
                              <td
                                style={
                                  ppmValue >= 500 ||
                                  grhValue >= 14487.85 ||
                                  ltMinValue >= 336.77
                                    ? { backgroundColor: "#ed3939" }
                                    : {}
                                }
                                class="table__top-right table__right"
                              >
                                {ppmValue >= 500 ||
                                grhValue >= 14487.85 ||
                                ltMinValue >= 336.77
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td class="table__top-left">Leak captured</td>
                              <td
                                style={
                                  operation.inspectionFlir?.leak
                                    ? { backgroundColor: "#ed3939" }
                                    : {}
                                }
                                class="table__top-right table__right"
                              >
                                {operation.inspectionFlir?.leak ? "Yes" : "No"}
                              </td>
                            </tr>
                            <tr>
                              <td>ppm-meter</td>
                              <td class="table__right">{ppmValue}</td>
                            </tr>
                            <tr>
                              <td>gram / hour</td>
                              <td className="table__right">{grhValue}</td>
                            </tr>
                            <tr>
                              <td>litre / minute</td>
                              <td className="table__right">{ltMinValue}</td>
                            </tr>
                            <tr>
                              <td>Event ID</td>
                              <td class="table__right">{operation.id}</td>
                            </tr>
                            <tr>
                              <td>Date</td>
                              <td class="table__right">
                                {isoDateToString(operation.date)}
                              </td>
                            </tr>
                            <tr>
                              <td>Longitude</td>
                              <td class="table__right">
                                {" "}
                                {parseFloat(
                                  operation.locationOperation?.longitude || 0
                                ).toFixed(5)}
                              </td>
                            </tr>
                            <tr>
                              <td>Latitude</td>
                              <td class="table__right">
                                {parseFloat(
                                  operation.locationOperation?.latitude || 0
                                ).toFixed(5)}
                              </td>
                            </tr>
                            <tr>
                              <td class="table__bottom-left">Videos</td>
                              <td class="table__bottom-right table__right">
                                {videos.length}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </section>
                  </Grid>
                </InfoTitle>
              </div>
            </Grid>

            {/* --OPERATION PHOTOS-- */}

            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div className="photo-container-image">
                <h1 className="operation-title">
                  <span>GALLERY</span>
                </h1>
                <div
                  className="gallery"
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "0px 24px 0px 20px",
                  }}
                >
                  {operation.photos.length > 0 && (
                    <>
                      <p style={{ fontSize: "1.6rem" }}>
                        <b>Total Pictures: </b> {operation.photos.length}
                      </p>
                      <br />
                      <PhotoGallery photos={operation.photos} />
                    </>
                  )}
                  <ContentButtonGallery>
                    {/* <Wrapperbtn> */}
                    <Label htmlFor="files">
                      <AddAPhotoIcon />
                      <p>Add Picture</p>
                    </Label>
                    <input
                      id="files"
                      style={{ visibility: "hidden" }}
                      accept="image/*"
                      type="file"
                      name="file"
                      onChange={uploadFile}
                    />
                    {/* </Wrapperbtn> */}
                  </ContentButtonGallery>
                  <Modal
                    open={openModal}
                    onClose={handleClosePhotoModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                      }}
                    >
                      <HeadModal>
                        <div className="title">
                          <img
                            src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                            alt=""
                            className="neurona"
                          />
                          <span className="titleHeadModal">Add Picture</span>
                        </div>
                        <Button onClick={handleClosePhotoModal}>
                          <AiFillCloseCircle
                            style={{
                              color: "#FFF",
                              fontSize: 21,
                              marginBottom: -4,
                            }}
                          />
                        </Button>
                      </HeadModal>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                          marginTop: "50px",
                        }}
                      >
                        Please select the type of picture to upload:
                        <br />
                        <div>
                          <select
                            defaultValue={typePicture}
                            onChange={setObjectHandler}
                            style={{
                              backgroundColor: PRIMARY_COLOR,
                              borderColor: PRIMARY_COLOR,
                              color: "#FFF",
                              padding: "5px 15px",
                              margin: "11px 11px 11px 15px",
                            }}
                          >
                            <option selected value="1">
                              Screen Sensor
                            </option>
                            <option value="2">Equipment</option>
                            <option value="3">General</option>
                          </select>
                        </div>
                      </Typography>
                      <LoadingButton
                        sx={{
                          margin: "11px",
                        }}
                        loadingPosition="start"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        style={{
                          backgroundColor: PRIMARY_COLOR,
                          borderColor: PRIMARY_COLOR,
                          color: "#FFF",
                        }}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setLoading(true);
                          savePhotos();
                        }}
                        style={{
                          backgroundColor: PRIMARY_COLOR,
                          borderColor: PRIMARY_COLOR,
                          color: "#FFF",
                        }}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Modal>
                </div>
              </div>
            </Grid>

            {/* --EQUIPMENT PHOTO-- */}
            {equipmentPhoto && (
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <div className="photo-container">
                  <h1 className="operation-title">
                    <span>EQUIPMENT</span>
                  </h1>

                  {equipmentPhotos.length === 1 ? (
                    <img
                      width="85%"
                      height="400px"
                      src={equipmentPhoto.urlPhoto}
                      alt="equipment"
                    />
                  ) : (
                    <div
                      style={{
                        width: "85%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "0px 0px 0px 0px",
                      }}
                    >
                      <PhotoGallery photos={equipmentPhotos} state={false} />{" "}
                    </div>
                  )}

                  {/* <img
                    width="85%"
                    height="480px"
                    src={equipmentPhoto.urlPhoto}
                    alt="equipment"
                  /> */}
                  <ContentButton
                    onClick={() => {
                      setOpenModalRotatePhoto(true);
                    }}
                  >
                    {/* <Wrapperbtn> */}
                    <LabelRotatePhoto>
                      <p>Rotate Picture</p>
                    </LabelRotatePhoto>
                    {/* </Wrapperbtn> */}
                  </ContentButton>
                </div>
              </Grid>
            )}

            <Modal
              open={openModalRotatePhoto}
              onClose={handleCloseModalRotatePhoto}
            >
              <Box
                sx={styleRotateModal}
                style={{
                  position: "relative",
                  borderRadius: "10px",
                }}
              >
                <HeadModal>
                  <div className="title">
                    <img
                      src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                      alt="neurona"
                      className="neurona"
                    />
                    <span className="titleHeadModal">Rotate Picture</span>
                  </div>
                  <Button onClick={handleCloseModalRotatePhoto}>
                    <AiFillCloseCircle
                      style={{ color: "#FFF", fontSize: 21 }}
                    />
                  </Button>
                </HeadModal>
                <DialogContent>
                  {equipmentPhoto && (
                    <img
                      style={{
                        transform: `rotate(${rotation}deg)`,
                        marginTop: "20px",
                      }}
                      width="60%"
                      src={equipmentPhoto.urlPhoto}
                      alt="equipment"
                    />
                  )}

                  <br></br>
                </DialogContent>
                <RotateLeftIcon
                  onClick={rotateImageLeft}
                  sx={{
                    cursor: "pointer",
                  }}
                ></RotateLeftIcon>
                <RotateRightIcon
                  onClick={rotateImageRight}
                  sx={{
                    cursor: "pointer",
                  }}
                ></RotateRightIcon>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                ></Typography>
              </Box>
            </Modal>

            {/* --SCREEN SENSOR PHOTO-- */}
            {namePlatePhoto && (
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <div className="photo-container">
                  <h1 className="operation-title">
                    <span>SCREEN SENSOR</span>
                  </h1>

                  {namePlatePhotos.length === 1 ? (
                    <img
                      width="85%"
                      height="400px"
                      src={namePlatePhoto.urlPhoto}
                      alt="equipment"
                    />
                  ) : (
                    <div
                      style={{
                        width: "85%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "0px 0px 0px 0px",
                      }}
                    >
                      <PhotoGallery photos={namePlatePhotos} state={false} />{" "}
                    </div>
                  )}

                  <ContentButton
                    onClick={() => {
                      setOpenModalRotatePhotoSensor(true);
                    }}
                  >
                    {/* <Wrapperbtn> */}
                    <LabelRotatePhoto>
                      <p>Rotate Picture</p>
                    </LabelRotatePhoto>
                    {/* </Wrapperbtn> */}
                  </ContentButton>
                </div>
              </Grid>
            )}

            <Modal
              open={openModalRotatePhotoSensor}
              onClose={handleCloseModalRotatePhotoSensor}
            >
              <Box
                sx={styleRotateModal}
                style={{
                  position: "relative",
                  borderRadius: "10px",
                }}
              >
                <HeadModal>
                  <div className="title">
                    <img
                      src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                      alt=""
                      className="neurona"
                    />
                    <span className="titleHeadModal">Rotate Picture</span>
                  </div>
                  <Button onClick={handleCloseModalRotatePhotoSensor}>
                    <AiFillCloseCircle
                      style={{ color: "#FFF", fontSize: 21 }}
                    />
                  </Button>
                </HeadModal>
                <DialogContent>
                  {namePlatePhoto && (
                    <img
                      style={{
                        transform: `rotate(${rotation}deg)`,
                        marginTop: "20px",
                      }}
                      width="50%"
                      src={namePlatePhoto.urlPhoto}
                      alt="nameplate"
                    />
                  )}
                </DialogContent>
                <br></br>
                <RotateLeftIcon
                  onClick={rotateImageLeft}
                  sx={{
                    cursor: "pointer",
                  }}
                ></RotateLeftIcon>
                <RotateRightIcon
                  onClick={rotateImageRight}
                  sx={{
                    cursor: "pointer",
                  }}
                ></RotateRightIcon>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                ></Typography>
              </Box>
            </Modal>

            {/* --MAP- */}

            {namePlatePhoto && equipmentPhoto ? (
              <Grid item xs={12} md={12} lg={4} xl={4}>
                <div className="content-map">
                  <h1 className="operation-title">
                    <span>EVENT LOCATION</span>
                  </h1>
                  <MapView
                    otherMarkers={otherMarkers}
                    eventId={
                      operation.Reparation !== null
                        ? operation.Reparation?.id
                        : operation.inspectionFlir !== null
                        ? operation.inspectionFlir?.id
                        : operation.inspectionSniffer !== null
                        ? operation.inspectionSniffer?.id
                        : operation.leak?.id
                    }
                    typeOperation={operation.typeOperation}
                    location={operation.locationOperation}
                    urlIcon={operation.typeOperation.urlIcon}
                  />
                </div>
              </Grid>
            ) : namePlatePhoto || equipmentPhoto ? (
              <Grid item xs={12} md={12} lg={8} xl={8}>
                <div className="content-map">
                  <h1 className="operation-title">
                    <span>LOCATION</span>
                  </h1>
                  <MapView
                    eventId={
                      operation.Reparation !== null
                        ? operation.Reparation?.id
                        : operation.inspectionFlir !== null
                        ? operation.inspectionFlir?.id
                        : operation.inspectionSniffer !== null
                        ? operation.inspectionSniffer?.id
                        : operation.leak?.id
                    }
                    typeOperation={operation.typeOperation}
                    location={operation.locationOperation}
                    urlIcon={operation.typeOperation.urlIcon}
                  />
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className="content-map">
                  <h1 className="operation-title">
                    <span>LOCATION</span>
                  </h1>
                  <MapView
                    eventId={
                      operation.Reparation !== null
                        ? operation.Reparation?.id
                        : operation.inspectionFlir !== null
                        ? operation.inspectionFlir?.id
                        : operation.inspectionSniffer !== null
                        ? operation.inspectionSniffer?.id
                        : operation.leak?.id
                    }
                    typeOperation={operation.typeOperation}
                    location={operation.locationOperation}
                    urlIcon={operation.typeOperation.urlIcon}
                  />
                </div>
              </Grid>
            )}

            {/* --EVENT HISTORY-- */}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: seeAnemometerTable ? "" : "none",
              }}
            >
              <div className="table-container">
                <>
                  <div className="operation-title">
                    <h1>
                      <br></br>
                      <span>ANEMOMETER DATA</span>
                    </h1>
                  </div>
                  <div className="operation-table">
                    <ListOperationTable
                      operation={operation}
                      setSeeAnemometerTable={setSeeAnemometerTable}
                    ></ListOperationTable>
                  </div>
                </>
              </div>
            </Grid>

            {/* --LEAK CUATIFICATION-- */}
            {videos && videos.length > 0 && (
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <div className="leak-container">
                  <div className="operation-title">
                    <h1>
                      LEAK <span>QUANTIFICATION</span>
                    </h1>
                  </div>
                  {/* <div className="container-videos"> */}
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={videos[0].urlVideo === null ? 12 : 6}
                      xl={videos[0].urlVideo === null ? 12 : 6}
                    >
                      <item>
                        <div className="operation-title">
                          <h5>
                            <span>FIELD VIDEO</span>
                          </h5>
                        </div>
                        <div className="container-video">
                          {videos[0].urlVideo !== null ? (
                            videos.map((video, index) => (
                              <>
                                <div className="video">
                                  <img
                                    src="https://images.pexels.com/photos/162568/oil-pump-jack-sunset-clouds-silhouette-162568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                    alt="video"
                                    height="100%"
                                    width="100%"
                                  />
                                  <button
                                    onClick={() =>
                                      openFullscreen("field-video")
                                    }
                                    class="button"
                                  >
                                    <span></span>
                                  </button>
                                  <video
                                    id="field-video"
                                    height="0"
                                    width="0"
                                    controls
                                    key={index}
                                    style={{
                                      margin: "0 1rem 1rem",
                                    }}
                                  >
                                    <source
                                      src={video.urlVideo}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                                <p className="video-text">{video.name}</p>
                              </>
                            ))
                          ) : (
                            <Wrapperbtn
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                flexDirection: "column",
                                marginBottom: "20px",
                              }}
                            >
                              <Label onClick={handleOpenUploadVideo}>
                                <FileUploadIcon />
                                <p
                                  style={{
                                    width: "360px",
                                    textAlign: "center",
                                  }}
                                >
                                  Upload Field Video
                                </p>
                              </Label>
                            </Wrapperbtn>
                          )}
                        </div>
                      </item>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={videos[0].urlVideo === null ? 0 : 6}
                      xl={videos[0].urlVideo === null ? 0 : 6}
                    >
                      <item>
                        {videos[0].urlVideo !== null && (
                          <>
                            <div className="operation-title">
                              <h5>
                                <span>FIELD VIDEO PROCESSED</span>
                              </h5>
                            </div>
                            <div className="container-video">
                              {videos[0].flirVideoProcessed !== null ? (
                                <>
                                  <div className="videoProcessed">
                                    <img
                                      src="https://images.pexels.com/photos/162568/oil-pump-jack-sunset-clouds-silhouette-162568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                      alt="video"
                                      height="100%"
                                      width="100%"
                                    />
                                    <button
                                      onClick={() =>
                                        openFullscreen("field-video-processed")
                                      }
                                      class="button"
                                    >
                                      <span></span>
                                    </button>
                                    <video
                                      id="field-video-processed"
                                      height="0"
                                      width="0"
                                      controls
                                      style={{
                                        margin: "0 1rem 1rem",
                                      }}
                                    >
                                      <source
                                        src={
                                          videos[0].flirVideoProcessed.urlVideo
                                        }
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>

                                  <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} lg={6} xl={6}>
                                      <a
                                        className="video-text-pdf"
                                        href={
                                          videos[0].flirVideoProcessed.urlReport
                                        }
                                      >
                                        Pdf Report
                                      </a>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} xl={6}>
                                      <p className="video-text-ppm">
                                        ppm: {videos[0].flirVideoProcessed.ppm}
                                      </p>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (
                                <Wrapperbtn
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    flexDirection: "column",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Label
                                    onClick={handleOpenUploadVideoProcessed}
                                  >
                                    <FileUploadIcon />
                                    <p
                                      style={{
                                        width: "400px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Upload Processed Video
                                    </p>
                                  </Label>
                                </Wrapperbtn>
                              )}
                            </div>
                          </>
                        )}
                      </item>
                    </Grid>
                  </Grid>
                  {/* </div> */}
                </div>
              </Grid>
            )}

            {/* modal Upload video Flir */}
            {operation && (
              <GenericDialog
                openDialog={openModalUploadVideo}
                setOpenDialog={handleOpenUploadVideo}
                content={{
                  title: "Sync Video",
                  description: null,
                  disagree: null,
                  agree: null,
                  content: (
                    <ContentUploadFieldVIdeo
                      namePlatePhoto={namePlatePhoto}
                      nameFlirVideo={nameFlirVideo}
                      videos={videos}
                      setNameFlirVideo={setNameFlirVideo}
                      flirVideoId={flirVideoId}
                      userId={operation.userId}
                      operationId={operationId}
                      setOpenDialog={handleOpenUploadVideo}
                    />
                  ),
                }}
                style={{
                  margin: 0,
                  padding: 0,
                  width: "70%",
                  height: "100%",
                  maxWidth: "none",
                }}
              />
            )}

            {/* modal Upload video Processed */}
            <Modal
              open={openModalUploadVideoProcessed}
              onClose={handleOpenUploadVideoProcessed}
            >
              <Box
                sx={style}
                style={{
                  position: "relative",
                  borderRadius: "10px",
                }}
              >
                <HeadModal>
                  <img
                    src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                    alt=""
                    className="neurona"
                  />
                  <span className="titleHeadModal">Video Details</span>
                  <Button onClick={handleCloseUploadModalProcessed}>
                    <AiFillCloseCircle
                      style={{ color: "#FFF", fontSize: 21 }}
                    />
                  </Button>
                </HeadModal>
                {videosUpload &&
                  videosUpload.map(
                    (video, index) =>
                      video.flirVideoProcessed !== null && (
                        <video
                          width="230"
                          height="240"
                          controls
                          key={index}
                          style={{
                            marginTop: index === 0 ? "50px" : "0",
                          }}
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                      )
                  )}

                <SummaryFeature
                  style={{
                    marginTop: videosUpload ? "0" : "50px",
                  }}
                >
                  <p class="geometry-type">
                    <b></b>
                  </p>

                  <div>
                    <input
                      id="uploadVideoProcessed"
                      type="file"
                      name="file"
                      style={{ visibility: "hidden" }}
                      onChange={uploadVideoProcessed}
                      accept="video/*"
                    ></input>
                    <input
                      id="uploadPdf"
                      type="file"
                      name="file"
                      style={{ display: "none" }}
                      onChange={uploadPdfProcessed}
                      accept=".pdf"
                    ></input>
                    <p class="geometry-type">
                      <b>Upload Video:</b>
                    </p>

                    <Grid container spacing={2}>
                      <Grid item xs={10} md={10} lg={10} xl={10}>
                        {videos.length > 0 ? (
                          <input
                            type="text"
                            value={nameFlirVideoUpdateProcessed}
                          />
                        ) : (
                          <input type="text" />
                        )}
                      </Grid>
                      <Grid item xs={2} md={2} lg={2} xl={2}>
                        <label htmlFor="uploadVideoProcessed">
                          <CloudUploadIcon
                            sx={{
                              cursor: "pointer",
                              mt: 1,
                            }}
                          />
                        </label>
                      </Grid>
                    </Grid>
                  </div>
                  <p class="geometry-type">
                    <b>Upload Report:</b>
                  </p>

                  <Grid container spacing={2}>
                    <Grid item xs={10} md={10} lg={10} xl={10}>
                      <input type="text" value={namePdf} />
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} xl={2}>
                      <label htmlFor="uploadPdf">
                        <PictureAsPdfIcon
                          sx={{
                            cursor: "pointer",
                            mt: 1,
                          }}
                        />
                      </label>
                    </Grid>
                  </Grid>

                  <div>
                    <p class="geometry-type">
                      <b>PPM-m:</b>
                    </p>
                    <input
                      type="text"
                      // value={nameFlirVideo}
                      // value={regionName}
                      onChange={(e) => setPPM(e.target.value)}
                    />
                  </div>
                  <div>
                    <p class="geometry-type">
                      <b>gr/h:</b>
                    </p>
                    <input
                      type="text"
                      // value={nameFlirVideo}
                      // value={regionName}
                      onChange={(e) => setGrH(e.target.value)}
                    />
                  </div>
                  <div>
                    <p class="geometry-type">
                      <b>lt/min:</b>
                    </p>
                    <input
                      type="text"
                      // value={nameFlirVideo}
                      // value={regionName}
                      onChange={(e) => setLtMin(e.target.value)}
                    />
                  </div>
                </SummaryFeature>

                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                ></Typography>

                {nameFlirVideoUpdateProcessed &&
                  (PPM.length > 0 || grH.length > 0 || ltMin.length > 0) &&
                  namePdf !== undefined && (
                    <LoadingButton
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        // setLoading(true);
                        saveVideoProcessed();
                      }}
                      sx={{ mt: 2 }}
                      style={{
                        backgroundColor: PRIMARY_COLOR,
                        borderColor: PRIMARY_COLOR,
                        color: "#FFF",
                        textTransform: "none",
                      }}
                    >
                      Save
                    </LoadingButton>
                  )}
              </Box>
            </Modal>
          </ContainerOperations>
        </Box>
      )}
    </>
  );
};

export default OperationDetails;
