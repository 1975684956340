import Container from "@mui/material/Container";
import NavBar from "components/Admin/NavBar";
import { CustomContainer } from "./videoManagerStyles";
import CustomizedTable from "components/Tables/CustomizedTable";
import FetchUsersAdminCompany from "hooks/fetchUsersAdminCompany";
import { useNavigate } from "react-router-dom";
import Loading from "components/Lodings/LoadingV2";

function createRows(operators) {
  return operators.map((operator) => ({
    id: operator.userId,
    firstName: operator.firstName,
    secondName: operator.secondName,
    firstLastName: operator.firstLastName,
    email: operator.user.email,
    company: operator.company.name,
    jobTitle: operator.jobTitle,
    role: operator.role.role,
    area: operator.area,
  }));
}

const headers = [
  "First Name",
  "Second Name",
  "Last Name",
  "E-mail",
  "Company",
  "Role",
  "Area",
];

const keyValues = [
  "firstName",
  "secondName",
  "firstLastName",
  "email",
  "company",
  "role",
  "area",
];

const VideoManager = () => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const { data: adminData, error } = FetchUsersAdminCompany(adminCompanyId);
  const rows = adminData ? createRows(adminData.operators) : null;
  const navigate = useNavigate();

  const handlerClickUser = (userId) => navigate(`/upload-videos/${userId}`);

  return (
    <>
      <NavBar linkLogo={"admin"} />
      <Container maxWidth="xl">
        {adminData ? (
          <CustomContainer>
            <section className="title">
              <h1>VIDEO MANAGER</h1>
              <p>Videos upload by user</p>
            </section>
            <section className="table-container">
              {!error && rows && (
                <CustomizedTable
                  headers={headers}
                  rows={rows}
                  keyValues={keyValues}
                  handlerClick={handlerClickUser}
                />
              )}
            </section>
          </CustomContainer>
        ) : (
          <Loading />
        )}
      </Container>
    </>
  );
};

export default VideoManager;
