import { PRIMARY_COLOR } from "./../../../utils/const";
import styled from "styled-components";

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }
`;

export const WrapperTypeObjectTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0 0 10px 0px; */

  .icon-title {
    display: flex;
    align-items: center;
  }
  p {
    font-size: 1.4rem;
  }
`;
export const WrapperTypeElementTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  .icon-title {
    display: flex;
    align-items: center;
  }
`;
export const FormInput = styled.input`
  margin-left: 1px;
  margin-right: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: #1c1c1c;
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid #31a636;
`;
export const WrapperButtonsIcons = styled("div")`
  position: right;
  display: flex;
  margin-right: 1px;

  align-items: right;
  justify-content: space-between;

  margin-left: 1px;
  width: 40%;
`;
