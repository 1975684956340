import useSupercluster from "use-supercluster";
import { useEffect, useMemo, useState } from "react";
import { Marker } from "react-map-gl";
import { MarkerContainer } from "./MapStyle";
import { useDispatch, useSelector } from "react-redux";
import { NamePlateMarker } from "./userRoutes";
import OperationDetails from "components/ImageGallery/OperationDetails";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";

const OperatorClusters = ({
  operations,
  bounds,
  onSelectPoint,
  viewState,
  setViewState,
}) => {
  // const [points, setPoints] = useState([]);
  const dispatch = useDispatch();

  // const [showOperationDetails, setShowOperationDetails] = useState(false);
  const showMarkersByTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );
  const showAllEvents = useSelector((state) => state.adminReducer.showEvents);

  const operationsFlat = Object.values(operations).flatMap(
    (operations) => operations.operations
  );

  const operationsFilter = operationsFlat.filter((operation) => {
    if (!showAllEvents && showMarkersByTypeElement[operation.typeElmentGhgName])
      return true;
    return false;
  });

  const points = operationsFilter.map((operation) => ({
    type: "Feature",
    properties: {
      cluster: false,
      operation: {
        urlIcon: operation.urlIcon,
        ...operation,
      },
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(operation.location.longitude),
        parseFloat(operation.location.latitude),
      ],
    },
  }));

  const { clusters } = useSupercluster({
    points,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const iteratorOperation = (operation) => {
    const {
      urlIcon,
      operationId,
      location,
      // user: userId,
      typeElmentGhgName,
      anemometers,
    } = operation;
    return (
      <div key={operationId}>
        {showMarkersByTypeElement[typeElmentGhgName] && (
          <>
            <Marker
              latitude={Number(location.latitude)}
              longitude={Number(location.longitude)}
            >
              <NamePlateMarker
                style={{
                  cursor: "pointer",
                }}
                onClick={() => HandleClickOperation(operationId)}
              >
                <img src={urlIcon} alt="nameplate marker"></img>
              </NamePlateMarker>
            </Marker>
            {anemometers &&
              anemometers.length > 0 &&
              anemometers.map((anemometer) => {
                return (
                  <Marker
                    latitude={Number(
                      anemometer.anemometer?.locationAnemometer?.latitude || 0
                    )}
                    longitude={Number(
                      anemometer.anemometer?.locationAnemometer?.longitude || 0
                    )}
                  >
                    <NamePlateMarker
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={
                          "https://bluedragon-uploaded-files.s3.us-east-2.amazonaws.com/anemometerIconMap.png"
                        }
                        alt="anemometer icon"
                      ></img>
                    </NamePlateMarker>
                  </Marker>
                );
              })}
          </>
        )}
      </div>
    );
  };

  const HandleClickOperation = async (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
    dispatch(setShowOperationDetails(true));
  };

  const MarkersCluster = useMemo(() =>
    clusters.map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, point_count: pointCount } =
        cluster.properties;
      if (isCluster) {
        return (
          <Marker
            key={`cluster-${cluster.id}`}
            latitude={latitude}
            longitude={longitude}
          >
            <MarkerContainer>
              <div
                className="cluster-marker-operation"
                style={{
                  width: `${10 + (pointCount / points.length) * 25}px`,
                  height: `${10 + (pointCount / points.length) * 25}px`,
                }}
                onClick={() => {
                  onSelectPoint(longitude, latitude);
                  setViewState({
                    ...viewState,
                    latitude,
                    longitude,
                  });
                }}
              >
                {pointCount}
              </div>
            </MarkerContainer>
          </Marker>
        );
      }
      return iteratorOperation(cluster.properties.operation);
    })
  );
  return <>{MarkersCluster}</>;
};

export default OperatorClusters;
