import DigitalTwin from "../../components/Admin/DigitalTwin";
import DrawerObjectDT from "./../../components/DigitalTwin/DrawerObject";
import NavBar from "../../components/Admin/NavBar";

const DigitalTwinAdmin = () => {
  return (
    <>
      <NavBar linkLogo={"admin"}/>  
      <DrawerObjectDT />
      <DigitalTwin />
    </>
  );
};

export default DigitalTwinAdmin;
