import { useEffect, useMemo, useState } from "react";
import MapDigitalTwin from "./Map";
import * as turf from "@turf/turf";
import { setLocationsCSV } from "redux/actions";
import { setDataObjects } from "redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";

import useSwr from "swr";

const calculateCenter = (allObjects) => {
  const locations = allObjects.map((object) => {
    const location = [
      Number(object.location.latitude),
      Number(object.location.longitude),
    ];
    return location;
  });
  var features = turf.points(locations);
  const center = turf.center(features);
  return center.geometry.coordinates;
};

const DigitalTwinAdmin = () => {
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [centerLocations, setCenterLocations] = useState({});
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );

  const url = `${
    process.env.REACT_APP_URL_BACKEND
  }api/v1/admin-company/objects/${adminCompanyId}?${
    startDate && `start-date=${startDate}`
  }&${endDate && `end-date=${endDate}`}`;

  const { data, error } = useSwr(url);

  const objects = useMemo(() => {
    return data && !error ? data : [];
  }, [data, error]);

  const urlOperations = `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/admin/${adminCompanyId}`;
  const { data: dataOperations, error: errorOperations } =
    useSwr(urlOperations);
  const operations = dataOperations && !errorOperations ? dataOperations : [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (objects) {
          dispatch(setDataObjects(objects));
          const center = calculateCenter(objects);
          setCenterLocations({
            latitude: center[0],
            longitude: center[1],
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    dispatch(setLocationsCSV({}));
  }, [objects, dispatch]);

  return (
    <>
      <MapDigitalTwin
        centerLocations={centerLocations}
        objects={objects}
        operations={operations}
      />
    </>
  );
};

export default DigitalTwinAdmin;
