import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { HeadModal } from "./dialogsStyles";
import { PRIMARY_COLOR } from "./../../utils/const";
import { AiFillCloseCircle } from "react-icons/ai";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const OpenDialog = (props) => {
  const { openDialog, setOpenDialog, execute, content } = props;
  const open = openDialog;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoad, setIsLoad] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleExecute = async () => {
    setIsLoad(true);
    await execute();
    setIsLoad(false);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialogContent-root": {
            padding: "0px 10px 0px 10px",
          },
        }}
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">{content.title}</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle
              style={{ color: "#FFF", fontSize: 21, marginBottom: -4 }}
            />
          </Button>
        </HeadModal>
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            marginTop: "50px",
          }}
        ></DialogTitle>
        <DialogContent>
          <DialogContentText>{content.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {content.disagree && (
            <Button
              onClick={handleClose}
              style={{
                background: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: "#fff",
              }}
            >
              {content.disagree}
            </Button>
          )}
          {content.agree && (
            <Button
              onClick={handleExecute}
              autoFocus
              style={{
                background: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: "#fff",
              }}
            >
              {isLoad ? <CircularProgress /> : content.agree}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OpenDialog;
