import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const MapCardContainer = styled.div`
  /* height: 100%; */
  height: 200hv;

  width: 100%;

  display: flex;
  flex-direction: column;
  padding-top: 90px;
  align-items: center;
  background-color: #f9f9f9;
  /* margin-left: 10px; */
  /* margin-right: 80px; */
`;

export const MapWrapper = styled.div`
  /* max-width: 1000px; */
  /* height: 50%; */
  /* padding:10px; */

  width:90%;
  display: grid;
  background-color: #000;

  /* grid-auto-rows: 40rem; */
  grid-template-columns: 350px minmax(10px, 5000px);
  grid-template-rows: 40rem; ;
  /* grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr) ); */

  /* grid-template-columns: minmax(1fr, 1fr) minmax(60rem, 1fr) ; */
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

// export const MapWrapper = styled.div`
//   /* max-width: 1000px; */
//   /* height: 50%; */
//   padding:10px;

//   width:90%;
//   display: grid;
//   /* background-color: #000; */

//   /* grid-auto-rows: 40rem; */
//   grid-template-columns: minmax(50px, 5000px) 300px;
//   grid-template-rows: 50rem; ;
//   /* grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr) ); */

//   /* grid-template-columns: minmax(1fr, 1fr) minmax(60rem, 1fr) ; */
//   align-items: center;
//   justify-content: center;
//   gap: 1rem;
//   }
// `;

export const MapH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const MapCard = styled.div`
  position: absolute;
  align-content: stretch;
  overflow: hidden;
  object-fit: cover;
  object-fit: fill;
  height: 91.7%;
  width: 100%;
  top: 80px;
  /* width: 80%; */
  /* object-fit: fill; */
  display: flex;
  flex-direction: column;
  /* justify-content: top; */
  /* align-items: center; */
  background-color: #fff;
  /* margin-bottom: 100px; */
  /* padding: 10px 10px; */
  /* padding-right: 10px; */
  /* padding-left: 10px; */
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
  /* box-shadow: 5px 3px 6px #00000066; */
  /* box-shadow: 5px 3px 6px #00000066; */
`;

export const WrapperButtons = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
`;


export const MarkerCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;

  border-radius: 10px;
  max-height: 540px;
  max-width: 320px;
  /* padding: 30px; */
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const LayersCard = styled.div`
  align-content: stretch;
  overflow: hidden;
  object-fit: cover;
  object-fit: fill;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;

  /* border-radius: 10px; */
  /* max-height: 840px; */
  /* height: 90%; */
  max-width: 320px;
  max-height: 320px;
  padding: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const WrapperImages = styled.div`
  display: flex;
  margin: 20px;
  overflow-x: auto;
`;

export const ScrollChild = styled.img`
  width: 90%;
  height: 150px;
  margin-right: 10px;
  border-radius: 10px;
`;


export const NavbarLogoR = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const Img = styled.img`
  width: 160px;
  height: auto;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: #222729;
  padding: 10px 22px;
  color: #fff;
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #222729;
  }
`;
