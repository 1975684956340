import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { DeleteUserButton } from "./UserRegisterElements.jsx";
import OpenDialog from "./../Dialogs/OpenDialog.jsx";
import Profile from "./../Admin/Profile";
import OperationDialog from "./../Dialogs/OperationDialog";
import { useDispatch } from "react-redux";
import {
  setOperationDetails,
  setShowOperationDetails,
} from "../../redux/actions/index.jsx";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "secondName",
    numeric: false,
    disablePadding: false,
    label: "Second Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "E-mail",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "area",
    numeric: false,
    disablePadding: false,
    label: "Area",
  },
  {
    id: "project",
    numeric: false,
    disablePadding: false,
    label: "Project",
  },
  {
    id: "confirmationToken",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Creation Date",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    ></Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ handleClose }) {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [idUserDelete, setIdUserDelete] = useState(null);
  const [contentDialog, setContentDialog] = useState({});
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userId, setUserId] = useState(null);

  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-decimetrix/admins/${adminDecimetrixId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const res = await result.data;
      const admins = createRows(res.adminCompany);
      setRows(admins);
    };
    fetchData();
  }, [handleClose]);

  const createRows = (admins) => {
    return admins.map((admin) => {
      return {
        id: admin.userId,
        firstName: admin.firstName,
        lastName: admin.firstLastName,
        secondName: admin.secondName,
        email: admin.user.email,
        company: admin.company.name,
        role: admin.role?.role,
        area: admin.area,
        project: admin.project,
        createdAt: admin.createdAt.substring(0, 10),
        canDelete: admin.operators.length === 0,
        confirmationToken: admin.user.userVerification.confirmationToken,
      };
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleDelete = async (user) => {
    setIdUserDelete(user.id);
    setContentDialog({
      title: "",
      description: `Are you sure you want to delete ${user.firstName}?`,
      disagree: "Cancel",
      agree: "Accept",
    });
    setOpenDialog(true);
  };

  const deleteUser = async () => {
    const result = await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/${idUserDelete}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const rowsHelp = rows.filter((row) => row.id !== idUserDelete);
    setRows(rowsHelp);
    setContentDialog({});
    setIdUserDelete(null);
    setOpenDialog(false);
  };

  const handleClickUser = async (userId) => {
    const user = {
      content: <Profile userId={userId} />,
      title: "User Details",
    };
    dispatch(setOperationDetails(user));
    dispatch(setShowOperationDetails(true));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={deleteUser}
        content={contentDialog}
      />

      <OperationDialog
        style={{
          margin: 0,
          padding: 0,
          width: "70%",
          height: "70%",
          maxWidth: "none",
        }}
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              "& th": {
                color: "rgba(96, 96, 96)",
                backgroundColor: "rgb(238, 238, 238)",
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const prop = row.canDelete
                    ? { onClick: () => handleDelete(row) }
                    : { disable: "true" };
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.firstName}
                      selected={isItemSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell id={labelId} align="left">
                        <button
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            border: "none",
                          }}
                          onClick={() => handleClickUser(row.id)}
                        >
                          {row.firstName}
                        </button>
                      </TableCell>
                      <TableCell align="left">{row.secondName}</TableCell>
                      <TableCell align="left">{row.lastName}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.company}</TableCell>
                      <TableCell align="left">{row.role}</TableCell>
                      <TableCell align="left">{row.area}</TableCell>
                      <TableCell align="left">{row.project}</TableCell>
                      <TableCell align="left">
                        {row.confirmationToken === null
                          ? "Verified e-mail"
                          : "E-Mail pending of verification"}
                      </TableCell>
                      <TableCell align="left">
                        <DeleteUserButton {...prop}>
                          <DeleteIcon
                            sx={{
                              fontSize: "2rem",
                              color: prop.disable ? "	#808080" : "#ff5151",
                              cursor: prop.disable ? "not-allowed" : "pointer",
                            }}
                          />
                        </DeleteUserButton>
                      </TableCell>
                      <TableCell align="left">{row.createdAt}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
