import {
  SHOW_DRAWER_DIGITAL_TWIN,
  SHOW_CARD_MAKER_DIGITAL_TWIN,
  SET_DATA_OBJECT_DT,
  SET_PROFILE_IMAGE,
  SET_DATA_OBJECT_DT_IMAGES,
  SHOW_DRAWER_OBJECT_DT,
  SHOW_MARKERS_DIGITAL_TWIN,
  SET_MARKER_BY_TYPE_ELEMENT_ID,
  SHOW_MARKERS_BY_ACTIVITIES,
  SHOW_MARKER_BY_ELEMENT_WITH_MEDIA,
  SHOW_OPERATION_DETAILS,
  OPERATION_DETAILS,
  SET_FILTER_OBJECTS_BY_DATA,
} from "../actions/types";

const initialState = {
  showDrawerDigitalTwin: false,
  showDrawerObjectDt: false,
  cardMarkerDigitalTwin: false,
  objectDataDT: {},
  profileImage: [],
  objectDataDTImages: [],
  showMarkersDigitalTwin: true,
  showMarkerByTypeElementId: {},
  showMarkersByActivities: {},
  showMarkerByElementWithMedia: false,
  showOperationDetails: false,
  operationDetails: {},
  setFilterObjectsByData: {},
};

const ShowDrawerDigitalTwinReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER_OBJECTS_BY_DATA:
      return {
        ...state,
        setFilterObjectsByData: action.payload,
      };
    case OPERATION_DETAILS:
      return {
        ...state,
        operationDetails: action.payload,
      };
    case SHOW_OPERATION_DETAILS:
      return {
        ...state,
        showOperationDetails: action.payload,
      };
    case SHOW_MARKER_BY_ELEMENT_WITH_MEDIA:
      return {
        ...state,
        showMarkerByElementWithMedia: action.payload,
      };
    case SHOW_DRAWER_DIGITAL_TWIN:
      return {
        ...state,
        showDrawerDigitalTwin: !state.showDrawerDigitalTwin,
      };
    case SHOW_DRAWER_OBJECT_DT:
      return {
        ...state,
        showDrawerObjectDt: action.payload,
      };
    case SHOW_CARD_MAKER_DIGITAL_TWIN:
      return {
        ...state,
        cardMarkerDigitalTwin: action.payload,
      };
    case SET_DATA_OBJECT_DT:
      return {
        ...state,
        objectDataDT: action.payload,
      };
    case SET_DATA_OBJECT_DT_IMAGES:
      return {
        ...state,
        objectDataDTImages: action.payload,
      };
    case SET_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.payload,
      };
    case SHOW_MARKERS_DIGITAL_TWIN:
      return {
        ...state,
        showMarkersDigitalTwin: !state.showMarkersDigitalTwin,
      };

    // reducer all markers
    case SET_MARKER_BY_TYPE_ELEMENT_ID:
      return {
        ...state,
        showMarkerByTypeElementId: action.payload,
      };
    case SHOW_MARKERS_BY_ACTIVITIES:
      return {
        ...state,
        showMarkersByActivities: action.payload,
      };

    default:
      return state;
  }
};

export default ShowDrawerDigitalTwinReducer;
