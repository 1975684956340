import DashboardsComponent from "../components/Dashboard/index";
import { useParams } from "react-router-dom";

const Dashboard = () => {
  const { adminId, customerId } = useParams();
  return (
    <div>
      <DashboardsComponent customerId={customerId} adminId={adminId} />
    </div>
  );
};

export default Dashboard;
