import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { HeadModal } from "./dialogsStyles";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { setShowOperationDetails } from "../../redux/actions";
import { AiFillCloseCircle } from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";

const OperationDialog = (props) => {
  const dispatch = useDispatch();
  const openDialog = useSelector(
    (state) => state.digitalTwinReducer.showOperationDetails
  );

  const params = useSelector(
    (state) => state.digitalTwinReducer.operationDetails
  );
  const { content, title } = params;

  const { style } = props;
  const open = openDialog;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    dispatch(setShowOperationDetails(false));
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": style,
          "& .MuiDialogContent-root": {
            padding: "0px 10px 0px 10px",
          },
        }}
      >
        <HeadModal>
          <div className="title">
            <img
              src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
              alt=""
              className="neurona"
            />
            <span className="titleHeadModal">{title}</span>
          </div>
          <Button onClick={handleClose}>
            <AiFillCloseCircle style={{ color: "#FFF", fontSize: 21 }} />
          </Button>
        </HeadModal>

        <DialogTitle sx={{ fontSize: "1.6rem" }} id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            backgroundColor: "#f0f0f0",
          }}
        >
          {content}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OperationDialog;
