import Grid from "@mui/material/Grid";
import Map, {
  NavigationControl,
  Source,
  Layer,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  ScaleControl,
} from "react-map-gl";

import { showRegionDetailsDrawer } from "redux/actions/admin";
import { useState, useCallback, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { MapCard, NavInfo } from "./UserRoutesElements";
import DrawControl from "./DrawControl";
import MapDrawer from "./MapDrawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { grey } from "@mui/material/colors";
import OpenDialog from "components/Dialogs/OpenDialog.jsx";
import DialogInformation from "components/Dialogs/DialogInformation.jsx";
import LayersIcon from "@mui/icons-material/Layers";
import axios from "axios";
import { CustomSelect, ButtonInformation, ButtonBaseMap } from "./userRoutes";

import {
  setDataRegion,
  SetUsers,
  SetShowUsers,
  SetShowAllUsers,
} from "redux/actions/admin";

import ShowLocationFromCSV from "./ShowLocationFromCSV";
import HandlerDrawerControl from "./HandlerDrawerControl";
import ObjectsClusters from "./ObjectsClusters";
import OperationsClusters from "./OperationsClusters";
import OperationDialog from "components/Dialogs/OperationDialog";

//GeoControl
import GeocoderControl from "./geocoder-control";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { ContentBaseMap, ContentInformation } from "./ContentDialogs";
import { isAfter, parse } from "date-fns";
import { isBefore } from "date-fns/esm";

const CustomizedChevronLeftIcon = styled(ChevronLeftIcon)`
  background-color: #0e4d45;
  border-radius: 23px;
`;

const CustomizedChevronRightIcon = styled(ChevronRightIcon)`
  background-color: #0e4d45;
  border-radius: 23px;
`;

const MapDigitalTwin = ({ objects, operations }) => {
  const [baseMap, setBaseMap] = useState("streets-v11");
  const [open, setOpen] = useState(true);

  const [regions, setRegions] = useState();
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);

  const [haveObjects, setHaveObjects] = useState(false);
  const [information, setInfoObjects] = useState(false);
  const [section, setSection] = useState("ghg");
  const [filterObjects, setFilterObjects] = useState([]);

  const { onCreate, onSelect, onUpdate, onDelete } = HandlerDrawerControl();

  const dispatch = useDispatch();

  const locationsCSV = useSelector(
    (state) => state.locationCSVReducer.locationsCSV
  );

  const [viewState, setViewState] = useState({
    latitude: 4.81500179,
    longitude: -75.725484,
    width: "100vw",
    height: "100vh",
    zoom: 5,
  });

  const [contentDialog, setContentDialog] = useState({
    title: "No items",
    description:
      "At this moment there are no objects to see on the map, you can see the map empty.",
    disagree: "See map",
  });

  const users = useSelector((state) => state.adminReducer.SetUsers);

  const showUsers = useSelector((state) => state.adminReducer.SetShowUsers);

  const showAllUsers = useSelector(
    (state) => state.adminReducer.SetShowAllUsers
  );

  const mapRef = useRef();

  const dateFilterObject = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );

  useEffect(() => {
    const { endDate: endDateString, startDate: startDateString } =
      dateFilterObject;
    const endDate = parse(endDateString, "yyyy-MM-dd", new Date());
    const startDate = parse(startDateString, "yyyy-MM-dd", new Date());
    const newObjects = objects.filter((object) => {
      const { date: dateObjectString } = object;
      const dateObject = parse(dateObjectString, "yyyy-MM-dd", new Date());
      const isInRange =
        !isBefore(dateObject, startDate) && !isAfter(dateObject, endDate);
      return isInRange;
    });
    setFilterObjects(newObjects);
  }, [dateFilterObject, objects]);

  useEffect(() => {
    if (objects.length > 0) {
      const users = {};
      for (const { user } of objects) {
        if (user.userTypeId === 1) {
          users[user.id] = {
            operator: `${user.adminCompany.firstName} ${user.adminCompany.firstLastName}`,
            isShow: true,
          };
        }
        if (user.userTypeId === 2) {
          users[user.id] = {
            operator: `${user.operator.firstName} ${user.operator.firstLastName}`,
            isShow: true,
          };
        }
      }
      setFilterObjects(objects);
      dispatch(SetUsers(users));
      setHaveObjects(false);
    }
  }, [objects, dispatch]);

  // get all regions from api-green-dragon-mg
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_MG_API}region`
        );
        const res = await response.data;
        const layersIds = [];
        res.map((region) => layersIds.push(region._id));
        setInteractiveLayerIds(layersIds);
        setRegions(res);
      } catch (error) {
        console.error("error:", error);
      }
    };
    fetchData();
  }, []);

  // show regions Polygon on map
  const iteratorRegions = () => {
    return regions.map((object, id) => {
      const features = object.data.features;
      const featuresFilter = features.filter(
        (feature) => feature.geometry.type === "Polygon"
      );
      return featuresFilter.map((feature, index) => (
        <div key={index}>
          <Source type="geojson" data={feature} key={id}>
            <Layer
              key={index}
              id={object._id}
              type="fill"
              paint={{
                "fill-color": "#00957f",
                "fill-opacity": 0.7,
              }}
            />
          </Source>
        </div>
      ));
    });
  };

  const changeBaseMap = (event) => {
    setBaseMap(event.target.value);
    setHaveObjects(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (drawState) => {
    setOpen(false);
  };

  const filterObjectsByUser = () => {
    const objectsFilter = objects.filter((object) => {
      const userId = object.user.id;
      if (users && users[userId].isShow) {
        return true;
      }
      return false;
    });
    setFilterObjects(objectsFilter);
  };

  if (showUsers) {
    dispatch(SetShowUsers(!showUsers));
    filterObjectsByUser();
  }

  const seeAllMarkerOfUser = () => {
    for (const key in users) {
      users[key].isShow = true;
      setHaveObjects(false);
    }
    setFilterObjects(objects);
  };

  if (showAllUsers) {
    dispatch(SetShowAllUsers(!showAllUsers));
    seeAllMarkerOfUser();
  }

  //onHover
  const onHover = useCallback(
    (event) => {
      const { features } = event;
      const hoveredFeature = features && features[0];
      if (features && features.length > 0) {
        dispatch(setDataRegion(hoveredFeature.properties));
        dispatch(showRegionDetailsDrawer(true));
      }
    },
    [dispatch]
  );

  const handleTabInformation = (event) => {
    setSection(event.currentTarget.value);
  };

  // Modal that show the objects
  const handleInformationObjects = () => {
    setInfoObjects(true);
  };

  const onSelectPoint = useCallback((longitude, latitude) => {
    mapRef.current?.flyTo({
      center: [longitude, latitude],
      duration: 2000,
      zoom: 16,
    });
  }, []);

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  // Modal that filter objects for user
  const handleBaseMap = () => {
    setHaveObjects(true);
    setContentDialog(ContentBaseMap(changeBaseMap));
  };

  const coordinatesGeocoder = function (query) {
    // Match anything which looks like
    // decimal degrees coordinate pair.
    const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
    );
    if (!matches) {
      return null;
    }

    function coordinateFeature(lng, lat) {
      return {
        center: [lng, lat],
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
        place_name: "Lat: " + lat + " Lng: " + lng,
        place_type: ["coordinate"],
        properties: {},
        type: "Feature",
      };
    }

    const coord1 = Number(matches[1]);
    const coord2 = Number(matches[2]);
    const geocodes = [];

    if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
    }

    if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
    }

    return geocodes;
  };

  return (
    <>
      <Grid container>
        {/* Dialogs */}
        <OpenDialog
          openDialog={haveObjects}
          setOpenDialog={setHaveObjects}
          content={contentDialog}
        />
        {/* Dialog infotmation */}
        <DialogInformation
          openDialog={information}
          setOpenDialog={setInfoObjects}
          content={ContentInformation(section, handleTabInformation)}
        />
        <Grid item xs={12} sm={6} md={9}>
          <MapCard>
            <CustomSelect>
              <NavInfo tipo={open}>
                <MapDrawer drawState={open} />
                {open === false ? (
                  <IconButton
                    color="error"
                    onClick={handleDrawerOpen}
                    fontSize="medium"
                    sx={{ color: grey[50] }}
                  >
                    <CustomizedChevronLeftIcon sx={{ color: grey[100] }} />
                  </IconButton>
                ) : (
                  <IconButton
                    color="error"
                    onClick={handleDrawerClose}
                    fontSize="large"
                    sx={{ color: grey[50] }}
                  >
                    <CustomizedChevronRightIcon sx={{ color: grey[100] }} />
                  </IconButton>
                )}
              </NavInfo>
            </CustomSelect>

            {/* Button information */}
            {objects && (
              <ButtonInformation onClick={handleInformationObjects}>
                <InfoIcon
                  style={{
                    color: "#282828",
                    fontSize: "1.6rem",
                  }}
                />
              </ButtonInformation>
            )}
            {/* Button layer map */}
            {objects && (
              <ButtonBaseMap onClick={handleBaseMap}>
                <LayersIcon
                  style={{
                    color: "#282828",
                    fontSize: "1.6rem",
                  }}
                />
              </ButtonBaseMap>
            )}

            <Map
              {...viewState}
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              style={{ width: "100%", height: "100vh", margin: "auto" }}
              mapStyle={`mapbox://styles/mapbox/${baseMap}`}
              // onClick={handleClick}
              interactiveLayerIds={interactiveLayerIds}
              onMouseMove={onHover}
              onMouseLeave={() => dispatch(showRegionDetailsDrawer(false))}
              onMove={useCallback((e) => {
                setViewState(e.viewState);
              }, [])}
              ref={mapRef}
              attributionControl={false}
            >
              <GeocoderControl
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                position="top-right"
                placeholder={"Try: Longitude, Latitude"}
                localGeocoder={coordinatesGeocoder}
              />

              <NavigationControl />
              {Object.keys(locationsCSV).length !== 0 && (
                <ShowLocationFromCSV
                  locationsCSV={locationsCSV}
                  onSelectPoint={onSelectPoint}
                  bounds={bounds}
                  viewState={viewState}
                  setViewState={setViewState}
                />
              )}
              <DrawControl
                position="top-right"
                displayControlsDefault={false}
                userProperties={true}
                controls={{
                  polygon: true,
                  line_string: true,
                  point: true,
                  trash: true,
                }}
                onSelect={onSelect}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onDelete={onDelete}
              />
              {regions && iteratorRegions()}
              <AttributionControl customAttribution="© Decimetrix 2022" />
              <FullscreenControl />
              <GeolocateControl
                ref={useCallback((ref) => {
                  if (ref) {
                    ref.trigger();
                  }
                }, [])}
                trackUserLocation={true}
              />
              <ScaleControl position="bottom-right" />

              {filterObjects && (
                <ObjectsClusters
                  objects={filterObjects}
                  onSelectPoint={onSelectPoint}
                  bounds={bounds}
                  viewState={viewState}
                  setViewState={setViewState}
                />
              )}
              <OperationsClusters
                operations={operations}
                onSelectPoint={onSelectPoint}
                bounds={bounds}
                viewState={viewState}
                setViewState={setViewState}
              />
              <OperationDialog
                style={{
                  margin: 0,
                  padding: 0,
                  width: "70%",
                  height: "100%",
                  maxWidth: "none",
                }}
              />
            </Map>
          </MapCard>
        </Grid>
      </Grid>
    </>
  );
};

export default MapDigitalTwin;
