import styled from "styled-components";

export const ImgIconOperation = styled.img`
  cursor: pointer;
`;

export const MapCardContainer = styled.div`
  height: 200hv;
  width: 200hv;
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 50px;
  margin-top: 50px;
  align-items: center;
  background-color: #f9f9f9;
  margin-bottom: 100px;
`;
export const MapCard = styled.div`
  position: relative;
  /* align-content: stretch; */
  overflow: hidden;
  /* object-fit: cover; */
  /* object-fit: fill; */

  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
`;

export const NamePlateMarker = styled.button`
  background: none;
  border: none;

  img {
    width: 40px;
  }
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0e4d45;
    color: white;
  }
`;

export const ButtonBaseMap = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 107px;
  right: 9px;
  z-index: 10;
  background-color: #f9f9f9;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;
`;