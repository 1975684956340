// Required modules
import NavBar from "components/Admin/NavBar";
import CustomizedTable from "components/Tables/CustomizedTable";
import { useEffect, useState } from "react";
import Form from "components/Forms/Form";

// Hooks
import useGet from "hooks/useFetch";
import useForm from "hooks/useForm";

// Necessary data and methods
import {
  headersTableTypeRegions,
  keyValuesTableTypeRegions,
  initialValuesFormRegionType,
  buttonCreate,
  createRowsTypeRegion,
  setFormRegionType,
} from "./dataConfigAdmin";

// Styles
import {
  ColumnAdminConfig,
  ConfigAdminContainer,
  RowAdminConfig,
  TitleAdminConfig,
  CreateNewRegionButton,
} from "./configAdminElemts";

// Icons
import { IoMdAdd } from "react-icons/io";

export default function TypeRegions() {
  // States
  const [typeRegionsRow, setTypeRegionsRow] = useState();

  // All regions
  const [typeRegions, errorTypeRegion, setTypeRegions] =
    useGet(`api/v1/type-region`);

  // Field for form
  const [form, methodsForm] = useForm(initialValuesFormRegionType);
  const { handleChangeForm, changeFormForSpecificValues, clearField } =
    methodsForm;
  const [buttonsForm, setButtonsForm] = useState([buttonCreate]);

  // Click in type region into table
  const handleClickRegionType = (id) => {
    const typeRegion = typeRegions.find((typeRegion) => {
      return typeRegion.id === id;
    });
    setFormRegionType(typeRegion, changeFormForSpecificValues, setButtonsForm);
  };

  // Clear form when click in new region button
  const handleClearForm = () => {
    setFormRegionType(null, clearField);
    setButtonsForm([buttonCreate]);
  };

  // manage rows
  const handleRows = (type, change) => {
    if (type === "create") {
      setTypeRegions([...typeRegionsRow, change]);
    }

    if (type === "update") {
      const newTypeRegions = typeRegions.map((typeRegion) => {
        if (typeRegion.id === change.id) {
          return change;
        }
        return typeRegion;
      });
      setTypeRegions(newTypeRegions);
    }

    if (type === "delete") {
      const newTypeRegions = typeRegions.filter((typeRegion) => {
        return typeRegion.id !== change;
      });
      setTypeRegions(newTypeRegions);
    }

    handleClearForm();
  };

  // Create rows with type regions
  useEffect(() => {
    typeRegions && setTypeRegionsRow(() => createRowsTypeRegion(typeRegions));
  }, [typeRegions]);

  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <ConfigAdminContainer>
        <TitleAdminConfig>Configure Type Regions</TitleAdminConfig>
        <RowAdminConfig>
          <ColumnAdminConfig>
            {typeRegionsRow && !errorTypeRegion && (
              <CustomizedTable
                headers={headersTableTypeRegions}
                rows={typeRegionsRow}
                keyValues={keyValuesTableTypeRegions}
                handlerClick={handleClickRegionType}
              />
            )}
          </ColumnAdminConfig>
          <ColumnAdminConfig>
            <CreateNewRegionButton>
              {buttonsForm.length > 1 && (
                <button onClick={handleClearForm}>
                  <span>
                    <IoMdAdd />
                  </span>
                  New Region
                </button>
              )}
            </CreateNewRegionButton>
            <Form
              form={form}
              handleChange={handleChangeForm}
              buttons={buttonsForm}
              handleRows={handleRows}
            />
          </ColumnAdminConfig>
        </RowAdminConfig>
      </ConfigAdminContainer>
    </div>
  );
}
