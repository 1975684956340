import NavBar from "../components/NavBar";
import MapCollectionComponent from "../components/MapCollection/";
import { useParams } from "react-router-dom";

const MapCollection = () => {
  const { date } = useParams();

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: {},
    button: {
      link: `collections`,
      text: "Back",
    },
  };

  return (
    <>
      <NavBar configNavbar={configNavbar} />
      <MapCollectionComponent date={date} />
    </>
  );
};

export default MapCollection;
