import styled from "styled-components";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const NamePlateMarker = styled.button`
  background: none;
  border: none;

  img {
    width: 40px;
  }
`;

export const CustomSelect = styled("div")`
  position: absolute;
  top: 0.6rem;
  left: 25px;
  z-index: 10;
  select {
    padding: 7px 10px;
    border-radius: 5px;
    background-color: #0E4D45;
    color: white;
  }
`;

export const ButtonFilter = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 19.3rem;

  right: 10px;
  z-index: 10;
  background-color: #f9f9f9;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px 8px;
  border-radius: 5px;
`;

export const ButtonInformation = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 399px;
  right: 9px;
  z-index: 10;
  background-color: #f9f9f9;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;
`;
export const ButtonBaseMap = styled("button")`
  outline: none;
  border: none;
  position: absolute;
  top: 357px;
  right: 9px;
  z-index: 10;
  background-color: #f9f9f9;
  color: white;
  display: inline-block;
  cursor: pointer;
  padding: 7px;
  border-radius: 5px 4px;
`;

export const CustomizeToggleButtonGroup = styled(ToggleButtonGroup)`

  @media (max-width: 480px) {
    padding: 1%;
    margin-right: 2%;
  }
`;
