import { Container } from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import ListUser from "./ListUser";
import FormRegister from "./FormRegister";

import { Title, ButtonStyle } from "./styleUserRegister";

const UserRegister = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container style={{
      paddingTop: 80,
    }} maxWidth="xl">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6} xl={6}>

      
      <Title className="title">
        <h1>User Registration</h1>
      </Title>
      </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6}>
      <ButtonStyle>
        <button onClick={handleClickOpen}>Add User</button>
      </ButtonStyle>
      </Grid>
        </Grid>
      </Box>
      <div>
        <FormRegister open={open} handleClose={handleClose} />
        <ListUser handleClose={handleClose} />
      </div>
    </Container>
  );
};

export default UserRegister;
