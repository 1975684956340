import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "../../utils/const";

export const ContentButton = styled.div`
  margin-top: 2px;
`;

export const Wrapperbtn = styled("div")`
  position: relative;
  margin-left: 2rem;
  margin-top: 3.6rem;
  width: 100%;
`;

export const WrapperIcon = styled("div")`
  position: relative;
  margin-left: 1rem;
  margin-top: 5rem;
  width: 100%;
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 1px;
    margin-bottom: 6px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Label = styled("label")`
  background: ${PRIMARY_COLOR};
  width: 50%;

  border-radius: 5px;
  color: #f9f9f9;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 1.4rem;

  p {
    margin-left: 0.5rem;
  }

  &:hover {
    background: ${SECOND_COLOR};
  }
`;

export const ContainerForm = styled.div`
  display: flex;
  width: 95%;
  margin: 0 auto;

  #form-file-upload {
    height: 300px;
    width: 100%;
    text-align: center;
    position: relative;
  }

  #input-file-upload {
    display: none;
  }

  #label-file-upload.drag-active {
    background-color: #9bc4cb;
  }

  #drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  #label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;
  }

  .upload-button:hover {
    text-decoration-line: underline;
  }

  .drag-info p {
    font-size: 1.7rem;
  }
  .drag-info button p {
    color: ${PRIMARY_COLOR};
  }
`;

export const WrapperVideos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 2rem;
  width: 95%;
  margin: 0 auto;
`;

export const VideoContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 32%;
  min-width: 300px;
  height: auto;
  margin: 10px 0;
  padding: 0 10px 20px 10px;
  box-shadow: 0 0 5px 0 #cfcfcf;
  border-radius: 5px;
  align-items: center;
  text-align: center;

  .video-load {
    position: absolute;
    left: 10px;
    top: 5px;
  }
  .delete-button {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .video-container {
    margin-top: 40px;
  }
  .video-details {
    margin: 10px 0;
  }
`;
