import {
  SET_DATA_REGION,
  SET_DATA_OBJECTS_POLYGON,
  SET_DATA_OBJECTS,
  SHOW_DRAWER_USER_ROUTES,
  TYPE_JOB,
  SHOW_DRAWER_DIGITAL_TWIN_ADMIN,
  SET_COORDINATES_USER_JOB,
  SET_FEATURES_DETAILS,
  SET_FEATURES_DETAILS_DT,
  SET_LOCATIONSID_IN_POLYGON,
  SET_GEOMETRY_POLYGON,
  SET_FEATURES_POLYGON,
  SHOW_MARKER_BY_TYPE_OBJECT_ID,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID,
  SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD,
  SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON,
  SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON,
  SHOW_REGION_DETAILS_DRAWER,
  SHOW_MARKER_BY_ELEMENT_WITH_MEDIA,
  SET_FILTER_USERS,
  SET_SHOW_FILTER_USERS,
  SET_SHOW_FILTER_ALL_USERS,
  FILTER_BY_EVENTS,
} from "./types";

export const showMarkerseByTypeObjectPolygon = (payload) => {
  return {
    type: SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON,
    payload,
  };
};

export const showMarkerseByTypeElementPolygon = (payload) => {
  return {
    type: SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON,
    payload,
  };
};

export const showMarkerseByTypeObject = (payload) => {
  return {
    type: SHOW_MARKER_BY_TYPE_OBJECT_ID,
    payload,
  };
};

export const showMarkerseByTypeElement = (payload) => {
  return {
    type: SHOW_MARKER_BY_TYPE_ELEMENT_ID,
    payload,
  };
};

export const showMarkerseByTypeObjectLoad = (payload) => {
  return {
    type: SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD,
    payload,
  };
};

export const showMarkerseByTypeElementLoad = (payload) => {
  return {
    type: SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD,
    payload,
  };
};

export const setDataObjects = (payload) => {
  return {
    type: SET_DATA_OBJECTS,
    payload,
  };
};

export const setDataObjectsPolygon = (payload) => {
  return {
    type: SET_DATA_OBJECTS_POLYGON,
    payload,
  };
};

export const setDataRegion = (payload) => {
  return {
    type: SET_DATA_REGION,
    payload,
  };
};

export const showDrawerUserRoutes = (payload) => {
  return {
    type: SHOW_DRAWER_USER_ROUTES,
    payload,
  };
};

export const setTypeJob = (payload) => {
  return {
    type: TYPE_JOB,
    payload,
  };
};

export const showDrawerDigitalTwinAdmin = (payload) => {
  return {
    type: SHOW_DRAWER_DIGITAL_TWIN_ADMIN,
    payload,
  };
};

export const setCoordinatesUserRoute = (payload) => {
  return {
    type: SET_COORDINATES_USER_JOB,
    payload,
  };
};

export const setFeaturesDetails = (payload) => {
  return {
    type: SET_FEATURES_DETAILS,
    payload,
  };
};

export const setFeaturesDetailsDT = (payload) => {
  return {
    type: SET_FEATURES_DETAILS_DT,
    payload,
  };
};

export const setLocationsIdPolygon = (payload) => {
  return {
    type: SET_LOCATIONSID_IN_POLYGON,
    payload,
  };
};

export const setGeometryPolygon = (payload) => {
  return {
    type: SET_GEOMETRY_POLYGON,
    payload,
  };
};

export const setFeaturesPolygon = (payload) => {
  return {
    type: SET_FEATURES_POLYGON,
    payload,
  };
};

export const showRegionDetailsDrawer = (payload) => {
  return {
    type: SHOW_REGION_DETAILS_DRAWER,
    payload,
  };
};

export const showMarkerByElementWithMedia = (payload) => {
  return {
    type: SHOW_MARKER_BY_ELEMENT_WITH_MEDIA,
    payload,
  };
};

export const SetUsers = (payload) => {
  return {
    type: SET_FILTER_USERS,
    payload,
  };
};

export const SetShowUsers = (payload) => {
  return {
    type: SET_SHOW_FILTER_USERS,
    payload,
  };
};

export const SetShowAllUsers = (payload) => {
  return {
    type: SET_SHOW_FILTER_ALL_USERS,
    payload,
  };
};

export const setShowEvents = (payload) => {
  return {
    type: FILTER_BY_EVENTS,
    payload,
  };
};
