import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import React, { useState } from "react";
import {
  GalleryContainer,
  GalleryContainerNoThumbnails,
} from "./ImageDetailsElements";

const PhotoGalley = ({ photos, state }) => {
  const items = photos.map((photo) => {
    return {
      original: photo?.url || photo.urlPhoto || photo,
      thumbnail: photo?.url || photo.urlPhoto || photo,
    };
  });

  return (
    <>
      {state === false ? (
        <GalleryContainerNoThumbnails>
          <ImageGallery
            class="image-gallery-description"
            items={items}
            showBullets={true}
            showPlayButton={false}
            showThumbnails={state}
          />
        </GalleryContainerNoThumbnails>
      ) : (
        <GalleryContainer>
          <ImageGallery
            class="image-gallery-description"
            items={items}
            showBullets={true}
            showPlayButton={false}
            showThumbnails={true}
            thumbnailPosition={"left"}
          />
        </GalleryContainer>
      )}
    </>
  );
};

export default PhotoGalley;
