import NavBar from "../components/NavBar/index";
import CollectionsComponent from "../components/Collections/index";

const Collections = () => {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: {},
    button: {
      link: "hub",
      text: "Back",
    },
  };

  return (
    <div>
      <NavBar configNavbar={configNavbar} />
      <CollectionsComponent />
    </div>
  );
};

export default Collections;
