import React from "react";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import MapIcon from "@mui/icons-material/Map";
import Divider from "@mui/material/Divider";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PersonIcon from "@mui/icons-material/Person";

import { WrapperImages, ScrollChild, HeadModal } from "./PowerSystemsElements";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setDataObjectDTImages } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { Btn } from "../ButtonElements";

import { Label, Wrapperbtn, WraperTitleCardMarker } from "./stylesPowerSystems";
import { PRIMARY_COLOR } from "./../../utils/const";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const CardMarker = () => {
  const dispatch = useDispatch();
  // Get state from redux
  const data = useSelector((state) => state.digitalTwinReducer.objectDataDT);
  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );
  // init component state
  const [openModal, setOpenModal] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [photosFile, setPhotosFile] = useState([]);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  // define functions to openModal and close modal
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  // define functions to handle photo upload
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setDataObjectDTImages([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setShowSaveBtn(true);
  };

  // define functions to handle save button
  const savePhotos = async () => {
    setShowSaveBtn(false);
    setLoading(true);
    photosFile.forEach(async (file) => {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", file);
      const urlPhoto = await fetch(
        `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
        {
          method: "POST",
          body: formData,
        }
      );
      const photo = await urlPhoto.json();
      const res = await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/object-photos`,
        {
          objectId: data.id,
          photoTypeId: 3,
          url: photo.urlFile,
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
    });
    setLoading(false);
    handleClose();
  };

  const navigate = useNavigate();

  const showDetailsOject = (event) => {
    // dispatch(setDateCollection({}));
    // navigate(`/imageGallery/${event.target.id}`);
  };

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 15);
  };

  return (
    <>
      {objectImages.length > 0 && (
        <CardMedia
          id={data.id}
          component="img"
          // height="140"
          image={objectImages[0]}
          alt="object photo"
          onClick={showDetailsOject}
          sx={{
            cursor: "pointer",
          }}
        />
      )}
      <CardContent>
        <WraperTitleCardMarker>
          <h1>
            <span>{data.typeObject.name} </span> OBJECT TYPE
          </h1>
          <div className="wraper-title-element">
            {data.typeObjectId === 2 ? (
              <>
                <h2>{data.typeObjectAgile.typeElementAgile.name} </h2>
                <img
                  width="30"
                  src={data.typeObjectAgile.typeElementAgile.urlIconColor}
                ></img>
              </>
            ) : null}
            {data.typeObjectId === 1 ? (
              <>
                <h2>{data.typeObjectGhg.typeElementGhg.name}</h2>
                <img
                  width="30"
                  src={data.typeObjectGhg.typeElementGhg.url_icon_color}
                ></img>
              </>
            ) : null}
          </div>
        </WraperTitleCardMarker>
        <Divider variant="inset" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <PersonIcon alt="Remy Sharp" fontSize="large" />
          </ListItemAvatar>
          <ListItemText
            primary="Created by:"
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {data.user.userTypeId === 1
                  ? `${data.user.adminCompany.firstName} ${data.user.adminCompany.firstLastName}`
                  : data.user.userTypeId === 2
                  ? `${data.user.operator.firstName} ${data.user.operator.firstLastName}`
                  : data.user.userTypeId === 3
                  ? `${data.user.adminDecimetrix.firstName} ${data.user.adminDecimetrix.firstLastName}`
                  : null}
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <DateRangeIcon alt="Remy Sharp" fontSize="large" />
          </ListItemAvatar>
          <ListItemText
            primary="Date:"
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {isoDateToString(data.date)}
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <MapIcon alt="Remy Sharp" fontSize="large" />
          </ListItemAvatar>
          <ListItemText
            primary="Coordinates:"
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {data.location.latitude}, {data.location.longitude}
              </Typography>
            }
          />
        </ListItem>
        <Divider variant="inset" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <ArtTrackIcon alt="Remy Sharp" fontSize="large" />
          </ListItemAvatar>
          <ListItemText
            primary="Processing State:"
            secondary={
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {data.isProcessed ? "Processed" : "Not Processed"}
              </Typography>
            }
          />
        </ListItem>
        <Typography gutterBottom variant="h5" component="div">
          Photos
        </Typography>
        <WrapperImages>
          {objectImages.map((photo, index) => (
            <ScrollChild key={index} alt="photo" src={photo} />
          ))}
        </WrapperImages>
        <Wrapperbtn>
          <Label htmlFor="files">
            <AddAPhotoIcon />
            <p>Add Picture</p>
          </Label>
          <input
            id="files"
            style={{ visibility: "hidden" }}
            accept="image/*"
            type="file"
            name="file"
            onChange={uploadFile}
          />
          {showSaveBtn && <Btn onClick={handleOpen}>Save images</Btn>}
        </Wrapperbtn>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              position: "relative",
              borderRadius: "10px",
            }}
          >
            <HeadModal>
              <img
                src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                alt=""
                className="neurona"
              />
              <span className="titleHeadModal">Add Picture</span>
            </HeadModal>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                marginTop: "50px",
              }}
            >
              Are you sure you want add this picture?
            </Typography>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
              color="primary"
              onClick={() => {
                setLoading(true);
                savePhotos();
              }}
              style={{
                backgroundColor: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: "#FFF",
              }}
            >
              Save
            </LoadingButton>
          </Box>
        </Modal>
      </CardContent>
    </>
  );
};

export default CardMarker;
