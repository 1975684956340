// Required modules
import axios from "axios";
import { format } from "date-fns/esm";

// Heafer to type regions table
export const headersTableTypeRegions = [
  "Region Id",
  "Region Type",
  "Region Description",
  "Region Created At",
];

// Key values to type regions table
export const keyValuesTableTypeRegions = [
  "id",
  "type",
  "description",
  "createdAt",
];

// Initial values with name and value
export const initialValuesFormRegionType = {
  type: {
    value: "",
    validate: "true",
    type: "text",
    label: "Type",
    placeholder: "Facility...",
    name: "type",
    legendError: "Type is required",
    regularExpression: /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ ]{1,50}$/,
  },
  description: {
    value: "",
    validate: "true",
    type: "text",
    label: "Description",
    placeholder: "Many objects...",
    name: "description",
  },
};

// Get current toekn
const getToken = () => {
  return localStorage.getItem("token");
};

// Create row to type region table
export const createRowsTypeRegion = (rows) => {
  return rows.map((row) => {
    const { createdAt } = row;
    const newCreatedAt = format(new Date(createdAt), "MMMM dd',' yyyy");
    return {
      id: row.id,
      type: row.type,
      description: row.description,
      createdAt: newCreatedAt,
    };
  });
};

// Click in type region into table
// Callback is to change state the form
// Callback(newValuesOfForm)
export const setFormRegionType = (typeRegion, callback, setButtons) => {
  let newValues = {};
  if (typeRegion) {
    const { description, type, id } = typeRegion;
    newValues = { description, type, id };
  }
  callback && callback(newValues);
  setButtons && setButtons([buttonUpdate, buttonDelete]);
};

const createBody = (values) => {
  const { description, type } = values;
  const body = {};
  if (type.value) body["type"] = type.value;
  if (description.value) body["description"] = description.value;
  return body;
};

// Create new type region
// Callback is to change the rows of table
// callback(type, newData)
const createTypeRegion = async (values, valid, callback) => {
  if (!valid) return;
  const body = createBody(values);
  const res = await axios.post(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/type-region`,
    body,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  const data = res.data;
  callback("create", data);
};

// Update type region
// Callback is to change the rows of table
// callback(type, updateData)
const updateTypeRegion = async (values, valid, callback) => {
  if (!valid) return;
  const {
    id: { value: id },
  } = values;
  const body = createBody(values);
  const res = await axios.put(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/type-region/${id}`,
    body,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  const data = res.data;
  callback("update", data);
};

// Delete type region
// Callback is to change the rows of table
// callback(type, deleteData)
const deleteTypeRegion = async (values, valid, callback) => {
  if (!valid) return;
  const {
    id: { value: id },
  } = values;
  const res = await axios.delete(
    `${process.env.REACT_APP_URL_BACKEND}api/v1/type-region/${id}`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  const data = res.data;
  data && callback("delete", id);
};

// Create button information
export const buttonCreate = {
  label: "Create",
  type: "create",
  handleClick: createTypeRegion,
};

// Create button information
export const buttonUpdate = {
  label: "Update",
  type: "update",
  handleClick: updateTypeRegion,
};

// Create button information
export const buttonDelete = {
  label: "Delete",
  type: "delete",
  handleClick: deleteTypeRegion,
};
