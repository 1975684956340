import { Provider } from "react-redux";
import { render } from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Styles } from "./styles.js";
import { SWRConfig } from "swr";
render(
  <Provider store={store}>
    <Styles />
    <SWRConfig
      value={{
        fetcher: (...args) => {
          const token = localStorage.getItem("token");
          return fetch(...args, {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }).then((response) => response.json());
        },
      }}
    >
      <App />
    </SWRConfig>
  </Provider>,
  document.getElementById("root")
);
