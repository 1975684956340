import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export const PieChart = ({ parameters }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Objects GHG Collected" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          <Doughnut
            data={{
              labels: parameters?.labels,
              datasets: [
                {
                  data: parameters?.data,
                  backgroundColor: [
                    "#0F4994",
                    "#31A636",
                    "#564994",
                    "#DF358B",
                    "#F28E02",
                    "#31A636",
                    "#007A72",
                    "#FABD3D",
                  ],
                  borderWidth: 8,
                  borderColor: "#FFFFFF",
                  hoverBorderColor: [
                    "#0F4994",
                    "#31A636",
                    "#564994",
                    "#DF358B",
                    "#F28E02",
                    "#31A636",
                    "#007A72",
                    "#FABD3D",
                  ],
                },
              ],
            }}
            options={{
              legend: {
                display: false,
                position: "bottom",
              },
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button color="primary" size="small">
            Decimetrix<sup>®</sup>
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
