import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import {
  FormContainer,
  WrapperForm,
  Form,
  Label,
  ContainerButtonCenter,
  Button,
  MessageError,
  MessageSuccess,
} from "../Forms/FormElements";

import InputComponent from "../Forms/Input";

const FormSignUp = ({ handleClose }) => {
  const [firstName, setFirstName] = useState({ field: "", valid: null });
  const [secondName, setSecondName] = useState({ field: "", valid: null });
  const [firstLastName, setFirstLastName] = useState({
    field: "",
    valid: null,
  });
  const [company, setCompany] = useState({ field: "", valid: null });
  const [email, setEmail] = useState({ field: "", valid: null });
  const [area, setArea] = useState({ field: "", valid: null });
  const [project, setProject] = useState({ field: "", valid: null });
  const [FormValidated, setFormValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const token = localStorage.getItem("token");
  const [messageError, setMessageError] = useState("");

  const expressions = {
    firstName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    firstLastName: /^[a-zA-ZÀ-ÿ]{1,40}$/, // letters only
    secondName: /^[a-zA-ZÀ-ÿ]{0,40}$/, // letters only
    company: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    area: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    project: /^[a-zA-Z0-9_.+-À-À-ÿ\s]{1,50}$/, //
    password: /^.{8,60}$/, // 8 a 60 characters.
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const saveUser = async (body) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: token ? `Bearer ${token}` : "",
          },
          body: JSON.stringify(body),
        }
      );
      await res.json();
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      firstName.valid === "true" &&
      firstLastName.valid === "true" &&
      company.valid === "true" &&
      email.valid === "true" &&
      area.valid === "true" &&
      project.valid === "true"
    ) {
      const body = {
        firstName: firstName.field,
        secondName: secondName.field,
        firstLastName: firstLastName.field,
        area: area.field,
        project: project.field,
        adminDecimetrixId: adminDecimetrixId,
        user: {
          email: email.field,
        },
        company: {
          name: company.field,
        },
      };

      const res = await saveUser(body);
      setLoading(false);
      if (res.status === 409) {
        setFormValidated(false);
        setMessageError("This email is already registered");
      } else if (res.status === 201) {
        handleClose();
        setFormValidated(true);
        setMessageError(null);
        setFirstName({ field: "", valid: null });
        setSecondName({ field: "", valid: null });
        setFirstLastName({ field: "", valid: null });
        setEmail({ field: "", valid: null });
        setArea({ field: "", valid: null });
        setProject({ field: "", valid: null });
      } else {
        setFormValidated(false);
        setMessageError("Fill all the fields correctly.");
      }
    } else {
      setFormValidated(false);
      setMessageError("Fill all the fields correctly.");
    }
    setLoading(false);
  };

  return (
    <FormContainer>
      <WrapperForm>
        <Form action="" onSubmit={onSubmit}>
          <InputComponent
            state={firstName}
            changeState={setFirstName}
            type="text"
            label="First Name *"
            name="firstName"
            legendError="The name can only contain letters."
            regularExpression={expressions.firstName}
          />
          <InputComponent
            state={secondName}
            changeState={setSecondName}
            type="text"
            label="Second Name"
            name="secondName"
            legendError="The name can only contain letters."
            regularExpression={expressions.secondName}
          />
          <InputComponent
            state={firstLastName}
            changeState={setFirstLastName}
            type="text"
            label="Last Name *"
            name="firstLastName"
            legendError="The last name can only contain letters."
            regularExpression={expressions.firstLastName}
          />
          <InputComponent
            state={company}
            changeState={setCompany}
            type="text"
            label="Company *"
            name="company"
            legendError="The company can only contain letters, numbers, periods, hyphens and underscore."
            regularExpression={expressions.company}
          />
          <InputComponent
            state={email}
            changeState={setEmail}
            type="email"
            label="E-mail *"
            placeholder="email@email.com"
            name="email"
            legendError="The email can only contain letters, numbers, periods, hyphens and underscore."
            regularExpression={expressions.email}
          />
          <InputComponent
            state={area}
            changeState={setArea}
            type="text"
            label="Area *"
            placeholder=""
            name="area"
            legendError="The area can only contain letters, numbers, periods, hyphens and underscore."
            regularExpression={expressions.area}
          />
          <InputComponent
            state={project}
            changeState={setProject}
            type="project"
            label="Project *"
            placeholder=""
            name="project"
            legendError="The area can only contain letters, numbers, periods, hyphens and underscore."
            regularExpression={expressions.project}
          />
          <ContainerButtonCenter>
            <Button type="submit">Register</Button>
            {loading && (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </ContainerButtonCenter>
        </Form>
        {FormValidated ? (
          <MessageSuccess>
            <p>User saved successfully</p>
          </MessageSuccess>
        ) : messageError !== "" ? (
          <MessageError>
            <p>{messageError}</p>
          </MessageError>
        ) : null}
      </WrapperForm>
    </FormContainer>
  );
};

export default FormSignUp;
