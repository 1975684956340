import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AiFillCloseCircle } from "react-icons/ai";
import { HeadModal } from "./UserRegisterElements";

import SignUp from "./FormSignUp";

export default function FormDialog({ open, handleClose }) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <HeadModal>
            <div className="title">
              <img
                src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                alt=""
                className="neurona"
              />
              <span className="titleHeadModal">Admin Company Register</span>
            </div>
            <Button onClick={handleClose}>
              <AiFillCloseCircle style={{ color: "#FFF", fontSize: 21 }} />
            </Button>
          </HeadModal>
        </DialogTitle>
        <DialogContent
          style={{
            marginTop: "25px",
          }}
        >
          <SignUp handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
