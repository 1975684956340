import { CircularProgress } from "@mui/material";
import { ContentButton } from "./CustomButtonStyle";

const CustomButton = ({ text, onClick, isLoad }) => {
  return (
    <ContentButton>
      <button onClick={onClick}>
        {isLoad && (
          <CircularProgress
            style={{
              width: "20px",
              height: "20px",
              color: "#f9f9f9",
              marginRight: "5px",
            }}
          />
        )}
        {text}
      </button>
    </ContentButton>
  );
};

export default CustomButton;
