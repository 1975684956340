import AdminComponent from "../../components/AdminDecimetrix/index";
import NavBar from "../../components/AdminDecimetrix/NavBar";

const Admin = () => {
  return (
    <>
      <NavBar linkLogo={""} />
      <AdminComponent />
    </>
  );
};

export default Admin;
