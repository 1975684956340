// Components
import NavBar from "components/Admin/NavBar";
import Dashboard from "components/Admin/Dashboard/index";

function dashboard() {
  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <Dashboard />
    </div>
  );
}

export default dashboard;
