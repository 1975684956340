import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { PRIMARY_COLOR } from "../../utils/const";

export const NavbarLogoR = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Nav = styled.nav`
  width: ${(props) => (props.tipo ? "90%" : "100%")};
  margin-left: ${(props) => (props.tipo ? "10%" : "0%")};
`;

export const Img = styled.img`
  width: 160px;
  height: auto;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const DeleteUserButton = styled.div`
  outline: none;
  border: none;
  padding-left: 15px;
`;

export const NavInfo = styled.div`
  margin-right: ${(props) => (props.tipo ? "170px" : "0%")};
`;

export const NavBtnLink = styled.button`
  border-radius: 50px;
  background: #222729;
  padding: 10px 22px;
  color: #fff;
  font-size: 1.6rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #222729;
  }
`;
