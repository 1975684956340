import Grid from "@mui/material/Grid";
import Map, { Marker, NavigationControl } from "react-map-gl";
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapCard } from "./PowerSystemsElements";
import * as turf from "@turf/turf";
import DrawControl from "./DrawControl";
import Lottie from "react-lottie";
import MAP from "../../lotties/MAP-2.json";

import {
  showCardMakerDigitalTwin,
  setDataObjectDT,
  setDataObjectDTImages,
  showDrawerObjectDt,
} from "../../redux/actions";

import { NamePlateMarker, CustomSelect } from "./stylesPowerSystems";

import mapboxgl from "mapbox-gl";
import axios from "axios";
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const DigitalTwinOperator = () => {
  // get customerId from localStorage
  const customerId = localStorage.getItem("customerId");
  const [objects, setObjects] = useState();
  const [error, setError] = useState();
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const locationsCSV = useSelector(
    (state) => state.locationCSVReducer.locationsCSV
  );
  const showMarkersDT = useSelector(
    (state) => state.digitalTwinReducer.showMarkersDigitalTwin
  );

  const [baseMap, setBaseMap] = useState("streets-v11");
  const [centerLocations, setCenterLocations] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/customer/${customerId}`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
        const res = await response.data;
        setObjects(res);
        const center = calculateCenter(res);
        setCenterLocations({
          latitude: center[0],
          longitude: center[1],
        });
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, [customerId]);

  const showLocationCSV = () => {
    return locationsCSV.list.map((location, index) => {
      return (
        <div key={index}>
          {showMarkersDT && (
            <Marker
              latitude={Number(location.latitude)}
              longitude={Number(location.longitude)}
            >
              <NamePlateMarker id={location.id}>
                <img
                  id={location.id}
                  src="https://iconosgreendragon.s3.us-east-2.amazonaws.com/Icono-Flare.png"
                  alt="nameplate marker"
                ></img>
              </NamePlateMarker>
            </Marker>
          )}
        </div>
      );
    });
  };

  // get JsonByTypeElementId from localStorage
  const dataJsonByTypeElementId = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByTypeElementId
  );
  // get JsonByActivityName
  const dataJsonByActivityName = useSelector(
    (state) => state.digitalTwinReducer.showMarkersByActivities
  );

  const iteratorLocation = () => {
    return objects.map((object, index) => {
      return (
        <div key={index}>
          {showMarkersDT &&
            dataJsonByTypeElementId[object.typeElement.id] &&
            dataJsonByActivityName[object.typeElement.activity.name] && (
              <Marker
                latitude={Number(object.location.latitude)}
                longitude={Number(object.location.longitude)}
              >
                <NamePlateMarker id={object.id} onClick={handleClickMarker}>
                  <img
                    id={object.id}
                    src={object.typeElement.icon}
                    alt="nameplate marker"
                  ></img>
                </NamePlateMarker>
              </Marker>
            )}
        </div>
      );
    });
  };

  const [features, setFeatures] = useState({});

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const calculateCenter = (allObjects) => {
    const locations = [];
    allObjects.map((object, index) => {
      const location = [
        Number(object.location.latitude),
        Number(object.location.longitude),
      ];
      locations.push(location);
    });
    var features = turf.points(locations);
    const center = turf.center(features);
    return center.geometry.coordinates;
  };

  const changeBaseMap = (event) => {
    setBaseMap(event.target.value);
  };

  const isEmptyObject = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object;
  };

  const handleClickMarker = async (event) => {
    dispatch(showDrawerObjectDt(true));
    const objectMarker = await fetch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${event.target.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const dataMarker = await objectMarker.json();
    dispatch(setDataObjectDT(dataMarker));
    dispatch(
      setDataObjectDTImages(
        dataMarker.objectPhotos.map((image) => {
          return image.url;
        })
      )
    );
    dispatch(showCardMakerDigitalTwin(true));
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: MAP,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={9}>
          <MapCard>
            <CustomSelect>
              <select id="customer" name="customer" onChange={changeBaseMap}>
                <option value="satellite-v9">Satellite</option>
                <option value="light-v10">Light</option>
                <option value="dark-v10">Dark</option>
                <option selected value="streets-v11">
                  Streets
                </option>
              </select>
            </CustomSelect>

            {objects && objects.length > 0 ? (
              !isEmptyObject(centerLocations) && (
                <Map
                  mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                  initialViewState={{
                    latitude: centerLocations.latitude,
                    longitude: centerLocations.longitude,
                    width: "100%",
                    height: "70vh",
                    zoom: 16,
                  }}
                  style={{ width: "100%", height: "100vh", margin: "auto" }}
                  maxZoom={60}
                  mapStyle={`mapbox://styles/mapbox/${baseMap}`}
                >
                  <NavigationControl style={navControlStyle} />
                  {error ? (
                    <div>Internal Error</div>
                  ) : (
                    objects && objects.length > 0 && <>{iteratorLocation()} </>
                  )}
                  {/* {
                objects && objects.length > 0 && <>{setObjects()} </>
              } */}
                  {Object.keys(locationsCSV).length !== 0
                    ? showLocationCSV()
                    : null}
                  <DrawControl
                    position="top-right"
                    displayControlsDefault={false}
                    userProperties={true}
                    controls={{
                      polygon: true,
                      line_string: true,
                      point: true,
                      trash: true,
                    }}
                    onCreate={onUpdate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                </Map>
              )
            ) : (
              <div>
                <Lottie options={defaultOptions} height={700} width={700} />
              </div>
            )}
          </MapCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DigitalTwinOperator;
