import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useGet from "./../../../hooks/useFetch";
import { useState } from "react";

export default function OperatorSelect() {
  const adminId = localStorage.getItem("adminId");
  const [operator, setOperator] = useState("");
  const token = localStorage.getItem("token");
  const [operators, errorOperators] = useGet(
    `api/v1/admin/customers/${adminId}`
  );

  const handleChange = (event) => {
    setOperator(event.target.value);
  };

  const operatorList = () => {
    return operators.customers.map((object) => {
      return (
        <MenuItem key={object.id} value={object.id}>
          {object.name} {object.lastName}
        </MenuItem>
      );
    });
  };

  return (
    <Box sx={{ minWidth: 500 }}>
      <FormControl sx={{ minWidth: 317 }}>
        <InputLabel id="demo-simple-select-label">User</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={operator}
          label="Operator"
          onChange={handleChange}
        >
          {operators && operatorList()}
        </Select>
      </FormControl>
    </Box>
  );
}
