import Typography from "@mui/material/Typography";
// check layer
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import { useStyles, CustomizedAccordion } from "./MapDrawerStyle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// custom style
import {
  WrapperTypeObjectTitle,
  WrapperTypeElementTitle,
} from "./AccordionsElements";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  showMarkerseByTypeObject,
  showMarkerseByTypeElement,
  showMarkerByElementWithMedia,
  setShowEvents,
} from "redux/actions/admin";
import { useEffect } from "react";

const deleteDuplicateObjects = (objects) => {
  return objects.filter(
    (object, index, self) =>
      index === self.findIndex((t) => t.name === object.name)
  );
};

const getTypeElementsNames = (dataObjects) => {
  const typeElementsNames = [];
  dataObjects.map((object) => {
    typeElementsNames.push(
      object.typeObjectId === 1
        ? object.typeObjectGhg?.typeElementGhg.name
        : object.typeObjectId === 2
        ? object.typeObjectAgile?.typeElementAgile.name
        : object.typeObjectId === 3
        ? object.typeObjectWell?.typeElementWell.name
        : object.typeObjectId === 4
        ? object.typeObjectFacility?.typeElementFacility.name
        : null
    );
  });
  return [...new Set(typeElementsNames)];
};

const capitalizeFirstLetter = (string) => {
  if (string === null) {
    return "";
  } else if (string === undefined) {
    return "";
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const AccordionDigitalTwin = ({ dataObjects }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const typeObjectsNames = dataObjects.map((object) => object.typeObject.name);
  let showElementWitheMedia = useSelector(
    (state) => state.digitalTwinReducer.showMarkerByElementWithMedia
  );
  // filter events
  let showAllEvents = useSelector((state) => state.adminReducer.showEvents);

  // filter duplicate typeObjectsNames
  const uniqueTypeObjectsNames = [...new Set(typeObjectsNames)];

  useEffect(() => {
    const showTypeObject = {};
    uniqueTypeObjectsNames.map((typeObjectName) => {
      showTypeObject[typeObjectName] = true;
    });
    dispatch(showMarkerseByTypeObject({ ...showTypeObject }));

    const showTypeElement = {};
    const typeElementsNames = getTypeElementsNames(dataObjects);
    typeElementsNames.map((typeElementName) => {
      showTypeElement[typeElementName] = true;
    });
    dispatch(showMarkerseByTypeElement({ ...showTypeElement }));
  }, [dataObjects]);

  const showTypeObject = useSelector(
    (state) => state.adminReducer.markerByTypeObject
  );

  const handlerClickFilterTypeObject = (typeObjectName) => {
    showTypeObject[typeObjectName] = !showTypeObject[typeObjectName];
    dispatch(showMarkerseByTypeObject({ ...showTypeObject }));
  };

  const showTypeElement = useSelector(
    (state) => state.adminReducer.markerByTypeElement
  );

  const handlerClickFilterTypeElement = (typeElementName) => {
    showTypeElement[typeElementName] = !showTypeElement[typeElementName];
    dispatch(showMarkerseByTypeElement({ ...showTypeElement }));
  };

  const accordionDetails = uniqueTypeObjectsNames.map(
    (typeObjectName, index) => {
      let typeElements = null;
      // filter typeElement by typeObjectName
      const objects = dataObjects.filter(
        (object) => object.typeObject.name === typeObjectName
      );

      if (typeObjectName === "GHG") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectGhg?.typeElementGhg.name,
            icon: object.typeObjectGhg?.typeElementGhg.url_icon_color,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }

      if (typeObjectName === "AGILE") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectAgile?.typeElementAgile.name,
            icon: object.typeObjectAgile?.typeElementAgile.urlIconColor,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }
      if (typeObjectName === "WELL") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectWell?.typeElementWell.name,
            icon: object.typeObjectWell?.typeElementWell.url_icon_color,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }
      if (typeObjectName === "FACILITIES") {
        const typesElementNames = objects.map((object) => {
          return {
            name: object.typeObjectFacility?.typeElementFacility.name,
            icon: object.typeObjectFacility?.typeElementFacility.urlIconColor,
          };
        });
        const uniqueTypesElementNames =
          deleteDuplicateObjects(typesElementNames);
        typeElements = uniqueTypesElementNames;
      }

      return (
        <div key={index}>
          <Accordion
            sx={{
              paddingLeft: "5px",
              border: "none",
              boxShadow: "none",
            }}
            defaultExpanded={false}
          >
            <WrapperTypeObjectTitle>
              <div className="icon-title">
                {showTypeObject[typeObjectName] ? (
                  <VisibilityIcon
                    sx={{
                      color: "#272727",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlerClickFilterTypeObject(typeObjectName)}
                  />
                ) : (
                  <VisibilityOffIcon
                    sx={{
                      color: "#575757",
                      margin: "0px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handlerClickFilterTypeObject(typeObjectName)}
                  />
                )}
                {typeObjectName === "GHG" && <p>Objects {typeObjectName}</p>}
                {typeObjectName !== "GHG" && (
                  <p>Objects {typeObjectName.toLowerCase()}</p>
                )}
              </div>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              ></AccordionSummary>
            </WrapperTypeObjectTitle>
            {typeElements && (
              <AccordionDetails>
                {typeElements.map((element, index) => {
                  return (
                    <FormGroup key={index}>
                      <WrapperTypeElementTitle>
                        <div className="icon-title">
                          {showTypeElement[element.name] ? (
                            <VisibilityIcon
                              sx={{
                                color: "#575757",
                                margin: "0px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handlerClickFilterTypeElement(element.name)
                              }
                            />
                          ) : (
                            <VisibilityOffIcon
                              sx={{
                                color: "#575757",
                                margin: "0px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handlerClickFilterTypeElement(element.name)
                              }
                            />
                          )}
                          <p>
                            {capitalizeFirstLetter(
                              element.name?.toLowerCase()
                            ).substring(0, 20)}
                          </p>
                        </div>
                        <img
                          src={element.icon}
                          width="30px"
                          height="30px"
                          alt="ico"
                        />
                      </WrapperTypeElementTitle>
                    </FormGroup>
                  );
                })}
              </AccordionDetails>
            )}
          </Accordion>
        </div>
      );
    }
  );

  const handlerClickFilterMedia = () => {
    showElementWitheMedia = !showElementWitheMedia;
    dispatch(showMarkerByElementWithMedia(showElementWitheMedia));
  };

  const handlerClickFilterEvents = () => {
    showAllEvents = !showAllEvents;
    dispatch(setShowEvents(showAllEvents));
  };

  return (
    <>
      <CustomizedAccordion
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ fontSize: "1.5rem" }}>Digital Twin</Typography>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}></AccordionSummary>
        </div>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showAllEvents ? (
              <VisibilityOffIcon
                sx={{
                  color: "#575757",
                  margin: "10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickFilterEvents}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  color: "#272727",
                  margin: "10px 10px 10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickFilterEvents}
              />
            )}
            <p>Events</p>
          </div>
        </WrapperTypeObjectTitle>
        <WrapperTypeObjectTitle>
          <div className="icon-title">
            {showElementWitheMedia ? (
              <VisibilityOffIcon
                sx={{
                  color: "#575757",
                  margin: "10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickFilterMedia}
              />
            ) : (
              <VisibilityIcon
                sx={{
                  color: "#272727",
                  margin: "10px 10px 10px 15px",
                  cursor: "pointer",
                }}
                onClick={handlerClickFilterMedia}
              />
            )}
            <p>Element with Media</p>
          </div>
        </WrapperTypeObjectTitle>
        {accordionDetails}
      </CustomizedAccordion>
    </>
  );
};

export default AccordionDigitalTwin;
