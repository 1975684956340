import Grid from "@mui/material/Grid";
import Map, { Source, Layer, NavigationControl } from "react-map-gl";
import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MapCard } from "./UserRoutesElements";
import DrawControl from "./DrawControl";
import * as turf from "@turf/turf";

import {
  showDrawerUserRoutes,
  setTypeJob,
  setCoordinatesUserRoute,
  setFeaturesDetails,
} from "./../../../redux/actions/admin";

import { CustomSelect } from "./userRoutes";

import { StyleDrawControl } from "./styleDrawControl";
// import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
// mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapRoutes = () => {
  const [baseMap, setBaseMap] = useState("streets-v11");
  const [centerLocations, setCenterLocations] = useState({
    latitude: 35.091,
    longitude: -106.6527,
  });

  const typeJob = useSelector((state) => state.adminReducer.typeJob);

  const [features, setFeatures] = useState({});
  const [points, setPoints] = useState([]);

  const [featuresCollection, setFeaturesCollection] = useState([]);
  const [pointsLine, setPointsLine] = useState([]);

  const dispatch = useDispatch();

  const onMouseMove = useCallback((e) => {
    const coordinates = e.lngLat;
    dispatch(setCoordinatesUserRoute(coordinates));
  }, []);

  const onCreate = useCallback((e) => {
    dispatch(showDrawerUserRoutes(true));
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
        dispatch(setFeaturesDetails(f));
      }
      return newFeatures;
    });
  }, []);

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  const onSelect = useCallback((e) => {
    for (const f of e.features) {
      dispatch(setFeaturesDetails(f));      
    }
  });

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const changeBaseMap = (event) => {
    setBaseMap(event.target.value);
  };

  const handleClick = (event) => {
    console.log("🚀 ~ file: Map.jsx ~ line 59 ~ handleClick ~ event", event);
  };

  const HandlerClickAddJob = (e) => {
    switch (typeJob) {
      case "point":
        dispatch(setTypeJob(null));
        setPoints(() => {
          const newPoints = [...points];
          newPoints.push(turf.point([e.lngLat.lng, e.lngLat.lat]));
          return newPoints;
        });
        break;
      case "route":
        setFeaturesCollection(() => {
          const newFeaturesCollection = [...featuresCollection];
          // filter point from featuresCollection
          const newFeaturesCollectionPoint = newFeaturesCollection.filter(
            (f) => f.geometry.type === "Point"
          );
          newFeaturesCollectionPoint.push(
            turf.point([e.lngLat.lng, e.lngLat.lat])
          );
          setPointsLine(() => {
            const newPoints = [...pointsLine];
            newPoints.push([e.lngLat.lng, e.lngLat.lat]);
            if (newFeaturesCollectionPoint.length > 1) {
              newFeaturesCollectionPoint.push(turf.lineString(newPoints));
            }
            return newPoints;
          });
          return newFeaturesCollectionPoint;
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={9}>
          <MapCard>
            <CustomSelect>
              <select
                id="customer"
                name="customer"
                onChange={changeBaseMap}
                defaultValue="streets-v11"
              >
                <option value="satellite-v9">Satellite</option>
                <option value="light-v10">Light</option>
                <option value="dark-v10">Dark</option>
                <option selected value="streets-v11">
                  Streets
                </option>
              </select>
            </CustomSelect>
            <Map
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              initialViewState={{
                latitude: centerLocations.latitude,
                longitude: centerLocations.longitude,
                width: "100%",
                height: "70vh",
                zoom: 15,
              }}
              style={{ width: "100%", height: "100vh", margin: "auto" }}
              maxZoom={60}
              mapStyle={`mapbox://styles/mapbox/${baseMap}`}
            >
              <NavigationControl style={navControlStyle} />
              <DrawControl
                position="top-right"
                displayControlsDefault={false}
                userProperties={true}
                styles={StyleDrawControl}
                controls={{
                  polygon: true,
                  line_string: true,
                  point: true,
                  trash: true,
                }}
                // change tooltip
                onCreate={onCreate}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onMouseMove={onMouseMove}
                onSelect={onSelect}
              />
              <Source
                id="point"
                type="geojson"
                cluster={true}
                data={turf.featureCollection(points)}
              >
                <Layer
                  id="add-point"
                  type="circle"
                  paint={{
                    "circle-radius": 8,
                    "circle-color": "#00957f",
                    "circle-opacity": 0.8,
                  }}
                />
              </Source>
              <Source
                id="line"
                type="geojson"
                data={turf.featureCollection(featuresCollection)}
              >
                <Layer
                  id="points-line"
                  type="circle"
                  paint={{
                    "circle-radius": 5,
                    "circle-color": "#000",
                    "circle-opacity": 0.8,
                  }}
                />
                <Layer
                  id="lines"
                  type="line"
                  paint={{
                    "line-color": "#17D479",
                    "line-opacity": 0.8,
                    "line-width": 5,
                  }}
                />
              </Source>
            </Map>
          </MapCard>
        </Grid>
      </Grid>
    </>
  );
};

export default MapRoutes;
