import { ContainerOperations } from "./OperationDetailsElements";
import {
  WraperTitleCardMarker,
  InfoTitle,
  Wrapperbtn,
  Label,
  LabelRotatePhoto,
} from "./ImageDetailsElements";
import useSwr from "swr";
import { useEffect, useState } from "react";
import MapView from "components/MapView";
import PhotoGallery from "./PhotoGallery";
import {
  ButtonDeleteElement,
  ContentButton,
  ContentButtonGallery,
} from "./ObjectDetailsElements";
import { HeadModal } from "../Dialogs/dialogsStyles";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import OpenDialog from "./../Dialogs/OpenDialog";
import ListEventsTable from "./ListEventsTable";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { useSWRConfig } from "swr";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Modal from "@mui/material/Modal";
import { setDataObjectDTImages, setShowOperationDetails } from "redux/actions";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@material-ui/core/Grid";
import { PRIMARY_COLOR } from "utils/const";
import { AiFillCloseCircle } from "react-icons/ai";
import { Button } from "@mui/material";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { parse } from "date-fns";
import { format } from "date-fns/esm";
import DialogContent from "@mui/material/DialogContent";

const ObjectDetails = ({ objectId }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const styleRotateModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
  };

  const dispatch = useDispatch();
  const objectImages = useSelector(
    (state) => state.digitalTwinReducer.objectDataDTImages
  );
  // Get state from redux
  const [PPM, setPPM] = useState();

  const [grH, setGrH] = useState();
  const [ltMin, setLtMin] = useState();
  const [equipmentPhoto, setEquipmentPhoto] = useState(null);
  const [namePlatePhoto, setNamePlatePhoto] = useState(null);
  const [equipmentPhotos, setEquipmentPhotos] = useState();
  const [namePlatePhotos, setNamePlatePhotos] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openModalRotatePhoto, setOpenModalRotatePhoto] = useState(false);
  const [openModalRotatePhotoNameplate, setOpenModalRotatePhotoNameplate] =
    useState(false);
  const [rotation, setRotation] = useState(0);
  const [amountEvents, setAmountEvents] = useState();
  const [seeEventsTable, setSeeEventsTable] = useState(false);
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [typePicture, setTypePicture] = useState("1");

  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "Are you sure you want to delete this element?",
    disagree: "Cancel",
    agree: "Accept",
  });
  const [photosFile, setPhotosFile] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [showDeleteButton, setShowDeleteButton] = useState(true);
  const token = localStorage.getItem("token");

  const { mutate } = useSWRConfig();

  const fetcher = (...args) =>
    fetch(...args, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => response.json());

  const urlData = `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${objectId}`;
  const { data: object, error: errorObject } = useSwr(urlData, fetcher);

  const handleClose = () => setOpenModal(false);

  const handleCloseModalRotatePhoto = () => {
    setOpenModalRotatePhoto(false);
    setRotation(0);
  };

  const handleCloseModalRotatePhotoNameplate = () => {
    setOpenModalRotatePhotoNameplate(false);
    setRotation(0);
  };
  const decodeToken = jwt_decode(token);

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    setPhotosFile([...photosFile, file]);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      dispatch(setDataObjectDTImages([reader.result, ...objectImages]));
      setPhotos([reader.result, ...photos]);
    };
    setOpenModal(true);
  };

  // define functions to handle save button
  const savePhotos = async () => {
    setLoading(true);
    Promise.all(
      photosFile.map(async (file) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("file", file);
        const urlPhoto = await fetch(
          `${process.env.REACT_APP_URL_OCR_API}uploadFile`,
          {
            method: "POST",
            body: formData,
          }
        );
        const photo = await urlPhoto.json();
        await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/object-photos`,
          {
            objectId: objectId,
            photoTypeId: typePicture,
            url: photo.urlFile,
          },
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      })
    ).then(() => {
      setLoading(false);
      handleClose();
      setPhotosFile([]);
      mutate(urlData);
    });
  };

  useEffect(() => {
    const equipementPhotosArray = [];
    const nameplatePhotosArray = [];
    object &&
      !errorObject &&
      object.objectPhotos.forEach((objectImage) => {
        if (objectImage.photoTypeId === 1) {
          setEquipmentPhoto(objectImage);
          equipementPhotosArray.push(objectImage);
        }

        if (objectImage.photoTypeId === 2) {
          setNamePlatePhoto(objectImage);
          nameplatePhotosArray.push(objectImage);
        }
      });
    setEquipmentPhotos(equipementPhotosArray);
    setNamePlatePhotos(nameplatePhotosArray);
  }, [object, errorObject]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/object-ghg/operations/${objectId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const res = result.data.objectsOperations;

      const mayorId = [];
      res.forEach((item) => {
        mayorId.push(item.operations[0].id);
      });
      const max = Math.max(...mayorId);
      const index = mayorId.indexOf(max);

      if (res[index].operations[0].leak !== null) {
        setPPM(res[index].operations[0].leak.ppm);
        setGrH(res[index].operations[0].leak.grH);
        setLtMin(res[index].operations[0].leak.ltMin);
      }
      if (res[index].operations[0].Reparation !== null) {
        setPPM(res[index].operations[0].Reparation.ppm);
        setGrH(res[index].operations[0].Reparation.grH);
        setLtMin(res[index].operations[0].Reparation.ltMin);
      }
      if (res[index].operations[0].inspectionFlir !== null) {
        setPPM(res[index].operations[0].inspectionFlir.ppm);
        setGrH(res[index].operations[0].inspectionFlir.grH);
        setLtMin(res[index].operations[0].inspectionFlir.ltMin);
      }
      if (res[index].operations[0].inspectionSniffer !== null) {
        setPPM(res[index].operations[0].inspectionSniffer.ppm);
        setGrH(res[index].operations[0].inspectionSniffer.grH);
        setLtMin(res[index].operations[0].inspectionSniffer.ltMin);
      }
    };

    fetchData();
    object &&
      object.typeObjectId === 1 &&
      axios
        .get(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/object-ghg/count/object/${objectId}`,
          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        )
        .then((response) => {
          const amount = response.data;
          setAmountEvents(amount);
          if (
            amount.inspectionSniffer !== 0 ||
            amount.inspectionFlir !== 0 ||
            amount.leaks !== 0 ||
            amount.reperations !== 0
          ) {
            setShowDeleteButton(false);
          }
        })
        .catch((err) => console.error(err));
  }, [object, objectId, token]);

  const handleDelete = () => {
    setOpenDialog(true);
  };

  const deleteElement = async () => {
    setContentDialog({ ...contentDialog, agree: <CircularProgress /> });
    const result = await axios.delete(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${object.id}`,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    if (result) {
      mutate(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/admin-company/objects/${adminCompanyId}`
      );
    }
    setContentDialog({ ...contentDialog, agree: "Accept" });
    setOpenDialog(false);
    dispatch(setShowOperationDetails(false));
  };

  const rotateImageLeft = () => {
    setRotation(rotation - 90);
  };

  const rotateImageRight = () => {
    setRotation(rotation + 90);
  };

  const setObjectHandler = (e) => {
    setTypePicture(e.target.value);
  };

  const isoDateToString = (isoDate) => {
    const date = new Date(isoDate);
    return date.toString().substring(0, 15);
  };

  return (
    <>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={deleteElement}
        content={contentDialog}
      />
      {object && (
        <ContainerOperations>
          {/* --OBJECT DETAILS */}
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <div className="info-operation">
              <WraperTitleCardMarker>
                <h1>
                  <span>{object.typeObject.name} </span> OBJECT TYPE
                </h1>
                <div className="wraper-title-element">
                  {object.typeObjectId === 2 ? (
                    <>
                      <h2>{object.typeObjectAgile.typeElementAgile.name} </h2>
                      <img
                        width="30"
                        src={
                          object.typeObjectAgile.typeElementAgile.urlIconColor
                        }
                        alt="object-agile-icon"
                      ></img>
                    </>
                  ) : null}
                  {object.typeObjectId === 1 ? (
                    <>
                      <h2>{object.typeObjectGhg.typeElementGhg.name}</h2>
                      <img
                        width="30"
                        src={object.typeObjectGhg.typeElementGhg.url_icon_color}
                        alt="object-ghg-icon"
                      ></img>
                    </>
                  ) : null}
                </div>
              </WraperTitleCardMarker>
              <InfoTitle>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <section className="main-tables-container">
                    <div className="main-currency-table">
                      <div className="currency-table--container">
                        <table>
                          <tr>
                            <td className="table__top-left">Leak</td>
                            <td
                              style={
                                PPM >= 500
                                  ? // grhValue >= 14487.85 ||
                                    // ltMinValue >= 336.77
                                    { backgroundColor: "#ed3939" }
                                  : {}
                              }
                              class="table__top-right table__right"
                            >
                              {PPM >= 500 || grH >= 14487.85 || ltMin >= 336.77
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>ppm-meter</td>
                            <td class="table__right">
                              {PPM === undefined || PPM === null ? "-" : PPM}
                            </td>
                          </tr>
                          <tr>
                            <td>gram / hour</td>
                            <td className="table__right">
                              {grH === undefined || grH === null ? "-" : grH}
                            </td>
                          </tr>
                          <tr>
                            <td>litre / minute</td>
                            <td className="table__right">
                              {ltMin === undefined || ltMin === null
                                ? "-"
                                : ltMin}
                            </td>
                          </tr>
                          <tr>
                            <td>Source</td>
                            <td className="table__right">
                              {object.dataSourceId === 1 ? (
                                <p>Mobile</p>
                              ) : object.dataSourceId === 2 ? (
                                <p>Desktop</p>
                              ) : object.dataSourceId === 3 ? (
                                <p>CSV File</p>
                              ) : null}
                            </td>
                          </tr>
                          <tr>
                            <td>Created by</td>
                            <td className="table__right">
                              {" "}
                              {object.user.operator?.firstName}{" "}
                              {object.user.operator?.firstLastName}
                              {object.user.adminCompany?.firstName}{" "}
                              {object.user.adminCompany?.firstLastName}
                            </td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td className="table__right">
                              {isoDateToString(object.date)}
                            </td>
                          </tr>
                          <tr>
                            <td>Longitude</td>
                            <td className="table__right">
                              {parseFloat(object.location.longitude).toFixed(5)}
                            </td>
                          </tr>
                          <tr>
                            <td>Latitude</td>
                            <td className="table__right">
                              {parseFloat(object.location.latitude).toFixed(5)}
                            </td>
                          </tr>
                          <tr>
                            <td>Object ID</td>
                            <td className="table__right">{object.id}</td>
                          </tr>
                          {amountEvents && object.typeObjectId === 1 && (
                            <tr>
                              <td className="table__bottom-left">
                                Object GHG ID
                              </td>
                              <td className="table__bottom-right table__right">
                                {object.typeObjectGhg.id}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                    {amountEvents && object.typeObjectId === 1 && (
                      <div className="main-currency-table">
                        <p className="currency-table--title">Events</p>
                        <div className="currency-table--container">
                          <table>
                            <tr>
                              <td className="table__top-left">Sniffer</td>
                              <td className="table__top-right table__right">
                                {amountEvents.inspectionSniffer}
                              </td>
                            </tr>
                            <tr>
                              <td>FLIR</td>
                              <td className="table__right">
                                {amountEvents.inspectionFlir}
                              </td>
                            </tr>
                            <tr>
                              <td>Leaks</td>
                              <td className="table__right">
                                {amountEvents.leaks}
                              </td>
                            </tr>
                            <tr>
                              <td className="table__bottom-left">Repairs</td>
                              <td className="table__bottom-right table__right">
                                {amountEvents.reperations}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    )}
                  </section>
                </Grid>
              </InfoTitle>
              {(decodeToken?.role === "COMPANY ADMIN" ||
                decodeToken?.sub === object?.userId) &&
                showDeleteButton && (
                  <Grid className="wrapper-buttons">
                    <Grid item xs={8} md={8} lg={12} xl={12}>
                      <Wrapperbtn>
                        <ButtonDeleteElement onClick={handleDelete}>
                          <DeleteIcon />
                          Delete
                        </ButtonDeleteElement>
                      </Wrapperbtn>
                    </Grid>
                  </Grid>
                )}
            </div>
          </Grid>

          {/* --GHG GALLERY PHOTOS-- */}
          <Grid item xs={12} md={12} lg={8} xl={8}>
            <div className="photo-container-image">
              <h1 className="operation-title">
                <span>GALLERY</span>
              </h1>
              <div
                className="gallery"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "0px 24px 0px 20px",
                }}
              >
                {object.objectPhotos.length > 0 && (
                  <>
                    <p style={{ fontSize: "1.6rem" }}>
                      <b>Total Pictures: </b> {object.objectPhotos.length}
                    </p>
                    <br />
                    <PhotoGallery photos={object.objectPhotos} />
                  </>
                )}
                <ContentButtonGallery>
                  {/* <Wrapperbtn> */}
                  <Label htmlFor="files">
                    <AddAPhotoIcon />
                    <p>Add Picture</p>
                  </Label>
                  <input
                    id="files"
                    style={{ visibility: "hidden" }}
                    accept="image/*"
                    type="file"
                    name="file"
                    onChange={uploadFile}
                  />
                  {/* </Wrapperbtn> */}
                </ContentButtonGallery>
                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    style={{
                      position: "relative",
                      borderRadius: "10px",
                    }}
                  >
                    <HeadModal>
                      <div className="title">
                        <img
                          src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                          alt=""
                          className="neurona"
                        />
                        <span className="titleHeadModal">Add Picture</span>
                      </div>
                      <Button onClick={handleClose}>
                        <AiFillCloseCircle
                          style={{
                            color: "#FFF",
                            fontSize: 21,
                            marginBottom: -4,
                          }}
                        />
                      </Button>
                    </HeadModal>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{
                        marginTop: "50px",
                      }}
                    >
                      Please select the type of picture to upload:
                      <br />
                      <div>
                        <select
                          defaultValue={typePicture}
                          onChange={setObjectHandler}
                          style={{
                            backgroundColor: PRIMARY_COLOR,
                            borderColor: PRIMARY_COLOR,
                            color: "#FFF",
                            padding: "5px 15px",
                            margin: "11px 11px 11px 15px",
                          }}
                        >
                          <option selected value="1">
                            Equipment
                          </option>
                          <option value="2">Nameplate</option>
                          <option value="3">General</option>
                        </select>
                      </div>
                    </Typography>
                    <LoadingButton
                      sx={{
                        margin: "11px",
                      }}
                      loadingPosition="start"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      style={{
                        backgroundColor: PRIMARY_COLOR,
                        borderColor: PRIMARY_COLOR,
                        color: "#FFF",
                      }}
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setLoading(true);
                        savePhotos();
                      }}
                      style={{
                        backgroundColor: PRIMARY_COLOR,
                        borderColor: PRIMARY_COLOR,
                        color: "#FFF",
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </Modal>
              </div>
            </div>
          </Grid>

          {/* --EQUIPMENT PHOTO-- */}
          {equipmentPhoto && (
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="photo-container">
                <h1 className="operation-title">
                  <span>EQUIPMENT</span>
                </h1>
                {equipmentPhotos.length === 1 ? (
                  <img
                    width="85%"
                    height="400px"
                    src={equipmentPhoto.url}
                    alt="equipment"
                  />
                ) : (
                  <div
                    style={{
                      width: "85%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px 0px 0px 0px",
                    }}
                  >
                    <PhotoGallery photos={equipmentPhotos} state={false} />{" "}
                  </div>
                )}

                <ContentButton
                  onClick={() => {
                    setOpenModalRotatePhoto(true);
                  }}
                >
                  {/* <Wrapperbtn> */}
                  <LabelRotatePhoto>
                    <p>Rotate Picture</p>
                  </LabelRotatePhoto>
                  {/* </Wrapperbtn> */}
                </ContentButton>
              </div>
            </Grid>
          )}

          <Modal
            open={openModalRotatePhoto}
            onClose={handleCloseModalRotatePhoto}
          >
            <Box
              sx={styleRotateModal}
              style={{
                position: "relative",
                borderRadius: "10px",
              }}
            >
              <HeadModal>
                <div className="title">
                  <img
                    src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                    alt=""
                    className="neurona"
                  />
                  <span className="titleHeadModal">Rotate Picture</span>
                </div>
                <Button onClick={handleCloseModalRotatePhoto}>
                  <AiFillCloseCircle style={{ color: "#FFF", fontSize: 21 }} />
                </Button>
              </HeadModal>
              <DialogContent>
                {equipmentPhoto && (
                  <img
                    style={{
                      transform: `rotate(${rotation}deg)`,
                      marginTop: "20px",
                    }}
                    width=" 50%"
                    src={equipmentPhoto.url}
                    alt="equipment"
                  />
                )}
                <br></br>
              </DialogContent>
              <RotateLeftIcon
                onClick={rotateImageLeft}
                sx={{
                  cursor: "pointer",
                }}
              ></RotateLeftIcon>
              <RotateRightIcon
                onClick={rotateImageRight}
                sx={{
                  cursor: "pointer",
                }}
              ></RotateRightIcon>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
              ></Typography>
            </Box>
          </Modal>

          {/* --NAMEPLATE PHOTO-- */}
          {namePlatePhoto && (
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="photo-container">
                <h1 className="operation-title">
                  <span>NAMEPLATE</span>
                </h1>

                {namePlatePhotos.length === 1 ? (
                  <img
                    width="85%"
                    height="400px"
                    src={namePlatePhoto.url}
                    alt="nameplate"
                  />
                ) : (
                  <div
                    style={{
                      width: "85%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "0px 0px 0px 0px",
                    }}
                  >
                    <PhotoGallery photos={namePlatePhotos} state={false} />{" "}
                  </div>
                )}

                <ContentButton
                  onClick={() => {
                    setOpenModalRotatePhotoNameplate(true);
                  }}
                >
                  {/* <Wrapperbtn> */}
                  <LabelRotatePhoto>
                    <p>Rotate Picture</p>
                  </LabelRotatePhoto>
                  {/* </Wrapperbtn> */}
                </ContentButton>
              </div>
            </Grid>
          )}

          <Modal
            open={openModalRotatePhotoNameplate}
            onClose={handleCloseModalRotatePhotoNameplate}
          >
            <Box
              sx={styleRotateModal}
              style={{
                position: "relative",
                borderRadius: "10px",
              }}
            >
              <HeadModal>
                <div className="title">
                  <img
                    src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                    alt=""
                    className="neurona"
                  />
                  <span className="titleHeadModal">Rotate Picture</span>
                </div>
                <Button onClick={handleCloseModalRotatePhotoNameplate}>
                  <AiFillCloseCircle style={{ color: "#FFF", fontSize: 21 }} />
                </Button>
              </HeadModal>
              <DialogContent>
                {namePlatePhoto && (
                  <img
                    style={{
                      transform: `rotate(${rotation}deg)`,
                      marginTop: "20px",
                    }}
                    width="50%"
                    src={namePlatePhoto.url}
                    alt="nameplate"
                  />
                )}
              </DialogContent>
              <br></br>
              <RotateLeftIcon
                onClick={rotateImageLeft}
                sx={{
                  cursor: "pointer",
                }}
              ></RotateLeftIcon>
              <RotateRightIcon
                onClick={rotateImageRight}
                sx={{
                  cursor: "pointer",
                }}
              ></RotateRightIcon>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
              ></Typography>
            </Box>
          </Modal>

          {/* --MAP- */}

          {namePlatePhoto && equipmentPhoto ? (
            <Grid item xs={12} md={12} lg={4} xl={4}>
              <div className="content-map">
                <h1 className="operation-title">
                  <span>OBJECT LOCATION</span>
                </h1>
                <MapView
                  typeObjectId={object.typeObjectId}
                  objectId={objectId}
                  location={object.location}
                  urlIcon={
                    object.typeObjectId === 1
                      ? object.typeObjectGhg.typeElementGhg.url_icon_color
                      : object.typeObjectId === 2
                      ? object.typeObjectAgile.typeElementAgile.urlIconColor
                      : object.typeObjectId === 3
                      ? object.typeObjectWell.typeElementWell.url_icon_color
                      : object.typeObjectId === 4
                      ? object.typeObjectFacility.typeElementFacility
                          .urlIconColor
                      : null
                  }
                />
              </div>
            </Grid>
          ) : namePlatePhoto || equipmentPhoto ? (
            <Grid item xs={12} md={12} lg={8} xl={8}>
              <div className="content-map">
                <h1 className="operation-title">
                  <span>OBJECT LOCATION</span>
                </h1>
                <MapView
                  typeObjectId={object.typeObjectId}
                  objectId={objectId}
                  location={object.location}
                  urlIcon={
                    object.typeObjectId === 1
                      ? object.typeObjectGhg.typeElementGhg.url_icon_color
                      : object.typeObjectId === 2
                      ? object.typeObjectAgile.typeElementAgile.urlIconColor
                      : object.typeObjectId === 3
                      ? object.typeObjectWell.typeElementWell.url_icon_color
                      : object.typeObjectId === 4
                      ? object.typeObjectFacility.typeElementFacility
                          .urlIconColor
                      : null
                  }
                />
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <div className="content-map">
                <h1 className="operation-title">
                  <span>OBJECT LOCATION</span>
                </h1>
                <MapView
                  typeObjectId={object.typeObjectId}
                  objectId={objectId}
                  location={object.location}
                  urlIcon={
                    object.typeObjectId === 1
                      ? object.typeObjectGhg.typeElementGhg.url_icon_color
                      : object.typeObjectId === 2
                      ? object.typeObjectAgile.typeElementAgile.urlIconColor
                      : object.typeObjectId === 3
                      ? object.typeObjectWell.typeElementWell.url_icon_color
                      : object.typeObjectId === 4
                      ? object.typeObjectFacility.typeElementFacility
                          .urlIconColor
                      : null
                  }
                />
              </div>
            </Grid>
          )}

          {/* --EVENT HISTORY-- */}
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: seeEventsTable ? "" : "none",
            }}
          >
            <div className="table-container">
              {object.typeObject.name === "GHG" ? (
                <>
                  <div className="operation-title">
                    <h1
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontSize: "2rem",
                        textAlign: "center",
                      }}
                    >
                      <span>
                        {" "}
                        CARBON FOOTPRINT MANAGEMENT <br /> EVENT HISTORY{" "}
                      </span>
                    </h1>
                  </div>
                  <div className="operation-table">
                    <ListEventsTable
                      objectId={objectId}
                      setSeeEventsTable={setSeeEventsTable}
                    ></ListEventsTable>
                  </div>
                </>
              ) : null}
            </div>
          </Grid>
        </ContainerOperations>
      )}
    </>
  );
};

export default ObjectDetails;
