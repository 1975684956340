import PersistentDrawerLeft from "../components/DigitalTwin/Drawer";
import DigitalTwinComponent from "../components/DigitalTwin";
import DrawerObjectDT from "../components/DigitalTwin/DrawerObject";

const DigitalTwin = () => {
  return (
    <>
      <PersistentDrawerLeft />
      <DrawerObjectDT />
      <DigitalTwinComponent />
    </>
  );
};

export default DigitalTwin;