import styled from "styled-components";

export const DashboardContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  align-items: center;
  background-color: #f9f9f9;
  margin-left: 80px;
  margin-right: 80px;
`;

export const DashboardWrapper = styled.div`
  /* max-width: 1000px; */
  width:90%;
  display: grid;
  grid-auto-rows: 20rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  align-items: center;
  gap: 3rem;
`;

export const DashboardCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const DashboardH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;
  margin-bottom: 64px;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const UserInfoReport = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-auto-rows: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
`;

export const UserContainer = styled.div`
  justify-content: center;
  background-color: #f9f9f9;
  margin-top: 15px;
  padding-top: 10px;
`;

export const UserH1 = styled.h1`
  font-size: 2rem;
  color: #010606;
  margin-bottom: 20px;
`;

export const ReportItem = styled.div`
  font-size: 1.4rem;
  padding: 3px;
  background-color: #f9f9f9;
  padding-right: 50px;
  border-radius: 0;
`;


