import { useEffect, useState } from "react";
import { Button, ContainerButtonCenter, FormSetUp } from "./FormElements";
import InputComponent from "./InputSetUp";

export default function Form(props) {
  const { form, handleChange, buttons, handleRows } = props;

  // To change the validate of the form
  const [formValidate, setFormValidate] = useState(true);

  // Action the button
  // Callback is a function that will be called when the button is clicked
  // Callback(fieldOfForm, ifFormIsValid, handlingOfRowsAfterExecuting)
  const handleClick = (e, callback) => {
    e.preventDefault();
    callback(form, formValidate, handleRows);
  };

  // Validate the form each that form have a change
  useEffect(() => {
    const validate = Object.values(form).every((field) => {
      if (!field.validate) return true; // In case of id
      return field.validate === "true";
    });
    setFormValidate(validate);
  }, [form]);

  return (
    <FormSetUp>
      <h1>Create region type</h1>
      <form action="">
        {Object.keys(form).map((key) => {
          if (key === "id") return false;
          return <InputComponent field={form[key]} onChange={handleChange} />;
        })}
        <ContainerButtonCenter>
          {buttons.map(({ label, handleClick: handleClickButton, type }) => (
            <Button
              type={type}
              onClick={(e) => handleClick(e, handleClickButton)}
            >
              {label}
            </Button>
          ))}
        </ContainerButtonCenter>
      </form>
    </FormSetUp>
  );
}
