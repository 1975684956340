import NavBar from "../components/NavBar";
import DashboardsComponent from "../components/Dashboards/index";
import { useParams } from "react-router-dom";

const Dashboards = () => {

  const { customerId } = useParams();

  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: {},
    button: {
      link: `collections`,
      text: "Back",
    },
  };

  return (
    <>
      <NavBar configNavbar={configNavbar} />
      <DashboardsComponent customerId={customerId} />
    </>
  );
};

export default Dashboards;
