import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CardMedia from "@mui/material/CardMedia";

import logoWhite from "./../../../images/Digital-Transformation.png";
import JOB from "../../../images/undraw_job.svg";

import { useDispatch, useSelector } from "react-redux";
import { showDrawerUserRoutes } from "./../../../redux/actions/admin";
import NavBar from "./NavBar";
import { useState } from "react";

import { Img, NavbarLogoR } from "./UserRoutesElements";

import TypeJob from "./TypeJob";
import OperatorSelect from "./OperatorSelect";

import {
  SummaryFeature,
  DrawerWrapper,
  TypeJobContainer,
  LocationInfo,
  FeatureDetails,
  AsignTo,
  ButtonCard,
  HeadModal,
} from "./DrawerElements";
import { PRIMARY_COLOR } from "../../../utils/const";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  margin: "0 5px",
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  // textAlign: "left",
};

const PersistentDrawerLeft = () => {
  const theme = useTheme();
  const open = useSelector((state) => state.adminReducer.showDrawerUserRoutes);

  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [featureJobData, setFeatureJobData] = useState();
  const [featureDescriptionData, setFeatureDescriptionData] = useState();
  const [featureAssignData, setFeatureAssignData] = useState();

  const [loading, setLoading] = useState(false);
  // define functions to openModal and close modal
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleDrawerOpen = () => {
    dispatch(showDrawerUserRoutes(true));
  };

  const handleDrawerClose = () => {
    dispatch(showDrawerUserRoutes(false));
  };

  const coordinateMuouse = useSelector(
    (state) => state.adminReducer.setCoordinatesUserRoute
  );

  const featureDetails = useSelector(
    (state) => state.adminReducer.setFeaturesDetails
  );

  // define functions to handle save button
  const savePhotos = async () => {
    setShowSaveBtn(false);
    setLoading(true);
    setLoading(false);
    handleClose();
  };

  // //onchange Feature param
  // const OnChangeFeatureParam = (event) => {
  //   setFeatureParam({
  //     ...featureParam,
  //     [event.target.name]: event.target.value,
  //   });
  // };
  // console.log("🐕‍🦺",featureParam);

  // //save form data feature
  // const saveFormData = async () => {
  //   const body = {
  //     geometry: featureDetails.geometry,
  //     featureParam: featureParam,
  //   };
  //   alert("Saved data");

  // };

  return (
    <>
      <NavBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon sx={{ fontSize: "2.5rem" }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            <NavbarLogoR to="/admin">
              <Img src={logoWhite} />
            </NavbarLogoR>
          </Typography>
        </Toolbar>
      </NavBar>
      <Drawer sx={styles.drawer} variant="persistent" anchor="left" open={open}>
        <DrawerHeader>
          <h2> Add Jobs</h2>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <DrawerWrapper>
          <Divider />
          <List subheader={<li />}>
            <TypeJobContainer>
              <TypeJob />
            </TypeJobContainer>
            <Divider />
            <LocationInfo>
              <b>Location:</b>
              <p>lng: {coordinateMuouse.lng}</p>
              <p>Lat: {coordinateMuouse.lat}</p>
            </LocationInfo>
            <Divider />
            {featureDetails.geometry.type !== null && (
              <FeatureDetails>
                <h2>Feature Details:</h2>
                <div>
                  <p class="geometry-type">
                    <b>type:</b>
                    <p>{featureDetails.geometry.type}</p>
                  </p>
                </div>
                <div>
                  <p>
                    <b>coordinates:</b> {featureDetails.geometry.coordinates[0]}
                    , {featureDetails.geometry.coordinates[1]}
                  </p>
                </div>
                <div>
                  <p>
                    <b>Job:</b>{" "}
                    <input
                      onChange={(event) =>
                        setFeatureJobData(event.target.value)
                      }
                      type="text"
                    />
                  </p>
                  <p>
                    <b>Description:</b>{" "}
                    <textarea
                      onChange={(event) =>
                        setFeatureDescriptionData(event.target.value)
                      }
                      type="text"
                    />
                  </p>
                </div>
                <AsignTo>
                  <p>
                    <b>Assign to:</b>
                  </p>
                  <OperatorSelect />
                </AsignTo>
                <ButtonCard onClick={handleOpen}>Save</ButtonCard>
                <Modal
                  open={openModal}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  {/* card summary job             */}
                  {/* Feature details Modal */}
                  <Box
                    sx={style}
                    style={{
                      position: "relative",
                      borderRadius: "10px",
                    }}
                  >
                    <HeadModal>
                      <img
                        src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                        alt=""
                        className="neurona"
                      />
                      <span className="titleHeadModal">Feature Details</span>
                    </HeadModal>
                    <CardMedia
                      style={{
                        marginTop: "50px",
                      }}
                      component="img"
                      height="240"
                      width="40"
                      image={JOB}
                      // image={"https://greendragon-bucketk-test.s3.us-east-2.amazonaws.com/motor-baldor-2.jpeg"}
                      alt="green iguana"
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                    <SummaryFeature>
                      <h2>Feature Details:</h2>
                      <div>
                        <p class="geometry-type">
                          <b>type:</b>
                          <p>{featureDetails.geometry.type}</p>
                        </p>
                      </div>
                      <div>
                        <p>
                          <b>Job:</b>{" "}
                          <input type="text" value={featureJobData} />
                        </p>
                        <p>
                          <b>Description:</b>{" "}
                          <textarea
                            type="text"
                            value={featureDescriptionData}
                          />
                        </p>
                      </div>
                      <AsignTo>
                        <p>
                          <b>Assign to:</b>
                        </p>
                        <OperatorSelect value={featureAssignData} />
                      </AsignTo>
                    </SummaryFeature>

                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    ></Typography>

                    <ButtonCard
                      startIcon={<SaveIcon />}
                      onClick={() => {
                        setLoading(true);
                        savePhotos();
                      }}
                    >
                      <p>
                        Confirm
                        {/* <SaveIcon />  */}
                      </p>
                    </ButtonCard>
                  </Box>
                </Modal>
              </FeatureDetails>
            )}
          </List>
        </DrawerWrapper>
      </Drawer>
    </>
  );
};

export default PersistentDrawerLeft;

const drawerWidth = 350;

const styles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  },
};
