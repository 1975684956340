import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_PARAMS,
  SET_LOCATIONS_CSV,
  RELOAD_CSV,
  RELOAD_DETAILS,
  SET_LOCATIONS_JSON,
  SHOW_DRAWER_DIGITAL_TWIN,
  SHOW_CARD_MAKER_DIGITAL_TWIN,
  SHOW_DRAWER_OBJECT_DT,
  SET_DATA_OBJECT_DT,
  SET_DATA_OBJECT_DT_IMAGES,
  SET_PROFILE_IMAGE,
  SHOW_MARKERS_DIGITAL_TWIN,
  SET_MARKER_BY_TYPE_ELEMENT_ID,
  SET_VIEW_MODE_COLLECTION,
  SET_SECTION_COLLECTION,
  SET_DATE_COLLECTION,
  SHOW_MARKERS_BY_ACTIVITIES,
  SHOW_OPERATION_DETAILS,
  OPERATION_DETAILS,
  SET_FILTER_OBJECTS_BY_DATA,
} from "./types";

export const setFilterObjectsByData = (payload) => {
  return {
    type: SET_FILTER_OBJECTS_BY_DATA,
    payload,
  };
};

export const setOperationDetails = (payload) => {
  return {
    type: OPERATION_DETAILS,
    payload,
  };
};

export const setShowOperationDetails = (payload) => {
  return {
    type: SHOW_OPERATION_DETAILS,
    payload,
  };
};

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const setCurrentUserParams = (payload) => ({
  type: SET_CURRENT_USER_PARAMS,
  payload,
});

export const setLocationsCSV = (payload) => ({
  type: SET_LOCATIONS_CSV,
  payload,
});

export const reloadCsv = (payload) => ({
  type: RELOAD_CSV,
  payload,
});

export const reloadDetails = (payload) => ({
  type: RELOAD_DETAILS,
  payload,
});

export const setLocationsJson = (payload) => ({
  type: SET_LOCATIONS_JSON,
  payload,
});

export const showDrawerDigitalTwin = () => ({
  type: SHOW_DRAWER_DIGITAL_TWIN,
});

export const showDrawerObjectDt = (payload) => ({
  type: SHOW_DRAWER_OBJECT_DT,
  payload,
});

export const showCardMakerDigitalTwin = (payload) => ({
  type: SHOW_CARD_MAKER_DIGITAL_TWIN,
  payload,
});

export const setDataObjectDTImages = (payload) => ({
  type: SET_DATA_OBJECT_DT_IMAGES,
  payload,
});

export const setProfileImage = (payload) => ({
  type: SET_PROFILE_IMAGE,
  payload,
});

export const setDataObjectDT = (payload) => ({
  type: SET_DATA_OBJECT_DT,
  payload,
});

export const showMarkersDigitalTwin = () => ({
  type: SHOW_MARKERS_DIGITAL_TWIN,
});

export const showMarkerByTypeElementId = (payload) => ({
  type: SET_MARKER_BY_TYPE_ELEMENT_ID,
  payload,
});
export const setViewModeCollection = (payload) => ({
  type: SET_VIEW_MODE_COLLECTION,
  payload,
});

export const setSectionCollection = (payload) => ({
  type: SET_SECTION_COLLECTION,
  payload,
});

export const setDateCollection = (payload) => ({
  type: SET_DATE_COLLECTION,
  payload,
});

export const showMarkersByActivities = (payload) => ({
  type: SHOW_MARKERS_BY_ACTIVITIES,
  payload,
});
