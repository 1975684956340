// Required modules
import NavBar from "components/Admin/NavBar";
import { useNavigate } from "react-router-dom";
import {
  ConfigAdminContainer,
  TitleAdminConfig,
  ItemsAdminConfig,
  ItemAdminCofig,
} from "./configAdminElemts";

export default function ConfigAdmin() {
  const navigate = useNavigate();

  // Navigate to any route
  const navigation = (url) => {
    navigate(url);
  };

  return (
    <div>
      <NavBar linkLogo={"admin"} />
      <ConfigAdminContainer>
        <TitleAdminConfig>set up green dragon</TitleAdminConfig>
        <ItemsAdminConfig>
          <ItemAdminCofig onClick={() => navigation("/config-type-regions")}>
            <h1>Type Of Regions</h1>
            <p>Manage app region types</p>
          </ItemAdminCofig>
        </ItemsAdminConfig>
      </ConfigAdminContainer>
    </div>
  );
}
