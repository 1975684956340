import Container from "@mui/material/Container";
import NavBar from "components/Admin/NavBar";
import { CustomContainer } from "./videoManagerStyles";
import CustomizedTable from "components/Tables/CustomizedTable";
import OperationDetails from "components/ImageGallery/OperationDetails";
import { useDispatch } from "react-redux";
import { setOperationDetails, setShowOperationDetails } from "redux/actions";
import OperationDialog from "components/Dialogs/OperationDialog";
import FetchFlirEventsByUserId from "hooks/fetchFlirEventsByUserId";
import { useParams } from "react-router-dom";
import GenericDialog from "components/Dialogs/GenericDialog";
import DragAndDropContent from "./dragAndDrop";
import { useState } from "react";
import FetchFieldVideosByUserId from "hooks/fetchFieldVideosWithoutEvent";
import Loading from "components/Lodings/LoadingV2";

// info table
const headers = [
  "Event ID",
  "Field Video Name",
  "Field Video",
  "Field Video Processed",
  "Date Collection",
  "No. Anemometers",
];
const keyValues = [
  "id",
  "videoName",
  "fieldVideo",
  "fieldVideoProcessed",
  "date",
  "anemometer",
];
function createRows(events) {
  return events.map((event) => {
    const flirVideos = event.inspectionFlir?.flirVideos[0];
    const fieldVideo =
      flirVideos?.urlVideo !== null && flirVideos?.urlVideo !== undefined
        ? "✅"
        : "❌";
    const fieldVideoProcessed =
      flirVideos?.flirVideoProcessed !== null ? "✅" : "❌";
    const NoAnemometers = event.inspectionFlir?.operationAnemometer.length;
    return {
      id: event.id,
      fieldVideo,
      fieldVideoProcessed,
      videoName: event.inspectionFlir?.flirVideos[0]?.name,
      date: `${isoDateToString(event.date)}`,
      anemometer: NoAnemometers,
    };
  });
}

function createRowsVideos(videos) {
  return videos.map((video) => {
    return {
      id: null,
      fieldVideo: "⚠️",
      videoName: video.name,
      date: `${isoDateToString(video.date)}`,
      anemometer: "-",
    };
  });
}

const isoDateToString = (isoDate) => {
  const date = new Date(isoDate);
  return date.toString().substring(0, 15);
};

const UploadVideos = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const { data: flirEvents, error } = FetchFlirEventsByUserId(userId);
  const rows = flirEvents ? createRows(flirEvents) : [];

  const eventWithoutVideo =
    flirEvents &&
    flirEvents.filter((event) => {
      const urlVideo = event.inspectionFlir?.flirVideos[0]?.urlVideo;
      return urlVideo !== null && urlVideo !== undefined ? false : true;
    });

  const { data: fieldVideos, error: erroFieldVideos } =
    FetchFieldVideosByUserId(userId);

  const rowsVideos =
    fieldVideos && !erroFieldVideos ? createRowsVideos(fieldVideos) : [];

  const handlerClickUpladVideos = () => handleOpenModal();

  const handleClickEvent = async (operationId) => {
    const operation = {
      content: <OperationDetails operationId={operationId} />,
      title: "Event Details",
    };
    dispatch(setOperationDetails(operation));
    dispatch(setShowOperationDetails(true));
  };

  return (
    <>
      <NavBar linkLogo={"admin"} />
      <Container maxWidth="xl">
        (
        <CustomContainer>
          <div className="title">
            <h1>UPLOAD VIDEOS</h1>
            {/* <p>Videos Jaime crew</p> */}
          </div>
          {flirEvents && !error ? (
            <div>
              <section className="kpis-section">
                <button
                  className="button-upload-videos"
                  onClick={() => handlerClickUpladVideos()}
                >
                  Upload Videos
                </button>
                <div className="kpis-container">
                  <h3>
                    <span>{flirEvents.length} FLIR</span> Events
                  </h3>
                  <h3>
                    <span>
                      {flirEvents.length -
                        eventWithoutVideo.length +
                        rowsVideos.length}{" "}
                      Videos
                    </span>{" "}
                    Uploaded
                  </h3>
                  <h3>
                    <span>
                      {flirEvents.length - eventWithoutVideo.length} Events
                    </span>{" "}
                    with video
                  </h3>
                  <h3>
                    <span>{eventWithoutVideo.length} Events</span> without viedo
                  </h3>
                  <h3>
                    <span>{rowsVideos.length} Videos</span> without event
                  </h3>
                </div>
              </section>
              <section className="table-container">
                <CustomizedTable
                  headers={headers}
                  rows={rows.concat(rowsVideos)}
                  keyValues={keyValues}
                  handlerClick={handleClickEvent}
                />
              </section>
            </div>
          ) : (
            <Loading />
          )}

          <OperationDialog
            style={{
              margin: 0,
              padding: 0,
              width: "70%",
              height: "100%",
              maxWidth: "none",
            }}
          />
          <GenericDialog
            openDialog={openModal}
            setOpenDialog={setOpenModal}
            content={{
              title: "Upload Videos",
              description: null,
              disagree: null,
              agree: null,
              content: (
                <DragAndDropContent
                  eventWithoutVideo={eventWithoutVideo}
                  userId={userId}
                />
              ),
            }}
            style={{
              margin: 0,
              padding: 0,
              width: "70%",
              height: "80%",
              maxWidth: "none",
            }}
          />
        </CustomContainer>
        )
      </Container>
    </>
  );
};

export default UploadVideos;
