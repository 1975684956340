import * as XLSX from "xlsx";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLocationsCSV } from "../../redux/actions";
import { setLocationsJson } from "../../redux/actions";
import OpenDialog from "../Dialogs/OpenDialog";

import { LoadCsv, CustomInput } from "./stylesPowerSystems";

const token = localStorage.getItem("token");

const LoadCSV = () => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [haveObjects, setHaveObjects] = useState(false);
  const [mediaMessage, setMediaMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const dispatch = useDispatch();

  // process CSV data
  const processData = async (dataString, name) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length === headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => ({
      name: c,
      selector: c,
    }));

    const locationsCSV = { columns, list, name };
    const locationsJson = await showCSV(locationsCSV);

    if (locationsJson === undefined) {
      setErrorMessage(true);
    } else {
      dispatch(setLocationsJson(locationsJson));
      dispatch(setLocationsCSV({ columns, list, name }));
      setData(list);
      setColumns(columns);
      setMediaMessage(true);
    }
  };

  const getIconElements = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/tables`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const tableName = await res.json();
      return tableName;
    } catch (error) {
      console.error(error);
    }
  };

  const showCSV = async (locationsCSV) => {
    try {
      const elements = await getIconElements();

      const object = new Array();
      locationsCSV.list.map((location, index) => {
        let nameObject = {};
        let iconObject = {};
        let typeObject = {};
        if (location.typeObjectId === "1") {
          nameObject =
            elements.typeElementGhg[Number(location.typeElementId) - 1].name;
          iconObject =
            elements.typeElementGhg[Number(location.typeElementId) - 1]
              .url_icon_color;
          typeObject = "GHG";
        }

        if (location.typeObjectId === "2") {
          nameObject =
            elements.objectsAgile[Number(location.typeElementId) - 1].name;
          iconObject =
            elements.objectsAgile[Number(location.typeElementId) - 1]
              .urlIconColor;
          typeObject = "AGILE";
        }

        if (location.typeObjectId === "3") {
          nameObject =
            elements.objectsWell[Number(location.typeElementId) - 1].name;
          iconObject =
            elements.objectsWell[Number(location.typeElementId) - 1]
              .url_icon_color;
          typeObject = "WELL";
        }

        if (location.typeObjectId === "4") {
          nameObject =
            elements.objectsFacility[Number(location.typeElementId) - 1].name;
          iconObject =
            elements.objectsFacility[Number(location.typeElementId) - 1]
              .urlIconColor;
          typeObject = "FACILITY";
        }

        if (
          Number(location.latitude) > 90 ||
          Number(location.latitude) < -91 ||
          Number(location.longitude) > 180 ||
          Number(location.longitude) < -180 ||
          Number(location.typeObjectId) > 4 ||
          Number(location.typeObjectId) < 1 ||
          (Number(location.typeObjectId) === 1 &&
            Number(location.typeElementId) > 8) ||
          (Number(location.typeObjectId) === 1 &&
            Number(location.typeElementId) < 1) ||
          (Number(location.typeObjectId) === 2 &&
            Number(location.typeElementId) > 12) ||
          (Number(location.typeObjectId) === 2 &&
            Number(location.typeElementId) < 1) ||
          Number(location.latitude) === NaN ||
          Number(location.longitude) === NaN ||
          Number(location.typeObjectId) === NaN ||
          Number(location.typeElementId) === NaN
        ) {
          throw new Error(
            "This is not an error. This is just to abort javascript"
          );
        }

        const body = {
          latitude: Number(location.latitude),
          longitude: Number(location.longitude),
          typeObjectId: Number(location.typeObjectId),
          typeElementId: Number(location.typeElementId),
          name: nameObject,
          icon: iconObject,
          type: typeObject,
        };
        object.push(body);
      });
      return object;
    } catch (error) {
      console.error(error);
    }
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      if (reader.result.length === 0) {
        setHaveObjects(true);
      } else {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        processData(data, file.name);
      }
    };

    reader.readAsBinaryString(file);
    e.target.value = "";
    if (file.size === 0) {
    } else {
    }
  };

  return (
    <LoadCsv>
      <label htmlFor="csv" style={{ textTransform: "none" }}>
        Add Data
      </label>
      <CustomInput
        id="csv"
        type="file"
        style={{ visibility: "hidden" }}
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload}
      />
      <OpenDialog
        openDialog={errorMessage}
        setOpenDialog={setErrorMessage}
        content={{
          title: "Invalid Format",
          description:
            "Cannot read the file. Please ensure that the format is valid and try again.",
          disagree: "Continue",
        }}
      />
      <OpenDialog
        openDialog={haveObjects}
        setOpenDialog={setHaveObjects}
        content={{
          title: "No Items",
          description:
            "The file that was uploaded is empty. Please provide another one.",
          disagree: "See map",
        }}
      />
      <OpenDialog
        openDialog={mediaMessage}
        setOpenDialog={setMediaMessage}
        content={{
          title: "No Media Files",
          description:
            "The file that was uploaded does not have any media. If you want to add pictures, click on the marker icon and select add pictures option.",
          disagree: "Ok",
        }}
      />
    </LoadCsv>
  );
};

export default LoadCSV;
