import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setViewModeCollection } from "./../../redux/actions";

const ToggleButtons = () => {
  const dispatch = useDispatch();

  const viewMode = useSelector(
    (state) => state.collectionReducer.viewModeCollection
  );

  const handleAlignment = (event, nextView) => {
    dispatch(setViewModeCollection(nextView));
  };

  return (
    <ToggleButtonGroup
      value={viewMode}
      exclusive
      onChange={handleAlignment}
      aria-label="text view"
    >
      <ToggleButton value="List" aria-label="left aligned">
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value="Module" aria-label="centered">
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
