import useSupercluster from "use-supercluster";
import { useEffect, useMemo, useState } from "react";
import { Marker } from "react-map-gl";
import { MarkerContainer } from "./MapStyle";
import { useSelector } from "react-redux";
import { NamePlateMarker } from "./userRoutes";
import useGet from "../../../hooks/useFetch";

const ShowLocationFromCSV = ({
  locationsCSV,
  bounds,
  viewState,
  setViewState,
  onSelectPoint,
}) => {
  const showMarkersByTypeObjectLoad = useSelector(
    (state) => state.adminReducer.markerByTypeObjectLoad
  );
  const showMarkersByTypeElementLoad = useSelector(
    (state) => state.adminReducer.markerByTypeElementLoad
  );

  const [iconElements, errorIconElements] = useGet("api/v1/objects/tables");

  // locationCSV to geoJSON
  const points = locationsCSV.list.map((location) => ({
    type: "Feature",
    properties: {
      cluster: false,
      location: location,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(location.longitude),
        parseFloat(location.latitude),
      ],
    },
  }));

  const { clusters, superCluster } = useSupercluster({
    points,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const iteratorCSV = (location, index) => {
    if (iconElements && !errorIconElements) {
      let icon = {};
      let nameTypeElement = null;
      let typeObjectSelect = null;
      if (location.typeObjectId === "1") {
        const { url_icon_gray } =
          iconElements.typeElementGhg[location.typeElementId - 1];
        icon.url = url_icon_gray;
        nameTypeElement =
          iconElements.typeElementGhg[location.typeElementId - 1].name;
        typeObjectSelect = "GHG";
      }
      if (location.typeObjectId === "2") {
        const { urlIconGray } =
          iconElements.objectsAgile[location.typeElementId - 1];
        icon.url = urlIconGray;
        nameTypeElement =
          iconElements.objectsAgile[location.typeElementId - 1].name;
        typeObjectSelect = "AGILE";
      }
      if (location.typeObjectId === "3") {
        const { url_icon_gray } =
          iconElements.objectsWell[location.typeElementId - 1];
        icon.url = url_icon_gray;
        nameTypeElement =
          iconElements.objectsWell[location.typeElementId - 1].name;
        typeObjectSelect = "WELL";
      }
      if (location.typeObjectId === "4") {
        const { urlIconGray } =
          iconElements.objectsFacility[location.typeElementId - 1];
        icon.url = urlIconGray;
        nameTypeElement =
          iconElements.objectsFacility[location.typeElementId - 1].name;
        typeObjectSelect = "FACILITY";
      }
      return (
        <div key={index}>
          {showMarkersByTypeObjectLoad[typeObjectSelect] &&
            showMarkersByTypeElementLoad[nameTypeElement] && (
              <Marker
                latitude={Number(location.latitude)}
                longitude={Number(location.longitude)}
              >
                <NamePlateMarker id={location.id}>
                  <img
                    id={location.id}
                    src={icon?.url}
                    alt="nameplate marker"
                  ></img>
                </NamePlateMarker>
              </Marker>
            )}
        </div>
      );
    }
  };

  const MarkersCluster = useMemo(() =>
    clusters.map((cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const { cluster: isCluster, point_count: pointCount } =
        cluster.properties;
      if (isCluster) {
        return (
          <Marker
            key={`cluster-${cluster.id}`}
            latitude={latitude}
            longitude={longitude}
          >
            <MarkerContainer>
              <div
                className="cluster-marker-csv"
                style={{
                  width: `${10 + (pointCount / points.length) * 50}px`,
                  height: `${10 + (pointCount / points.length) * 50}px`,
                }}
                onClick={() => {
                  onSelectPoint(longitude, latitude);
                  setViewState({
                    ...viewState,
                    latitude,
                    longitude,
                  });
                }}
              >
                {pointCount}
              </div>
            </MarkerContainer>
          </Marker>
        );
      }
      return iteratorCSV(cluster.properties.location, cluster.id);
    })
  );

  return MarkersCluster;
};

export default ShowLocationFromCSV;
