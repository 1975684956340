import { styled } from "@mui/material/styles";
import { PRIMARY_COLOR } from "./../../utils/const";

export const HeadModal = styled("modal")`
  height: 55px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  border-radius: 5px 5px 0px 0px;

  .neurona {
    width: 34px;
    margin-right: 1px;
    margin-bottom: 6px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
