import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AnimationLottie from "../animations/index";
import animation from "../../lotties/document-ocr-scan.json";
import useGet from "../../hooks/useFetch";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import PhotoGallery from "./PhotoGallery";

import {
  Title,
  Wrapper,
  WrapperImages,
  WrapperForms,
  CardImg,
  FormTitle,
  CardSubtitle,
  InfoObject,
  WrapperInput,
  LabelInput,
  WrapperButtons,
  ButtonCard,
  FormCard,
  FormInput,
  FormInputComment,
  ButtonDelete,
  Imgcard,
  InfoTitle,
  CardSubtitleObject,
  SelectOperation,
  HeadModal,
  WraperTitleCardMarker,
} from "./ImageDetailsElements";
import { PRIMARY_COLOR } from "../../utils/const";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const ImageDetails = ({ objectId }) => {
  const customerId = localStorage.getItem("customerId");
  const [urlImageProcessed, setUrlImageProcessed] = useState();
  const [electricParam, setElectricParam] = useState();
  const [generalParam, setGeneralParam] = useState();
  const [comments, setComments] = useState("");
  const [customer, setCustomer] = useState();
  const [showButtonStart, setShowButtonStart] = useState(true);
  const [showAnimationScanner, setShowAnimationScanner] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);
  const [namePlate, setNamePlate] = useState(null);
  const token = localStorage.getItem("token");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const [object, error] = useGet(`api/v1/objects/${objectId}`);
  const [objectPhotos, errorPhotos] = useGet(
    `api/v1/object-photos/object-id/${objectId}`
  );

  useEffect(() => {
    objectPhotos &&
      objectPhotos.objectImages.map((objectImage) => {
        if (objectImage.photoType.type === "OBJECT") setObjectUrl(objectImage);
        if (objectImage.photoType.type === "NAMEPLATE")
          setNamePlate(objectImage);
      });
  }, [objectPhotos]);

  useEffect(() => {
    const fetchData = async (userId) => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_URL_BACKEND}api/v1/users/${userId}`,

          {
            headers: {
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
        const { operator } = data;
        setCustomer(operator);
      } catch (error) {
        console.error(error);
      }
    };
    object && fetchData(object.userId);
  }, [object]);

  const buttonStartProcess = async () => {
    setShowAnimationScanner(!showAnimationScanner);
    try {
      const body = {
        url_img_unprocessed: objectPhotos.urlNameplate,
      };
      const imageProcessedData = await fetch(
        `${process.env.REACT_APP_URL_OCR_API}urlunprocessed`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const imageProcessed = await imageProcessedData.json();
      setUrlImageProcessed(imageProcessed[0].urlImgProcessed);
      setElectricParam(imageProcessed[1]);
      setGeneralParam(imageProcessed[2]);
      setShowButtonStart(!showButtonStart);
    } catch (error) {
      console.error(error);
    }
  };

  const OnChangeElectricParam = (event) => {
    setElectricParam({
      ...electricParam,
      [event.target.name]: event.target.value,
    });
  };

  const OnChangeGeneralParam = (event) => {
    setGeneralParam({
      ...generalParam,
      [event.target.name]: event.target.value,
    });
  };

  const saveFormData = async () => {
    const body = {
      urlImgProcessed: urlImageProcessed,
      comments: comments,
      objectId: objectId,
      electricParamMotor: electricParam,
      generalParamMotor: generalParam,
    };
    alert("Saved data");
    const imageProcessedData = await fetch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/paramPlates`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify(body),
      }
    );
    await imageProcessedData.json();

    if (imageProcessedData.status === 201) {
      await axios.patch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${objectId}`,
        {
          isProcessed: true,
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      navigate(`/imageGallery/${object.date.substring(0, 10)}/${customerId}`);
    } else {
      console.error("error");
    }
  };

  const updateObject = async () => {
    const res = await axios.patch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/${objectId}`,
      {
        isActive: false,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    if (res.status === 200) {
      navigate(`/imageGallery/${object.date.substring(0, 10)}/${customerId}`);
    }
  };

  return (
    <Container maxWidth="xl">
      {object && customer && objectPhotos && object.objectPhotos.length && (
        <>
          <WraperTitleCardMarker>
            <h1>
              <span>{object.typeObject.name} </span> OBJECT TYPE
            </h1>
            <div className="wraper-title-element">
              {object.typeObjectId === 2 ? (
                <>
                  <h2>{object.typeObjectAgile.typeElementAgile.name} </h2>
                  <img
                    width="30"
                    src={object.typeObjectAgile.typeElementAgile.urlIconColor}
                  ></img>
                </>
              ) : null}
              {object.typeObjectId === 1 ? (
                <>
                  <h2>{object.typeObjectGhg.typeElementGhg.name}</h2>
                  <img
                    width="30"
                    src={object.typeObjectGhg.typeElementGhg.url_icon_color}
                  ></img>
                </>
              ) : null}
            </div>
          </WraperTitleCardMarker>
          <InfoTitle>
            <CardSubtitleObject>
              <b>Created by: </b>
              {customer?.firstName} {customer?.secondName}
            </CardSubtitleObject>
            <CardSubtitleObject>
              <b>Date: </b>
              {object.date.substring(0, 10)}
            </CardSubtitleObject>
          </InfoTitle>
          <InfoObject>
            <CardSubtitle>
              <b>0 </b> Repairs
            </CardSubtitle>
            <CardSubtitle>
              <b>0 </b> Ispections flir
            </CardSubtitle>
            <CardSubtitle>
              <b>{objectPhotos.objectImages.length} </b> Files
            </CardSubtitle>
          </InfoObject>
          <SelectOperation>
            <option value="">Select operation</option>
            <option value="1">Repair</option>
            <option value="1">Inspection Flir</option>
            <option value="1">Leak</option>
          </SelectOperation>
          <Container
            maxWidth="md"
            sx={{
              width: "50%",
            }}
          >
            <PhotoGallery objectPhotos={objectPhotos} />
          </Container>
        </>
      )}
      <WrapperButtons>
        {showButtonStart ? (
          <ButtonCard to="" onClick={buttonStartProcess}>
            Start Process
          </ButtonCard>
        ) : null}
        {generalParam && electricParam ? (
          <ButtonCard to="" onClick={saveFormData}>
            Save Process
          </ButtonCard>
        ) : null}
        <ButtonDelete to="" onClick={handleOpen}>
          Delete Object
        </ButtonDelete>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              position: "relative",
              borderRadius: "10px",
            }}
          >
            <HeadModal>
              <img
                src="https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/d-decimetrix.png"
                alt=""
                className="neurona"
              />
              <span className="titleHeadModal">Delete Object</span>
            </HeadModal>
            <Typography
              style={{
                marginTop: "50px",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Are you sure you want to delete this object?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateObject();
                handleClose();
              }}
              style={{
                margin: "10px",
                backgroundColor: PRIMARY_COLOR,
                borderColor: PRIMARY_COLOR,
                color: "#FFF",
              }}
            >
              Yes
            </Button>
          </Box>
        </Modal>
      </WrapperButtons>

      <Wrapper>
        <WrapperImages>
          {namePlate ? (
            <>
              <a href={namePlate.url} target="_blank" rel="noreferrer">
                <CardImg src={namePlate.url} alt="image" />
              </a>
            </>
          ) : urlImageProcessed ? (
            <a href={urlImageProcessed} target="_blank" rel="noreferrer">
              <CardImg src={urlImageProcessed} alt="image" />
            </a>
          ) : showAnimationScanner ? (
            <AnimationLottie animation={animation} width={300} height={500} />
          ) : null}
        </WrapperImages>

        <WrapperForms>
          {/* <AnimationLottie animaetion={animation}/> */}
          {electricParam ? (
            <FormCard>
              <FormTitle>Electric Param </FormTitle>
              <WrapperInput>
                <LabelInput htmlFor="hp">HP:</LabelInput>
                <FormInput
                  type="text"
                  name="hp"
                  onChange={OnChangeElectricParam}
                  value={electricParam.hp}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Volt:</LabelInput>
                <FormInput
                  type="text"
                  name="voltage"
                  onChange={OnChangeElectricParam}
                  value={electricParam.voltage}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Amperage:</LabelInput>
                <FormInput
                  type="text"
                  name="amperage"
                  onChange={OnChangeElectricParam}
                  value={electricParam.amperage}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Power factor:</LabelInput>
                <FormInput
                  type="text"
                  name="powerFactor"
                  onChange={OnChangeElectricParam}
                  value={electricParam.powerFactor}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Efficiency:</LabelInput>
                <FormInput
                  type="text"
                  name="efficiency"
                  onChange={OnChangeElectricParam}
                  value={electricParam.efficiency}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Service factor:</LabelInput>
                <FormInput
                  type="text"
                  name="serviceFactor"
                  onChange={OnChangeElectricParam}
                  value={electricParam.serviceFactor}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>rpm:</LabelInput>
                <FormInput
                  type="text"
                  name="rpm"
                  onChange={OnChangeElectricParam}
                  value={electricParam.rpm}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>HZ:</LabelInput>
                <FormInput
                  type="text"
                  name="hz"
                  onChange={OnChangeElectricParam}
                  value={electricParam.hz}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Phases:</LabelInput>
                <FormInput
                  type="text"
                  name="phases"
                  onChange={OnChangeElectricParam}
                  value={electricParam.phases}
                />
              </WrapperInput>
            </FormCard>
          ) : null}
          {generalParam ? (
            <FormCard>
              <FormTitle>General Param</FormTitle>
              <WrapperInput>
                <LabelInput>Insulation class:</LabelInput>
                <FormInput
                  type="text"
                  name="insulationClass"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.insulationClass}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>manufacturer:</LabelInput>
                <FormInput
                  type="text"
                  name="manufacturer"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.manufacturer}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Serial number:</LabelInput>
                <FormInput
                  type="text"
                  name="serialNumber"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.serialNumber}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Enclosure:</LabelInput>
                <FormInput
                  type="text"
                  name="enclosure"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.enclosure}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Model number:</LabelInput>
                <FormInput
                  type="text"
                  name="modelNumber"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.modelNumber}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Cat:</LabelInput>
                <FormInput
                  type="text"
                  name="cat"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.cat}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Weight:</LabelInput>
                <FormInput
                  type="text"
                  name="weight"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.weight}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Date:</LabelInput>
                <FormInput
                  type="text"
                  name="date"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.date}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>temperature:</LabelInput>
                <FormInput
                  type="text"
                  name="temperature"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.temperature}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>frame:</LabelInput>
                <FormInput
                  type="text"
                  name="frame"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.frame}
                />
              </WrapperInput>
              <WrapperInput>
                <LabelInput>Duty:</LabelInput>
                <FormInput
                  type="text"
                  name="duty"
                  onChange={OnChangeGeneralParam}
                  value={generalParam.duty}
                />
              </WrapperInput>

              <WrapperInput>
                <LabelInput>Comments:</LabelInput>
              </WrapperInput>
              <FormInputComment
                type="text"
                name="comments"
                onInput={(e) => setComments(e.target.value)}
              />
            </FormCard>
          ) : // <Message>Please start process</Message>
          objectUrl ? (
            <a href={objectUrl.url} target="_blank" rel="noreferrer">
              <CardImg src={objectUrl.url} alt="image" />
            </a>
          ) : null}
        </WrapperForms>
      </Wrapper>
    </Container>
  );
};

export default ImageDetails;
