import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { PRIMARY_COLOR } from "utils/const";

export const HubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  background-color: #f9f9f9;
  text-align: center;
  padding: 20px 0;

  background-image: url("https://greendragon-bucket-test.s3.us-east-2.amazonaws.com/NeuronaVerdeMarcadeAgua.png");
  background-repeat: no-repeat;
  background-size: 35%;
  background-position: center;
  /* background-color:red; */
  height: 600px;
`;

export const StyledP = styled.p`
  @media (max-width: 600px) {
    display: none;
  }
`;

export const UserH2Flex = styled.text`
  font-size: 2rem;
  color: #010606;
  margin-bottom: 15px;
  justify-content: flex-start;
`;

export const NavInfo = styled.div`
  margin-top: 13%;
  margin-left: 15%;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

export const Nav = styled.nav`
  background-color: ${PRIMARY_COLOR};
  height: 80px;
  /* margin-top: -80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000066;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
  z-index: 10;
  width: ${(props) => (props.tipo ? "90%" : "100%")};
  margin-left: ${(props) => (props.tipo ? "10%" : "0%")};

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const HubWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-auto-rows: 30rem;
  align-items: center;
  gap: 3rem;
`;

export const HubCard = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 3px 6px #00000066;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media (max-width: 350px) {
    width: 200px;
  }
`;

export const HubIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;

export const HubH1 = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  color: ${PRIMARY_COLOR};
  margin-bottom: 64px;

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const HubH2 = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const HubP = styled.p`
  font-size: 1.5rem;
  text-align: center;
`;

export const UserInfoReport = styled.div`
  display: grid;
  gap: 0.5rem;
  grid-auto-rows: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  border: 2px solid #ececec;
  border-radius: 15px;
  padding: 1.2rem 0;
  margin-top: 3rem;
`;

export const UserContainer = styled.div`
  justify-content: center;
  background-color: #f9f9f9;
  margin-top: 70px;
  /* padding-top: 40px; */
  /* margin-left: 80px; */
  /* margin-right: 80px; */
`;

export const UserH1 = styled.h1`
  margin-bottom: 2px;
  font-size: 3rem;
  font-weight: 500;
  color: #010606;
`;

export const UserH2 = styled.text`
  font-size: 2.2rem;
  color: #010606;
  margin-bottom: 15px;
`;

export const ReportItem = styled.div`
  font-size: 2rem;
  padding: 3px;
  background-color: #f9f9f9;
  /* padding-right: 50px; */
  border-radius: 0;
  align-self: center;
  justify-self: center;
`;

export const Img = styled.img`
  width: 200px;
  height: auto;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  }
`;

export const NavbarLogoR = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 24px;
  font-weight: bold;
  text-decoration: none;
`;
