import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Home from "./pages";
import SignIn from "./pages/SignIn";
// import SelectFolders from "./pages/SelectFolders";
import ImageGallery from "./pages/ImageGallery/ImageGallery";
import ImageDetails from "./pages/ImageGallery/ImageDetails";
import Hub from "./pages/hub";
import Collections from "./pages/collections";
import FieldData from "./pages/fieldDataCollection";
import Dashboards from "./pages/dashboards";
import EmailConfirmation from "./pages/emailConfirmation";
import PasswordConfirmation from "./pages/passwordConfirmation";
import DigitalTwin from "./pages/digitalTwin";
import SignUp from "./pages/SignUp";
import MapCollection from "./pages/mapCollection";
import Profile from "./pages/Admin/profile";
// admin pages
import Admin from "./pages/Admin/admin";
import AdminDecimetrix from "./pages/AdminDecimetrix/adminDecimetrix";
import UserRegister from "./pages/Admin/userRegister";
import AdminRegister from "./pages/AdminDecimetrix/adminRegister";
import UserRoutes from "./pages/Admin/userRoutes";
import DigitalTwinAdmin from "./pages/Admin/digitalTwin";
import DashboardsAdmin from "./pages/Admin/dashboard";
// page for mobile app
import DashboardMobile from "./pages/dashboardMobile";

import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ForgotPassword from "./pages/ForgotPassword";

// pages for operator
import VideoManager from "./pages/Operator/videoManager";
import UploadVideos from "./pages/Operator/uploadVideos";
import ConfigAdmin from "pages/Admin/configAdmin/ConfigAdmin";
import TypeRegions from "pages/Admin/configAdmin/TypeRegions";

const App = () => {
  // Validate user
  const { token } = useSelector((state) => state.userReducer.user);
  let role = "None";
  if (token) role = jwt_decode(token).role;

  // Change material ui theme
  const theme = createTheme({
    typography: {
      fontFamily: "Montserrat",
      fontSize: 22.4,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          {/* Any person */}
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/password-reset/:token"
            element={<PasswordConfirmation />}
          />
          <Route
            exact
            path="/confirm-email-address/:token/:id"
            element={<EmailConfirmation />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* Admin */}
          <Route
            path="/admin"
            element={
              role === "COMPANY ADMIN" ? <Admin /> : <Navigate to="/signIn" />
            }
          />
          <Route
            path="/profileAdmin"
            element={
              role === "COMPANY ADMIN" ? <Profile /> : <Navigate to="/signIn" />
            }
          />
          <Route
            path="/digital-twin-admin"
            element={
              role === "COMPANY ADMIN" ? (
                <DigitalTwinAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/signUp"
            element={
              role === "COMPANY ADMIN" ? <SignUp /> : <Navigate to="/signIn" />
            }
          />
          <Route
            path="/user-register"
            element={
              role === "COMPANY ADMIN" ? (
                <UserRegister />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/user-routes"
            element={
              role === "COMPANY ADMIN" ? (
                <UserRoutes />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/dashboard-admin"
            element={
              role === "COMPANY ADMIN" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          {/* Admin */}
          <Route
            path="/admin-decimetrix"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <AdminDecimetrix />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/profile-admin-decimetrix"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <Profile />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/digital-twin-admin-decimetrix"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <DigitalTwinAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/signUp"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <SignUp />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/admin-decimetrix-register"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <AdminRegister />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/dashboard-admin-decimetrix"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/user-routes"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <UserRoutes />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/dashboard-admin"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/config-admin"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <ConfigAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/config-type-regions"
            element={
              role === "DECIMETRIX ADMIN" ? (
                <TypeRegions />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          {/* Operator */}
          <Route
            path="/digitalTwin"
            element={
              role === "OPERATOR" ? (
                <DigitalTwinAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          <Route
            path="/hub"
            element={
              role === "OPERATOR" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/hub"
              element={
                role === "OPERATOR" ? <Hub /> : <Navigate to="/signIn" />
              }
            />
          </Route>

          <Route
            path="/profile"
            element={
              role === "OPERATOR" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/profile"
              element={
                role === "OPERATOR" ? <Profile /> : <Navigate to="/signIn" />
              }
            />
          </Route>
          <Route
            path="/collections"
            element={
              role === "OPERATOR" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/collections"
              element={
                role === "OPERATOR" ? (
                  <Collections />
                ) : (
                  <Navigate to="/signIn" />
                )
              }
            />
          </Route>

          <Route
            path="/dashboard/:customerId"
            element={
              role === "OPERATOR" ? <Dashboards /> : <Navigate to="/signIn" />
            }
          />
          <Route
            path="/dashboardUser"
            element={
              role === "OPERATOR" ? (
                <DashboardsAdmin />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/digitalTwin"
            element={
              role === "OPERATOR" ? <DigitalTwin /> : <Navigate to="/signIn" />
            }
          />
          <Route
            path="/ImageGallery/:date/:customerId"
            element={
              role === "OPERATOR" ? <ImageGallery /> : <Navigate to="/signIn" />
            }
          />
          <Route
            path="/ImageGallery/:objectId"
            element={
              role === "OPERATOR" || "COMPANY ADMIN" ? (
                <ImageDetails />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />
          <Route
            path="/mapCollection/:date/:customerId"
            element={
              role === "OPERATOR" ? (
                <MapCollection />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          <Route
            path="/collections/:date"
            element={
              role === "OPERATOR" ? <PrivateRoute /> : <Navigate to="/signIn" />
            }
          >
            <Route
              path="/collections/:date"
              element={
                role === "OPERATOR" ? <FieldData /> : <Navigate to="/signIn" />
              }
            />
          </Route>

          <Route
            path="/dashboard-mobile/:customerId/:adminId"
            element={
              role === "OPERATOR" ? (
                <DashboardMobile />
              ) : (
                <Navigate to="/signIn" />
              )
            }
          />

          <Route
            path="/field-video-manager"
            element={
              role === "OPERATOR" ? <VideoManager /> : <Navigate to="/signIn" />
            }
          />

          <Route
            path="/upload-videos/:userId"
            element={
              role === "OPERATOR" ? <UploadVideos /> : <Navigate to="/signIn" />
            }
          />

          {/* None user */}
          <Route
            path="/signIn"
            element={
              role === "None" ? (
                <SignIn />
              ) : role === "COMPANY ADMIN" ? (
                <Navigate to="/admin" />
              ) : role === "DECIMETRIX ADMIN" ? (
                <Navigate to="/admin-decimetrix" />
              ) : (
                <Navigate to="/hub" />
              )
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
