import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../utils/const";

export const MarkerContainer = styled("div")`
  .cluster-marker,
  .cluster-marker-csv,
  .cluster-marker-operation {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: #fff;
    background: #ff5f7e;
    border-radius: 50%;
    box-shadow: 0 0 8px #ff7893;
    cursor: pointer;
  }

  .cluster-marker-csv {
    background: #d62ad0;
    box-shadow: 0 0 8px #eb51e5;
  }

  .cluster-marker-operation {
    background: #810955;
    box-shadow: 0 0 8px #cb3895;
  }

  .object-marker {
    background: none;
    border: none;
  }

  .object-marker img {
    width: 40px;
  }
`;
