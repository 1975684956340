import styled from "styled-components";
import { MdArrowForward, MdArrowRight } from "react-icons/md";
import { PRIMARY_COLOR } from "../../utils/const";


export const InfoContainer = styled.div`
  color: #fff;
  margin-top: -2px;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : `${PRIMARY_COLOR}`)};
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  /* padding: 0 24px; */
  justify-content: center;
`;

export const InfoRow = styled.div`
  margin: 100px 0;
  display: grid;
  padding: 24px;

  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 1050px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: -70px;
  padding: 0 20px;
  grid-area: col1;
  width: 400px;
  box-sizing: border-box;

  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const Column2 = styled.div`
  margin-bottom: -40px;
  padding: 50 50px;
  grid-area: col2;
  display: flex;
  height: 100px;
`;

export const TextWrapper = styled.div`
  max-width: 740px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #010606;
  font-size: 1.6rem;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 4.8rem;
  line-height: 1;
  font-weight: 700;
  color: ${({ lightText }) => (lightText ? "#f7fBfa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 3.2rem;
  }
`;

export const SubTitle = styled.p`
  margin-bottom: 35px;
  font-size: 1.8rem;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  text-align: justify;
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  display: flex;
  justify-content:center;
  align-items: center;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 2rem;
`;

export const ArrowRight = styled(MdArrowRight)`
  margin-right: 8px;
  font-size: 2rem;
`;
