import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const headCells = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "Id",
    },
    {
      id: "comments",
      numeric: false,
      disablePadding: false,
      label: "Comments",
    },
    {
      id: "WindDirection",
      numeric: false,
      disablePadding: false,
      label: "Wind Direction (deg°)",
    },
    {
      id: "latitude",
      numeric: false,
      disablePadding: false,
      label: "Latitude",
    },
    {
      id: "longitude",
      numeric: false,
      disablePadding: false,
      label: "Longitude",
    },
    {
      id: "velocity",
      numeric: false,
      disablePadding: false,
      label: "Velocity (km/h)",
    },
    {
      id: "skyCOndition",
      numeric: false,
      disablePadding: false,
      label: "Sky Condtion",
    },
    {
      id: "precipitation",
      numeric: false,
      disablePadding: false,
      label: "Precipitation",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <b>
              <TableSortLabel
                sx={{
                  "&.MuiTableSortLabel-root": {
                    ml: 2,
                  },
                  "& .MuiTableSortLabel-icon": {
                    opacity: 1,
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </b>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ operation, setSeeAnemometerTable }) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [rows, setRows] = useState([]);
  const token = localStorage.getItem("token");
  const typeOperations = [
    { inspectionSniffer: "inspection-sniffer" },
    { inspectionFlir: "inspection-flir" },
    { leak: "leak" },
    { Reparation: "reparation" },
  ];

  useEffect(() => {
    const typeOperationObjects = typeOperations[operation.typeOperationId - 1];
    const key = Object.keys(typeOperationObjects)[0];
    const value = typeOperationObjects[key];
    const specificOperationId = operation[key]?.id || 0;

    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/${value}/anemometer/${specificOperationId}`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      if (data) {
        const operationAnemometer = data.operationAnemometer.map(
          (opAn) => opAn.anemometer
        );
        setRows(operationAnemometer);
        if (operationAnemometer.length > 0) setSeeAnemometerTable(true);
      }
    };

    fetchData();
  }, [operation]);

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows.map((n) => n.id);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{
              minWidth: 750,
              "& th": {
                color: "#000000",
                backgroundColor: "#9bc4cb",
              },
            }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              // orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              // onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const isItemSelected = isSelected(row?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      // selected={isItemSelected}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#F7F4F3" : null,
                      }}
                    >
                      <TableCell id={labelId} align="center">
                        {row?.id}
                      </TableCell>
                      <TableCell align="center">{row?.comments}</TableCell>
                      <TableCell align="center">{row?.direction}</TableCell>
                      <TableCell align="center">
                        {row?.locationAnemometer.latitude}
                      </TableCell>
                      <TableCell align="center">
                        {row?.locationAnemometer.longitude}
                      </TableCell>
                      <TableCell align="center">{row?.velocity}</TableCell>
                      <TableCell align="center">
                        {row?.skyCondition.type[0] +
                          row?.skyCondition.type.slice(1).toLowerCase()}
                      </TableCell>
                      <TableCell align="center">
                        {row?.precipitation.type[0] +
                          row?.precipitation.type.slice(1).toLowerCase()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
