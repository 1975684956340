import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import { ItemList } from "./UserRoutesElements";

const ListObjects = ({ type }) => {
  const [objects, setObjects] = useState();
  const [operation, setOperation] = useState();
  const token = localStorage.getItem("token");

  useEffect(() => {
    getIconElements();
    getIconOperationsElements();
  }, []);

  const getIconElements = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/objects/tables`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const tableName = await res.json();
      setObjects(tableName);
    } catch (error) {
      console.error(error);
    }
  };

  const getIconOperationsElements = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_URL_BACKEND}api/v1/operation/`,
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );
      const operations = await res.json();
      setOperation(operations);
    } catch (error) {
      console.error(error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (string === null) {
      return "";
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  return (
    <Container>
      {(objects && operation) !== undefined ? (
        <div>
          {type === "ghg" ? (
            <>
              {objects.typeElementGhg.map((element, index) => (
                <ItemList>
                  <img src={element.url_icon_color} width="40px" alt="ico" />{" "}
                  <p>
                    {" "}
                    {capitalizeFirstLetter(
                      element.name.toLowerCase()
                    ).substring(0, 20)}
                  </p>
                </ItemList>
              ))}
            </>
          ) : type === "agile" ? (
            <>
              {objects.objectsAgile.map((element, index) => (
                <ItemList>
                  <img src={element.urlIconColor} width="40px" alt="ico" />
                  <p>
                    {capitalizeFirstLetter(
                      element.name.toLowerCase()
                    ).substring(0, 20)}
                  </p>
                </ItemList>
              ))}
            </>
          ) : (
            <>
              {operation.map((element, index) => (
                <ItemList>
                  <img
                    src={element.urlIcon}
                    width="40px"
                    alt="ico"
                  />
                  <p>
                    {capitalizeFirstLetter(
                      element.name.toLowerCase()
                    ).substring(0, 20)}
                  </p>
                </ItemList>
              ))}
            </>
          )}
        </div>
      ) : null}
    </Container>
  );
};

export default ListObjects;
