import styled from "styled-components";
import { PRIMARY_COLOR } from "./../../utils/const";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 11px;
  border-radius: 10px 10px 0px 0px;

  .neurona {
    width: 34px;
    margin-right: 1px;
    margin-bottom: 6px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
    margin-right: 15px;
    margin-left: 15px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentButton = styled.div`
  width: 94%;
  padding: 10px 10px 20px 10px;
`;

export const ContentButtonGallery = styled.div`
  width: 170%;
  padding: 10px 10px 10px 20px;
`;

export const Checkboxarea = styled.div`
  width: 100%;

  margin: 0rem;
  font-size: 1.4rem;
`;

export const CustomizedChevronLeftIcon = styled(ChevronLeftIcon)`
  background-color: #0e4d45;
  border-radius: 23px;
  font-size: 30px;
`;

export const DeleteUserButton = styled.div`
  outline: none;
  border: none;
  padding-left: 15px;
`;

export const ContainerOperations = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 10px;
  background-color: #f0f0f0;

  .map-container,
  .container-operation-galery,
  .c3,
  .c4,
  .c5,
  .c6 {
    margin: 0.3%;
    width: 100%;
    min-width: 150px;
    max-height: 500px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .table-container {
    margin: 10px 10px 10px 0px;
    width: 100%;
    min-width: 99.7%;
    max-height: 500px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .table-container .operation-title {
    display: flex;
    margin: 10px 0px 0px 0px;
  }
  .table-container .operation-title h1 {
    font-size: 1.5rem;
  }

  .table-container .operation-table {
    padding: 24px 24px 24px 24px;
  }

  .operation-type span {
    color: ${PRIMARY_COLOR};
  }

  .info-operation {
    display: flex;
    padding: 24px 24px 24px 24px;
    flex-direction: column;
    background-color: #fff;
    align-items: center;

    height: 660px;
    margin: 10px 20px 10px 0px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
    overflow-y: scroll;
    .wrapper-buttons {
      justify-content: center;
    }
  }

  .photo-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99.5%;
    height: 660px;
    background-color: #fff;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .operation-title {
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
  }

  .operation-title span {
    color: ${PRIMARY_COLOR};
    align-self: center;
  }

  .operation-details {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .details-item {
    padding: 0 10px;
    font-size: 1.4rem;
    padding: 5px 10px;
    border-radius: 0;
    color: #000000;
  }
  .details-item span {
    color: #595959;
  }

  .wrapper-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .button-load {
    height: 50px;
    width: 50%;
    border: none;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    background-color: ${PRIMARY_COLOR};
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
  }

  .content-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99.3%;
    /* height: 500px; */

    /* padding-bottom: 10px; */
    background-color: #fff;
    margin: 10px 0px 10px 0px;
    border-radius: 5px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .map-container {
    height: 100%;
  }

  .photo-container-image .gallery {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;

    overflow: hidden;
  }

  .photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 10px 20px 10px 0px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .leak-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 95.5%;
    margin: 10px 0px 20px 0px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .leak-container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 650px;
    background-color: #fff;
    margin: 10px 0px 0px 0px;
    border-radius: 5px;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }

  .leak-container-image .gallery {
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    overflow: hidden;
  }

  .leak-container .operation-title h1 {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    font-size: 1.5rem;
  }

  .photo-container .operation-title h1 {
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 10px 0;
    align-items: center;
    font-size: 1.5rem;
  }

  .c3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-videos {
    display: flex;
    width: 100%;
  }

  .container-video {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 5px 0;
  }

  .container-video .video {
    position: relative;
    text-align: center;
    padding: 0px 0px 0px 20px;
    object-fit: contain;
    opacity: 0.9;
  }

  .container-video .video:hover {
    opacity: 1;
  }

  .container-video .videoProcessed {
    position: relative;
    text-align: center;
    padding: 0px 20px 0px 0px;
    object-fit: contain;
    opacity: 0.9;
  }

  .container-video .videoProcessed:hover {
    opacity: 1;
  }

  .container-video .videoProcessed .button {
    position: absolute;
    opacity: 1;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 22px;
    height: 34px;
    background: ${PRIMARY_COLOR};
    border-radius: 100%;
    padding: 18px 20px 18px 28px;
    cursor: pointer;
  }

  .container-video .videoProcessed .button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 22px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }

  .container-video .video .button {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 22px;
    height: 34px;
    background: ${PRIMARY_COLOR};
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
    cursor: pointer;
  }
  .container-video .video .button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 22px solid #fff;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }

  .container-video .video-text {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: ${PRIMARY_COLOR};
    font-weight: bold;
  }

  .container-video .video-text-pdf {
    font-size: 1.4rem;
    margin-bottom: 10px;
    margin-left: 65%;
    color: ${PRIMARY_COLOR};
    font-weight: bold;
  }

  .container-video .video-text-ppm {
    font-size: 1.4rem;
    margin-left: 10%;
    margin-bottom: 10px;
    color: ${PRIMARY_COLOR};
    font-weight: bold;
  }

  @media screen and (min-width: 666px) {
    .photo-container-image .gallery {
      width: 80%;
    }
    .leak-container-image .gallery {
      width: 80%;
    }
  }
  @media screen and (min-width: 850px) {
    .container-operation-galery,
    .c3 {
      width: 49.4%;
    }
    .c4,
    .c5 {
      width: 49.4%;
    }
    .photo-container-image .gallery {
      width: 60%;
    }
    .leak-container-image .gallery {
      width: 60%;
    }
  }

  @media screen and (min-width: 1100px) {
    .map-container {
      width: 59.8%;
    }
    .table-container {
      width: 99%;
    }
    .container-operation-galery {
      width: 39%;
    }
    .c3,
    .c4 {
      width: 33%;
    }
    .c5 {
      width: 32.1%;
    }
    .c6 {
      width: 100%;
    }
    .photo-container-image .gallery {
      width: 50%;
    }
    .leak-container-image .gallery {
      width: 50%;
    }
  }
`;

export const WraperTitleCardMarker = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .wraper-title-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
  span {
    color: ${PRIMARY_COLOR};
  }
  h1 {
    margin-top: 15px;
    text-align: center;
  }
  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #636363;
  }
  img {
    width: 40px;
    height: 40px;
    margin: 5px 0;
  }
`;

export const VideoFlir = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: ${({ width }) => width};
  min-height: 200px;
`;

export const VideoFlirProcessed = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: ${({ width }) => width};
  min-height: 200px;
`;
