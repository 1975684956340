import { Container, Grid } from "@mui/material";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

function Index() {
  const [token, setToken] = useState("");

  // useEffect(() => {
  //   const getData = async () => {
  //     const { data } = await axios.get(
  //       `${process.env.REACT_APP_URL_BACKEND}api/v1/superset/token`
  //     );
  //     console.log("🚀 ~ file: index.jsx ~ line 12 ~ getData ~ data", data);
  //     setToken(data);
  //   };
  //   getData();
  // }, []);

  async function fetchGuestTokenFromBackend() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/superset/token`
    );
    return data;
  }

  function fetchGuestTokenFromBackend2() {
    return new Promise((resolve) => {
      axios
        .post(`${process.env.REACT_APP_URL_BACKEND}api/v1/superset/token`)
        .then((response) => {
          console.log(response.status);
        });
      resolve("mytoken");
    });
  }

  // token !== "" &&
  //   embedDashboard({
  //     id: "0924cf2b-70f0-47a4-8572-5f684d7c1d53",
  //     supersetDomain: "http://superset.decimetrix.com:5000",
  //     mountPoint: document.getElementById("my-superset-container"),
  //     fetchGuestToken: () => fetchGuestTokenFromBackend2(),
  //     iframeAttributes: {
  //       className: "my-test-frame-class",
  //       style: {
  //         display: "inline-block",
  //         minWidth: "50rem",
  //       },
  //     },
  //     dashboardUiConfig: {
  //       hideTitle: true,
  //       hideTab: true,
  //       hideChartControls: true,
  //     },
  //   });

  return (
    <div>
      <Container
        style={{
          paddingTop: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column", 
        }}
        maxWidth="xl"
      >
        <Grid container spacing={2}>
          <iframe
            width="1600"
            height="1100"
            seamless
            frameBorder="0"
            scrolling="yes"
            src="https://superset.decimetrix.com/superset/dashboard/1/?standalone=3"
          ></iframe>
        </Grid>
        {/* <div id="my-superset-container"></div> */}
      </Container>
    </div>
  );
}

export default Index;
