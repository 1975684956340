import styled from "styled-components";
import { PRIMARY_COLOR } from "../../../utils/const";

export const HeadModal = styled("modal")`
  height: 50px;
  background: ${PRIMARY_COLOR};
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 11px;
  border-radius: 10px 10px 0 0;

  .neurona {
    width: 34px;
    margin-right: 11px;
  }

  .titleHeadModal {
    font-size: 21px;
    color: #fff;
  }
`;

export const StyledP = styled.div`
  margin: 10px 0px 0px 30px;
  font-size: 1.6rem;
  text-align: left;
`;



export const SummaryFeature = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0px 0;
  margin-top: 50px;
  h3 {
    display: flex;
    padding: 1%;

    font-size: 1.6rem;
  }

  p {
    margin: 4px 0px 5px 5px;
    display: flex;
    flex-direction: column;
    align-items: start;
    font-size: 1.6rem;
    
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    font-size: 1.5rem;
  }
  textarea {
    height: 60px;
  }
`;
export const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 15px;
`;

export const TypeJobContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export const LocationInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 10px 0;

  b {
    margin-bottom: 5px;
  }

  p {
    margin: 5px 0;
  }
`;

export const FeatureDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;

  p {
    margin: 5px 0;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: #ffffff;
    font-size: 1.6rem;
  }
  textarea {
    height: 60px;
  }
`;

export const AsignTo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  p {
    margin-bottom: 10px;
  }
`;

export const ButtonCard = styled.button`
  width: 40%;
  height: 40px;
  border-radius: 15px;
  border: none;
  background-color: ${PRIMARY_COLOR};
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;

  &:hover {
    background-color: #1c1c1c;
    cursor: pointer;
  }
`;

export const SelectRegion = styled.select`
  margin: 5px 0;
  width: 100%;
  border: 1px solid ${PRIMARY_COLOR};
  border-radius: 5px;
  padding: 5px;
  background-color: #ffffff;
  font-size: 1.6rem;
`;
