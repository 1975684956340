import styled, { css } from "styled-components";
import Paper from "@mui/material/Paper";
import { PRIMARY_COLOR, SECOND_COLOR } from "./../../utils/const";

const colors = {
  border: "#15bbfd",
  error: "#f67070",
  success: "#54c25d",
};

export const FormSetUp = styled.form`
  min-width: 300px;
  width: 100%;
`;

export const ProfileWorkIcon = styled.p``;

export const Wrapperbtn = styled("div")`
  position: relative;
`;

export const AddButton = styled("label")`
  height: 45px;
  line-height: 45px;
  width: 50%;
  background: ${PRIMARY_COLOR};
  color: #fff;
  font-weight: bold;

  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 1px;
  transition: 0.1s ease all;

  &:hover {
    box-shadow: 1px 0px 1px rgba(163, 163, 163, 1);
  }
`;

export const CustomizedAccordion = styled(Paper)`
  margin-top: 0px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #b8b2b2;
  padding: 10px;
  box-shadow: none;
`;

export const FormInput = styled.input`
  margin-left: 10px;
  width: 100%;
  border: none;
  background-color: #f9f9f9;
  color: rgb(61, 61, 61);
  font-size: 1.4rem;
  cursor: line;
  border-bottom: 1px solid rgb(61, 61, 61);
`;

export const ProfileButton = styled.button`
  height: 45px;
  line-height: 45px;
  width: 45%;
  background: ${PRIMARY_COLOR};
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1.4rem;
  transition: 0.1s ease all;

  &:hover {
    box-shadow: 1px 0px 10px rgba(163, 163, 163, 1);
  }
`;

export const ProfileFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 2%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.1);
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80hv;
  width: 100%;
  background-color: #fff;
`;

export const WrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 10px;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 5px 20px;
  background-color: #ffffff;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.label`
  display: block;
  font-weight: 600;
  cursor: pointer;
  color: #5c5757;
  font-size: 1.6rem;

  ${(props) =>
    props.valid === "false" &&
    css`
      color: ${colors.error};
    `}
`;

export const WrapperInput = styled.div`
  width: 100%;
  position: relative;
  z-index: 90;
`;

export const Input = styled.input`
  width: 100%;
  background: #f1f1f1;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  margin: 10px 0 30px;
  transition: 0.3s ease all;
  border: none;
  /* border-bottom: 1px solid #2e2e2e; */
  display: flex;
  font-size: 1.5rem;

  &:focus {
    border: 3px solid ${colors.border};
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
  }

  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}
`;

export const InputProfile = styled.input`
  width: 100%;
  background: #f1f1f1;
  color: grey;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  padding: 10px 40px 10px 20px;
  pointer-events: none;
  border: none;
  /* border-bottom: 1px solid #2e2e2e; */
  display: flex;
  margin: 10px 0;
  ${(props) =>
    props.valid === "true" &&
    css`
      border: 3px solid transparent;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      border: 3px solid ${colors.error} !important;
    `}
`;

export const LegendError = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
  color: ${colors.error};
  display: none;

  ${(props) =>
    props.valid === "true" &&
    css`
      display: none;
    `}

  ${(props) =>
    props.valid === "false" &&
    css`
      display: block;
    `}
`;

export const ContainerTerms = styled.div`
  grid-column: span 2;

  input {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;

export const ContainerButtonCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: span 2;

  @media (max-width: 800px) {
    grid-column: span 1;
  }
`;

export const Button = styled.button`
  height: 45px;
  line-height: 45px;
  width: 100%;
  background: ${PRIMARY_COLOR};
  ${(props) =>
    props.type === "delete" &&
    css`
      background: #960909;
    `}
  color: #fff;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.1s ease all;

  &:hover {
    transition: all 0.2s ease-in-out;
    background-color: ${SECOND_COLOR};
    ${(props) =>
      props.type === "delete" &&
      css`
        background: #d30a0a;
      `}
  }
`;

export const MessageSuccess = styled.p`
  height: 30px;
  line-height: 45px;
  padding: 0px 15px;
  border-radius: 3px;
  margin: 10px auto;
  color: ${colors.success};
`;

export const MessageError = styled.div`
  height: 30px;
  line-height: 45px;
  color: #f66060;
  padding: 0px 15px;
  border-radius: 3px;
  margin: 10px auto;
  font-size: 1.6rem;
`;
