import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import { PRIMARY_COLOR } from "../../utils/const";
import logoWhite from "../../images/Digital-Transformation.png";
import { NavBtnLink, NavbarLogoR, Img } from "./ImageDetailsElements";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NavBarDrawer from "./../NavBar/navDrawer";

const ResponsiveAppBar = () => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const collectionInfo = useSelector(
    (state) => state.collectionReducer.dateCollection
  );

  const isEmptyObject = (object) => {
    return Object.keys(object).length === 0 && object.constructor === Object;
  };

  const handleClick = () => {
    const date = collectionInfo.date;
    const customerId = collectionInfo.customerId;
    if (isEmptyObject(collectionInfo)) {
      role === "COMPANY ADMIN"
        ? navigate("/digital-twin-admin")
        : navigate("/digitalTwin");
    }
    if (date && customerId) {
      navigate(`/collections/${date}/${customerId}`);
    }
    //else {
    //   navigate(`/collections`);
    // }
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: PRIMARY_COLOR,
        height: "80px",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <NavBarDrawer></NavBarDrawer>
          <Box component="div" sx={{ flexGrow: 5, mr: 2 }}>
            <NavbarLogoR to={role === "COMPANY ADMIN" ? "/admin" : "/hub"}>
              <Img src={logoWhite} />
            </NavbarLogoR>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <NavBtnLink onClick={handleClick}>Back</NavBtnLink>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
