import { useNavigate } from "react-router-dom";
import Icon2 from "images/dashboardV2.svg";
import Icon3 from "images/user.svg";
import Icon4 from "images/DigitalTwin.svg";
import Container from "@mui/material/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";

import useGet from "hooks/useFetch";

import {
  UserContainer,
  UserH1,
  UserH2,
  HubContainer,
  HubWrapper,
  HubCard,
  HubIcon,
  HubH1,
  HubH2,
  HubP,
  UserH2Flex,
} from "./HubElements";

const AdminHub = () => {
  const navigate = useNavigate();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const [adminCompany, errorAdminCompany] = useGet(
    `api/v1/admin-company/${adminCompanyId}`
  );

  // Navigate to any url
  const navigation = (url) => {
    navigate(url);
  };

  const capitalizeFirstLetter = (string) => {
    if (string === null) {
      return "";
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  const formatDate = (string) => {
    if (string === null) {
      return "";
    } else {
      const newString = string.replace("T", " ");
      return newString.replace(".000Z", " ");
    }
  };

  return (
    <Container
      sx={{
        paddingTop: "50px",
      }}
      maxWidth="xl"
    >
      <UserContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={8} xl={8}>
            {adminCompany && !errorAdminCompany ? (
              <UserH1>Hello, Welcome to a Green World!</UserH1>
            ) : (
              <UserH1>Loading ...</UserH1>
            )}
          </Grid>
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <UserH2Flex>
                {" "}
                <b>Last Login:</b>{" "}
                {adminCompany !== undefined &&
                  adminCompany !== null &&
                  `${capitalizeFirstLetter(
                    formatDate(adminCompany.user.userVerification.lastLogin)
                  )}`}
              </UserH2Flex>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={10} xl={10}>
            <UserH2>Please select the module that you want to use.</UserH2>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <UserH2Flex>
                {" "}
                <b>Role: </b>
                {adminCompany !== undefined && adminCompany !== null
                  ? `${capitalizeFirstLetter(
                      adminCompany.user.userType.role.toLowerCase()
                    )}`
                  : null}{" "}
              </UserH2Flex>
            </Box>
          </Grid>
        </Grid>
      </UserContainer>
      <HubContainer>
        <HubH1>
          Decimetrix<sup style={{ fontSize: 25 }}>®</sup> Green Dragon <br />{" "}
          Carbon Footprint Management System
        </HubH1>
        <HubWrapper>
          <HubCard onClick={() => navigation("/dashboard-admin")}>
            <HubH2>DASHBOARD</HubH2>
            <HubIcon src={Icon2} id="1" />
            <HubP>Report of all the objects collected.</HubP>
          </HubCard>
          <HubCard onClick={() => navigation("/digital-twin-admin")}>
            <HubH2>DIGITAL TWIN</HubH2>
            <HubIcon src={Icon4} />
            <HubP>View all objets and events in the map.</HubP>
          </HubCard>
          <HubCard onClick={() => navigation("/user-register")}>
            <HubH2>USERS</HubH2>
            <HubIcon src={Icon3} />
            <HubP>Add a new user to your team.</HubP>
          </HubCard>
        </HubWrapper>
      </HubContainer>
    </Container>
  );
};

export default AdminHub;
