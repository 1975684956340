import HubComponent from "../components/Hub/index";
import NavBar from "../components/Admin/NavBar";

const Hub = () => {
  return (
    <div>
      <NavBar linkLogo={"hub"} />
      <HubComponent />
    </div>
  );
};

export default Hub;
