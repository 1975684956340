// Required modules
import styled from "styled-components";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/const";

export const ConfigAdminContainer = styled.div`
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  width: 100%;
  padding: 21px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
`;

export const TitleAdminConfig = styled.h1`
  font-size: 3rem;
  text-transform: capitalize;
  margin-bottom: 40px;
`;

export const ItemsAdminConfig = styled.div`
  display: flex;
  justify-content: center;
`;

export const ItemAdminCofig = styled.div`
  flex: 1;
  box-shadow: rgba(79, 79, 79, 0.2) 0px 0px 5px 0px;
  padding: 13px 21px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 13px;
    color: ${PRIMARY_COLOR};
  }

  p {
    font-size: 2rem;
  }
`;

export const RowAdminConfig = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ColumnAdminConfig = styled.div`
  flex: 1;
  padding: 21px;
  display: flex;
  flex-direction: column;
`;

export const CreateNewRegionButton = styled.div`
  display: flex;
  justify-content: end;

  button {
    background: ${PRIMARY_COLOR};
    color: #fff;
    padding: 8px 13px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
  }

  button:hover {
    background: ${SECOND_COLOR};
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    font-size: 17px;
  }
`;
