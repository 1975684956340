import * as React from "react";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import CollectionsIcon from "@mui/icons-material/Collections";
import InfoIcon from "@mui/icons-material/Info";
// import "././drawer.css";
import ReactTooltip from "react-tooltip";

import { BoxNav } from "../Admin/DigitalTwin/UserRoutesElements";

const drawerWidth = 85;
// const drawerWidth = 250;

export default function PersistentDrawerLeft() {
  const iconStyle = {
    color: "#f9f9f9",
    fontSize: "4rem",
  };
  const styleListIcon = {
    justifyContent: "center",
  };

  const role = localStorage.getItem("role");

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const navigateTo = (event) => {
    if (event === "Home") {
      navigate("/");
    } else if (event === "Home ") {
      navigate("/hub");
    } else if (event === "Log in") {
      navigate("/signIn");
    } else if (event === "Dashboard") {
      navigate("/dashboard-admin");
    } else if (event === "Digital twin") {
      navigate("/digital-twin-admin");
    } else if (event === "Operators") {
      navigate("/user-register");
    } else if (event === "Collections") {
      navigate("/collections");
    } else if (event === "Dashboard user") {
      navigate("/dashboardUser");
    } else if (event === "Digital twin user") {
      navigate("/digitalTwin");
    } else if (event === "Company managers") {
      navigate("/admin-decimetrix-register");
    } else if (event === "About us") {
      window.open("https://www.linkedin.com/company/decimetrix/");
    } else {
      navigate("/");
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <BoxNav>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="end"
        sx={{ mr: 1, ...(open && {}) }}
      >
        <MenuIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#0E4D45",
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <List>
          {["Close"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={handleDrawerClose}>
                <ListItemIcon sx={styleListIcon}>
                  {index % 2 === 0 ? (
                    <CloseIcon sx={iconStyle} />
                  ) : (
                    <MailIcon sx={iconStyle} />
                  )}
                </ListItemIcon>
                <ListItemText />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {role === null ? (
          <List>
            {["Home", "Log in"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={(event) => navigateTo(text)}>
                  <ListItemIcon sx={styleListIcon}>
                    {text === "Home" ? (
                      <>
                        <HomeOutlinedIcon
                          sx={iconStyle}
                          data-tip
                          data-for="Home"
                        />
                        <ReactTooltip
                          id="Home"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Home
                        </ReactTooltip>
                      </>
                    ) : text === "Log in" ? (
                      <>
                        <PersonAddAltIcon
                          sx={iconStyle}
                          data-tip
                          data-for="LogIn"
                        />
                        <ReactTooltip
                          id="LogIn"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Log In
                        </ReactTooltip>
                      </>
                    ) : null}
                  </ListItemIcon>
                  <ListItemText />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : role === "COMPANY ADMIN" ? (
          <List>
            {["Home", "Dashboard", "Digital twin", "Operators"].map(
              (text, index) => (
                <ListItem key={text} disablePadding>
                  <ListItemButton onClick={(event) => navigateTo(text)}>
                    <ListItemIcon sx={styleListIcon}>
                      {text === "Home" ? (
                        <>
                          <HomeOutlinedIcon
                            sx={iconStyle}
                            data-tip
                            data-for="Home"
                          />
                          <ReactTooltip
                            id="Home"
                            place="right"
                            effect="solid"
                            offset="{'left': -15}"
                          >
                            Home
                          </ReactTooltip>
                        </>
                      ) : text === "Dashboard" ? (
                        <>
                          <DashboardIcon
                            sx={iconStyle}
                            data-tip
                            data-for="Dashboard"
                          />
                          <ReactTooltip
                            id="Dashboard"
                            place="right"
                            effect="solid"
                            offset="{'left': -15}"
                          >
                            Dashboard
                          </ReactTooltip>
                        </>
                      ) : text === "Digital twin" ? (
                        <>
                          <PublicIcon
                            sx={iconStyle}
                            data-tip
                            data-for="DigitalTwin"
                          />
                          <ReactTooltip
                            id="DigitalTwin"
                            place="right"
                            effect="solid"
                            offset="{'left': -15}"
                          >
                            Digital Twin
                          </ReactTooltip>
                        </>
                      ) : text === "Operators" ? (
                        <>
                          <PeopleIcon
                            sx={iconStyle}
                            data-tip
                            data-for="Operators"
                          />
                          <ReactTooltip
                            id="Operators"
                            place="right"
                            effect="solid"
                            offset="{'left': -15}"
                          >
                            Users
                          </ReactTooltip>
                        </>
                      ) : null}
                    </ListItemIcon>
                    <ListItemText />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        ) : role === "OPERATOR" ? (
          <List>
            {[
              "Home ",
              "Collections",
              "Dashboard user",
              "Digital twin user",
            ].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={(event) => navigateTo(text)}>
                  <ListItemIcon sx={styleListIcon}>
                    {text === "Home " ? (
                      <>
                        <HomeOutlinedIcon
                          sx={iconStyle}
                          data-tip
                          data-for="Home"
                        />
                        <ReactTooltip
                          id="Home"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Home
                        </ReactTooltip>
                      </>
                    ) : text === "Collections" ? (
                      <>
                        <CollectionsIcon
                          sx={iconStyle}
                          data-tip
                          data-for="Collections"
                        />
                        <ReactTooltip
                          id="Collections"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Collections
                        </ReactTooltip>
                      </>
                    ) : text === "Dashboard user" ? (
                      <>
                        <DashboardIcon
                          sx={iconStyle}
                          data-tip
                          data-for="DashboardUser"
                        />
                        <ReactTooltip
                          id="DashboardUser"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Dashboard
                        </ReactTooltip>
                      </>
                    ) : text === "Digital twin user" ? (
                      <>
                        <PublicIcon
                          sx={iconStyle}
                          data-tip
                          data-for="DigitalTwinUser"
                        />
                        <ReactTooltip
                          id="DigitalTwinUser"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Digital Twin
                        </ReactTooltip>
                      </>
                    ) : null}
                  </ListItemIcon>
                  <ListItemText />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : role === "DECIMETRIX ADMIN" ? (
          <List>
            {["Home", "Company managers"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={(event) => navigateTo(text)}>
                  <ListItemIcon sx={styleListIcon}>
                    {text === "Home" ? (
                      <>
                        <HomeOutlinedIcon
                          sx={iconStyle}
                          data-tip
                          data-for="Home"
                        />
                        <ReactTooltip
                          id="Home"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Home
                        </ReactTooltip>
                      </>
                    ) : text === "Company managers" ? (
                      <>
                        <PeopleIcon
                          sx={iconStyle}
                          data-tip
                          data-for="CompanyManagers"
                        />
                        <ReactTooltip
                          id="CompanyManagers"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          Company Managers
                        </ReactTooltip>
                      </>
                    ) : null}
                  </ListItemIcon>
                  <ListItemText />
                </ListItemButton>
              </ListItem>
            ))}
            {["About us"].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={(event) => navigateTo(text)}>
                  <ListItemIcon sx={styleListIcon}>
                    {text === "About us" ? (
                      <>
                        <InfoIcon sx={iconStyle} data-tip data-for="AboutUs" />
                        <ReactTooltip
                          id="AboutUs"
                          place="right"
                          effect="solid"
                          offset="{'left': -15}"
                        >
                          About Us
                        </ReactTooltip>
                      </>
                    ) : null}
                  </ListItemIcon>
                  <ListItemText />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : null}
      </Drawer>
    </BoxNav>
  );
}
