// import NavBar from "../../components/NavBar/index";
import ImageDetailsComponent from "../../components/ImageGallery/ImageDetails";
import { useParams } from "react-router-dom";
import MapView from "../../components/MapView";
import NavBar from "./../../components/ImageGallery/NavBar";


const ImageDetails = () => {
  const { objectId } = useParams();

  return (
    <div>
      <NavBar />
      <ImageDetailsComponent objectId={objectId} />
      <MapView objectId={objectId} />
    </div>
  );
};

export default ImageDetails;
