import styled from "styled-components";

export const ProfileStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;

  .title-profile {
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
  }

  @media screen and (max-width: 960px){
    margin-top: 150px;
  }
    
`;
