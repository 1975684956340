import { DrawerMapStyle, ButtonMapStyle, ImgButton } from "./MapElements";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import { CustomizeToggleButtonGroup } from "./userRoutes";
import ListObjects from "./ListObjects";
import { ItemList } from "./UserRoutesElements";

export const ContentBaseMap = (changeBaseMap) => ({
  title: "Base Map",
  description: (
    <DrawerMapStyle>
      <ButtonMapStyle value="streets-v11" onClick={changeBaseMap}>
        <ImgButton
          src={
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3tZaAJFEXlkxY3W3cMkbEfU95DFoJTipPww&usqp=CAU"
          }
        ></ImgButton>
        Streets
      </ButtonMapStyle>
      <ButtonMapStyle value="satellite-v9" onClick={changeBaseMap}>
        <ImgButton
          src={
            "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_SATELLITE.png"
          }
        ></ImgButton>
        Satellite
      </ButtonMapStyle>
      <ButtonMapStyle value="light-v10" onClick={changeBaseMap}>
        <ImgButton
          src={
            "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_LIGHT_V2.png"
          }
        ></ImgButton>
        Light
      </ButtonMapStyle>
      <ButtonMapStyle value="dark-v10" onClick={changeBaseMap}>
        <ImgButton
          src={
            "https://d1a3f4spazzrp4.cloudfront.net/kepler.gl/geodude/UBER_DARK_V2.png"
          }
        ></ImgButton>
        Dark
      </ButtonMapStyle>
    </DrawerMapStyle>
  ),
});

export const ContentInformation = (section, handleTabInformation) => ({
  title: "Information",
  description: (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CustomizeToggleButtonGroup
          value={section}
          onChange={handleTabInformation}
          padding={4}
        >
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="ghg">
            <p>GHG</p>
          </ToggleButton>
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="agile">
            <p>Agile</p>
          </ToggleButton>
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="operations">
            <p>Event types</p>
          </ToggleButton>
          <ToggleButton sx={{ fontSize: "1.4rem" }} value="clusters">
            <p>Clusters</p>
          </ToggleButton>
        </CustomizeToggleButtonGroup>
        <br />
        {section === "ghg" && <ListObjects type="ghg"></ListObjects>}
        {section === "agile" ? <ListObjects type="agile"></ListObjects> : null}
        {section === "operations" ? (
          <ListObjects type="operations"></ListObjects>
        ) : null}
        {section === "clusters" && (
          <Container>
            <ItemList>
              <span className="cluster-csv"></span>
              <p>File CSV</p>
            </ItemList>
            <ItemList>
              <span className="cluster-objects"></span>
              <p>Objects</p>
            </ItemList>
            <ItemList>
              <span className="cluster-events"></span>
              <p>Events</p>
            </ItemList>
          </Container>
        )}
      </Grid>
    </>
  ),
});
