import { Container, Grid } from "@mui/material";
import useGet from "../../hooks/useFetch";
import { Operations } from "./operations.jsx";
import { Power } from "./power.jsx";
import { PID } from "./pid.jsx";
import { ArcFlash } from "./arcflash.jsx";
import { GHG } from "./ghg.jsx";
import { Document } from "./document.jsx";
import { Vegetation } from "./vegetation.jsx";
import { Others } from "./others.jsx";

import { PieChart } from "./pieChart.jsx";
import { BarChart } from "./barChart.jsx";

import { UserContainer, UserH1 } from "./DashboardElements";

const DashboardComponent = ({ customerId, adminId }) => {
  const [customer, errorCustomer] = useGet(`api/v1/customers/${customerId}`);
  const [amountObjects, errorObjects] = useGet(
    `api/v1/objects/amount/${customerId}`
  );
  const [amountActivities, errorActivities] = useGet(
    `api/v1/objects/amount/activities/${customerId}`
  );
  const [barcharData, errorbarcharData] = useGet(
    `api/v1/objects/barcharData/${adminId}`
  );

  return (
    <div
      style={{
        background: "#f9f9f9",
      }}
    >
      <UserContainer>
        <Container maxWidth="xl">
          {customer && !errorCustomer ? (
            <UserH1>
              Master Dashboard, {customer.customer.name} {customer.customer.lastName}
            </UserH1>
          ) : (
            <UserH1>Loading ...</UserH1>
          )}
          <Grid container spacing={2}>
            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Operations data={amountActivities[0]} datamonth={20} />
              </Grid>
            ) : null}

            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Power data={amountActivities[1]} datamonth={20} />
              </Grid>
            ) : null}

            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <PID data={amountActivities[2]} datamonth={20} />
              </Grid>
            ) : null}

            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Document data={amountActivities[3]} datamonth={20} />
              </Grid>
            ) : null}

            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <ArcFlash data={amountActivities[4]} datamonth={20} />
              </Grid>
            ) : null}

            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <GHG data={amountActivities[5]} datamonth={20} />
              </Grid>
            ) : null}
            {amountActivities && !errorActivities ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Vegetation data={amountActivities[6]} datamonth={20} />
              </Grid>
            ) : null}
            {amountObjects && !errorObjects ? (
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                <Others data={amountObjects.amountObjects} datamonth={20} />
              </Grid>
            ) : null}
            {barcharData && !errorbarcharData ? (
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <BarChart data={barcharData} />
              </Grid>
            ) : null}
            {amountObjects && !errorObjects ? (
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <PieChart data={amountActivities} sx={{ height: "100%" }} />
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </UserContainer>
    </div>
  );
};

export default DashboardComponent;
