import NavBar from "../components/NavBar/index";
import FieldDataCollectionComponent from "../components/FieldDataCollection/index";
import { useParams } from "react-router-dom";

const FieldDataCollection = () => {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "hub",
    menuElements: {
      collections: "collections",
    },
    button: {
      link: "collections",
      text: "Back",
    },
  };

  const { date } = useParams();

  return (
    <>
      <NavBar configNavbar={configNavbar} />
      <FieldDataCollectionComponent date={date} />
    </>
  );
};

export default FieldDataCollection;
