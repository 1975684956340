import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { PRIMARY_COLOR } from "../../utils/const";


const drawerWidth = 400;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open
    ? {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        backgroundColor: `${PRIMARY_COLOR}`,
        height: "80px",
        transition: theme.transitions.create(["margin", "width"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    : {
        height: "80px",
        backgroundColor: `${PRIMARY_COLOR}`,
      }),
}));


export default AppBar;
