import axios from "axios";

const updateUrlFlirVideo = async (flirVideoId, url) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_URL_BACKEND}api/v1/flir-video/${flirVideoId}`,
      {
        urlVideo: url,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return data;
  } catch (error) {
    console.error(error);
  }
};

export default updateUrlFlirVideo;
