import NavBar from "./../components/NavBar";
import ForgotPassword from "./../components/forgotPassword/ForgotPassword";

export default function ForgotPasswordPage() {
  const configNavbar = {
    sidebar: false,
    typeLinks: "router",
    linkLogo: "",
    menuElements: {},
    button: {
      link: "",
      text: "Home",
    },
  };

  return (
    <div>
      <NavBar configNavbar={configNavbar} />
      <ForgotPassword />
    </div>
  );
}
