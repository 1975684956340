export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_PARAMS = "SET_CURRENT_USER_PARAMS";
export const SET_LOCATIONS_CSV = "SET_LOCATIONS_CSV";
export const RELOAD_CSV = "RELOAD_CSV";
export const RELOAD_DETAILS = "RELOAD_DETAILS";
export const SET_LOCATIONS_JSON = "SET_LOCATIONS_JSON";
export const SHOW_DRAWER_DIGITAL_TWIN = "SHOW_DRAWER_DIGITAL_TWIN";
export const SHOW_DRAWER_OBJECT_DT = "SHOW_DRAWER_OBJECT_DT";
export const SHOW_CARD_MAKER_DIGITAL_TWIN = "SHOW_CARD_MAKER_DIGITAL_TWIN";
export const SET_DATA_OBJECT_DT = "SET_DATA_OBJECT_DT";
export const SET_PROFILE_IMAGE = " SET_PROFILE_IMAGE";
export const SET_DATA_OBJECT_DT_IMAGES = "SET_DATA_OBJECT_DT_IMAGES";
export const SHOW_MARKERS_DIGITAL_TWIN = "SHOW_MARKERS_DIGITAL_TWIN";
export const SET_MARKER_BY_TYPE_ELEMENT_ID = "SET_MARKER_BY_TYPE_ELEMENT_ID";
export const SET_VIEW_MODE_COLLECTION = "SET_VIEW_MODE_COLLECTION";
export const SET_SECTION_COLLECTION = "SET_SECTION_COLLECTION";
export const SET_DATE_COLLECTION = "SET_DATE_COLLECTION";
export const SHOW_MARKERS_BY_ACTIVITIES = "SHOW_MARKERS_BY_ACTIVITIES";
export const SHOW_OPERATION_DETAILS = "SHOW_OPERATION_DETAILS";
export const OPERATION_DETAILS = "OPERATION_DETAILS";

// types of admin
export const SHOW_DRAWER_USER_ROUTES = "SHOW_DRAWER_USER_ROUTES";
export const TYPE_JOB = "TYPE_JOB";
export const SHOW_DRAWER_DIGITAL_TWIN_ADMIN = "SHOW_DRAWER_DIGITAL_TWIN_ADMIN";
export const SET_LOCATIONSID_IN_POLYGON = "SET_LOCATIONSID_IN_POLYGON";
export const SET_GEOMETRY_POLYGON = "SET_GEOMETRY_POLYGON";
// admin add user jobs
export const SET_COORDINATES_USER_JOB = "SET_COORDINATES_USER_JOB";
export const SET_FEATURES_DETAILS_DT = "SET_FEATURES_DETAILS_DT";
export const SET_FEATURES_DETAILS = "SET_FEATURES_DETAILS";
// Admin - Set data objects
export const SET_DATA_OBJECTS = "SET_DATA_OBJECTS";
// Digital twin admin
export const SHOW_MARKER_BY_TYPE_OBJECT_ID = "SHOW_MARKER_BY_TYPE_OBJECT_ID";
export const SHOW_MARKER_BY_TYPE_ELEMENT_ID = "SHOW_MARKER_BY_TYPE_ELEMENT_ID";
export const SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD =
  "SHOW_MARKER_BY_TYPE_OBJECT_ID_LOAD";
export const SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD =
  "SHOW_MARKER_BY_TYPE_ELEMENT_ID_LOAD";
export const SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON =
  "SHOW_MARKER_BY_TYPE_OBJECT_ID_POLYGON";
export const SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON =
  "SHOW_MARKER_BY_TYPE_ELEMENT_ID_POLYGON";
export const SHOW_MARKER_BY_ELEMENT_WITH_MEDIA =
  "SHOW_MARKER_BY_ELEMENT_WITH_MEDIA";
// Admin - Set data objects in Polygon
export const SET_DATA_OBJECTS_POLYGON = "SET_DATA_OBJECTS_POLYGON";
export const SET_FEATURES_POLYGON = "SET_FEATURES_POLYGON";
export const SET_DATA_REGION = "SET_DATA_REGION";
export const SHOW_REGION_DETAILS_DRAWER = "SHOW_REGION_DETAILS_DRAWER";
// filters
export const SET_FILTER_USERS = "SET_FILTER_USERS";
export const SET_SHOW_FILTER_USERS = "SET_SHOW_FILTER_USERS";
export const SET_SHOW_FILTER_ALL_USERS = "SET_SHOW_FILTER_ALL_USERS";
export const FILTER_BY_EVENTS = "FILTER_BY_EVENTS";
export const SET_FILTER_OBJECTS_BY_DATA = "SET_FILTER_OBJECTS_BY_DATA";
