import PersistentDrawerLeft from "./../../components/Admin/UserRoutes/Drawer";
import UserRoutesComponent from "./../../components/Admin/UserRoutes";

const userRoutes = () => {
  return (
    <>
      <PersistentDrawerLeft />
      <UserRoutesComponent />
    </>
  );
};

export default userRoutes;
